import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchTfamApiThunk} from "./fetchTfamApiThunk";
import {signOutAction} from "../slices/global";

const {REACT_APP_PING_FEDERATE_ENDPOINT, REACT_APP_REDIRECT_URI} = process.env

export const signOutThunk = createAsyncThunk(
  'global/signOut',
  async (arg, { getState, dispatch }) => {
    sessionStorage.setItem(
      "signOutFrom",
      JSON.stringify('snapshot')
    );
    const currentState = getState()
    const refreshToken = currentState.global.ssoTokens.refreshToken
    if (refreshToken) {
      await dispatch(fetchTfamApiThunk({
        method: 'POST',
        url: '/as/revoke_token.oauth2',
        params: {
          token: refreshToken,
          token_type_hint: 'refresh_token'
        }
      })).unwrap()
    }
    dispatch(signOutAction())
    let singOffUrl = `${REACT_APP_PING_FEDERATE_ENDPOINT}/idp/startSLO.ping`;
    singOffUrl = singOffUrl.concat(`?TargetResource=${REACT_APP_REDIRECT_URI}&InErrorResource=${REACT_APP_REDIRECT_URI}`);
    window.location.assign(singOffUrl);
  }
)