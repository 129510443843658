import React from 'react'
import { Provider } from 'react-redux'
import { store } from './store'
import App from './App'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/700.css'
import { ThemeProvider } from '@mui/material'
import { theme } from './theme'

window.oncontextmenu = function (event) {
  event.preventDefault()
  event.stopPropagation()
  return false
}

function Snapshot() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  )
}

export default Snapshot
