import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSensorsThunk } from '../../thunks/getSensorsThunk'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import AppError from '../../components/AppError'

export const loadSensorDataWrap = (Component, renderAfterLoad = false, Skeleton) => {
  return memo(function Wrapped () {
    const dispatch = useDispatch()
    const { storeId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const sensorErr = useSelector(state => state.sensors.err)
    useEffect(() => {
      if (storeId && !loaded) {
        dispatch(getSensorsThunk({ storeId })).unwrap().then(() => {
          setLoaded(true)
        })
      }
    }, [storeId])
    if (sensorErr) {
      return (
        <Box>
          <AppError />
        </Box>
      )
    }
    if (renderAfterLoad) {
      if (loaded) {
        return <Component />
      } else {
        return <Skeleton />
      }
    } else {
      return <Component />
    }
  })
}
