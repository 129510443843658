import { appNavbarWrap } from '../appNavbarWrap'
import React, { memo } from 'react'
import { Toolbar, Typography } from '@mui/material'
import NavBackButton from '../../components/NavBackButton'
import EditSensorNavbarSubmitButton from './EditSensorNavbarSubmitButton'
import { useSensor } from '../../hooks/useSensor'
import SensorTypeIcon from '../../components/SensorTypeIcon'

export const editSensorNavbarWrap = (Component) => {
  return appNavbarWrap(Component, memo(
    function Wrapped () {
      const sensor = useSensor()
      return (
        <Toolbar style={{
          justifyContent: 'space-between'
        }}>
          <NavBackButton/>
          <SensorTypeIcon sensorType={sensor.sensorType} />
          <Typography style={{
            marginTop: '2px',
            marginLeft: '10px'
          }}>
            # {sensor.sensorId}
          </Typography>
          <EditSensorNavbarSubmitButton />
        </Toolbar>
      )
    }
  ))
}
