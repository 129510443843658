import {useSelector} from "react-redux";
import {deviceSelector} from "../../appSelectors/deviceSelector";
import {useParams} from "react-router-dom";

export function useDevice() {
  const { deviceId } = useParams()
  const device = useSelector(deviceSelector(deviceId))
  if (!device) {
    throw new Error('Device not found')
  }
  return device
}
