import React, { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateVisualViewportHeightAction } from '../../slices/uiSize'

function AppWindowResizeListener () {
  const dispatch = useDispatch()
  const updateVisualViewportHeight = () => {
    dispatch(updateVisualViewportHeightAction(window.visualViewport.height))
  }
  useEffect(() => {
    updateVisualViewportHeight()
    window.visualViewport.addEventListener('resize', updateVisualViewportHeight)
    return () => {
      window.visualViewport.removeEventListener('resize', updateVisualViewportHeight)
    }
  }, [])
  return <></>
}

export default memo(AppWindowResizeListener)
