import {Box, List, ListSubheader} from "@mui/material";
import {loadStoreDataWrap} from "../../wraps/loadStoreDataWrap";
import {loadSensorDataWrap} from "../../wraps/loadSensorDataWrap";
import {storeNavbarWrap} from "../../wraps/storeNavbarWrap";
import React, {memo} from "react";
import AppListSubheaderSkeleton from "../../components/AppListSubheaderSkeleton";
import HelpModal from "../../components/HelpModal";
import LocationSensorSkeleton from "../StorePage/LocationSensorInStoreList/LocationSensorSkeleton";
import LocationListItem from "../StorePage/LocationSensorInStoreList/LocationListItem";
import SensorInStoreList from "../StorePage/LocationSensorInStoreList/SensorInStoreList";
import {useLoading} from "../../hooks/useLoading";
import {useSelector} from "react-redux";
import {loadDeviceDataWrap} from "../../wraps/loadDeviceDataWrap";
import DeviceListItem from "./DeviceListItem";
import {deviceNavbarWrap} from "../../wraps/deviceNavbarWrap";
import DeviceSensorTab from "../../components/DeviceSensorTab";

function DevicesPage() {
  const sensorsLoading = useLoading('gettingSensors')
  const devices = useSelector(state => state.devices.data)
  const numOfDevice = Object.keys(devices).length
  return (
    <Box>
      <DeviceSensorTab />
      <List
        aria-labelledby="location-sensor-list-subheader"
        subheader={
          <ListSubheader
            id='store-list'
            style={{
              display: 'flex',
              position: 'static',
              justifyContent: 'space-between',
            }}
          >
            {numOfDevice === 0 ? 'No device found' : `${numOfDevice} devices found`}
            <HelpModal/>
          </ListSubheader>
        }
      >
        {sensorsLoading
          ? (<LocationSensorSkeleton/>)
          : Object.keys(devices).map(deviceID => (
            <DeviceListItem device={devices[deviceID]} />
          ))}
      </List>
    </Box>
  )
}

export default loadStoreDataWrap(loadDeviceDataWrap(storeNavbarWrap(memo(DevicesPage))))