import { appNavbarWrap } from '../appNavbarWrap'
import React, { memo } from 'react'
import { Toolbar, Typography } from '@mui/material'
import NavBackButton from '../../components/NavBackButton'

export const archivedImageNavbarWrap = (Component) => {
  return appNavbarWrap(Component, memo(function Wrapped () {
    return (
      <Toolbar style={{
        justifyContent: 'left'
      }}>
        <NavBackButton/>
        <Typography style={{
          marginTop: '2px',
          marginLeft: '10px'
        }}>
          Archived Images
        </Typography>
      </Toolbar>
    )
  }))
}
