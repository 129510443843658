import { createAsyncThunk } from '@reduxjs/toolkit'
import { updateGlobalAction } from '../slices/global'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { clearSignInUsernamePasswordAction } from '../slices/signIn'

export const ssoSignInThunk = createAsyncThunk(
  'global/ssoLogin',
  async ({accessToken}, { getState, dispatch }) => {
    const result = await dispatch(fetchBackendAPIThunk({
      method: 'post',
      url: '/sso-signin',
      data: {
        accessToken
      },
      blockUi: true
    })).unwrap()
    dispatch(updateGlobalAction({
      username: result.username,
      token: result.token,
      iam: result.iam
    }))
    dispatch(clearSignInUsernamePasswordAction())
  }
)
