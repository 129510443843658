import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import ImageIcon from '@mui/icons-material/Image'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { archiveSensorImageThunk } from '../../../../thunks/archiveSensorImageThunk'
import {useSensor} from "../../../../hooks/useSensor";

SensorImageDrawerUnarchive.propTypes = {
  sensorImage: PropTypes.object.isRequired
}

function SensorImageDrawerUnarchive ({ sensorImage }) {
  const dispatch = useDispatch()
  const sensor = useSensor()
  const unarchiveSensorImage = () => {
    dispatch(archiveSensorImageThunk({ sensorImageId: sensorImage.sensorImageId, archive: false, sensorId: sensor.sensorId }))
  }
  return (
    <ListItemButton onClick={unarchiveSensorImage}>
      <ListItemIcon>
        <ImageIcon />
      </ListItemIcon>
      <ListItemText>
        Unarchive
      </ListItemText>
    </ListItemButton>
  )
}

export default memo(SensorImageDrawerUnarchive)
