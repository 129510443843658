import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useReactNativeMessageListener } from '../../hooks/useReactNativeMessageListener'
import { updateGlobalAction } from '../../slices/global'

const CONSTANTS = {
  UPDATE_USER_INFO: 'UPDATE_USER_INFO'
}

export default function ReactNativeConnector () {
  const dispatch = useDispatch()
  const global = useSelector(state => state.global)

  useReactNativeMessageListener((action) => {
    if (action.type === CONSTANTS.UPDATE_USER_INFO) {
      dispatch(updateGlobalAction(action.payload))
    }
  })

  useEffect(() => {
    const { username, token, iam } = global
    if (window.ReactNativeWebView && global.username) {
      const userAction = { type: CONSTANTS.UPDATE_USER_INFO, payload: { username, token, iam } }
      window.ReactNativeWebView.postMessage?.(JSON.stringify(userAction))
    }
  }, [global])

  return <React.Fragment></React.Fragment>
}
