import { generalHelpDetail } from '../helpDetails/generalHelpDetail'
import HelpSection from '../HelpSection'
import React, { memo } from 'react'
import { sensorHelpDetail } from '../helpDetails/sensorHelpDetail'
import { imageHelpDetail } from '../helpDetails/imageHelpDetail'

function HelpSections () {
  return (
    <>
      <HelpSection sectionName={'General'} helpDetail={generalHelpDetail} />
      <HelpSection sectionName={'Sensor'} helpDetail={sensorHelpDetail} />
      <HelpSection sectionName={'Image'} helpDetail={imageHelpDetail} />
    </>
  )
}

export default memo(HelpSections)
