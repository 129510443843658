import {ImageListItemBar, LinearProgress} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

DeviceImageListItemUploadProgressBar.propTypes = {
  percentage: PropTypes.number
}

function DeviceImageListItemUploadProgressBar({percentage}) {
  return (
    <ImageListItemBar
      style={{
        height: '100%'
      }}
      title={(
        <LinearProgress
          style={{
            borderRadius: '5px',
            height: '8px',
          }}
          variant={'determinate'}
          value={percentage}
        />
      )}
    />
  )
}

export default DeviceImageListItemUploadProgressBar