import PropTypes from 'prop-types'
import SensorFormHeader from '../SensorFormHeader'
import FormTitle from '../FormTitle'
import SensorIdTextField from '../SensorIdTextField'
import SensorTypeSelect from '../SensorTypeSelect'
import LocationCategorySelect from '../LocationCategorySelect'
import LocationNameSelect from '../LocationNameSelect'
import SensorNameSelect from '../SensorNameSelect'
import SensorFormSubmitButton from '../SensorFormSubmitButton'
import { Box } from '@mui/material'
import React from 'react'

SensorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

function SensorForm ({ onSubmit }) {
  return (
    <Box style={{
      padding: '0 10px'
    }}>
      <SensorFormHeader />
      <FormTitle>
        Sensor Info
      </FormTitle>
      <SensorIdTextField />
      <SensorTypeSelect />
      <FormTitle>
        Location Info
      </FormTitle>
      <LocationCategorySelect />
      <LocationNameSelect />
      <SensorNameSelect />
      <SensorFormSubmitButton onSubmit={onSubmit} />
    </Box>
  )
}

export default SensorForm
