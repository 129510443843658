import { Skeleton } from '@mui/material'
import React from 'react'

function AppListSubheaderSkeleton () {
  return (
    <Skeleton
      variant={'text'}
      width={100}
      height={30}
      style={{
        marginTop: '8px'
      }}
    />
  )
}

export default AppListSubheaderSkeleton
