import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAPIThunk } from './fetchApiThunk'
import { showSnackbarAction } from '../slices/snackbar'
import { signOutAction } from '../slices/global'

const baseURL = process.env.REACT_APP_API_URL + '/snapshot'

export const fetchBackendAPIThunk = createAsyncThunk(
  'util/fetchBackendAPI',
  async ({ method, url, params, data, blockUi, onUploadProgress }, { getState, dispatch, rejectWithValue }) => {
    const state = getState()
    const userToken = state.global.token
    let result
    try {
      result = await dispatch(fetchAPIThunk({
        config: {
          method,
          url,
          baseURL,
          params,
          data,
          onUploadProgress: progressEvent => {
            if (onUploadProgress) {
              onUploadProgress(Math.ceil((progressEvent.loaded / progressEvent.total) * 100))
            }
          },
          headers: {
            Authorization: userToken
          }
        },
        blockUi
      })).unwrap()
    } catch (e) {
      if (!e.response) {
        throw e
      }
      switch (e.response.status) {
        case 400: {
          dispatch(showSnackbarAction({
            severity: 'warning',
            message: e.response.data.msg
          }))
          return rejectWithValue(e)
        }
        case 401: {
          dispatch(showSnackbarAction({
            severity: 'info',
            message: e.response.data.msg
          }))
          dispatch(signOutAction())
          throw e
        }
        case 403: {
          dispatch(showSnackbarAction({
            severity: 'warning',
            message: e.response.data.msg
          }))
          throw e
        }
        case 500: {
          dispatch(showSnackbarAction({
            severity: 'error',
            message: e.response.data.msg
          }))
          throw e
        }
        default: {
          dispatch(showSnackbarAction({
            severity: 'error',
            message: 'Unknown Error'
          }))
          throw e
        }
      }
    }
    if (result.msg) {
      dispatch(showSnackbarAction({
        severity: 'info',
        message: result.msg
      }))
    }
    return result.data
  }
)
