const getSessionStorageData = (sessionStorageKey) => {
  let value = "";
  if (sessionStorage.getItem(sessionStorageKey) && sessionStorage.getItem(sessionStorageKey) !== 'undefined' && sessionStorage.getItem(sessionStorageKey) !== 'null') {
    value = JSON.parse(sessionStorage.getItem(sessionStorageKey));
  }
  return value;
}

export {
    getSessionStorageData
};
