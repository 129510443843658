import { sensorTypes } from '../../constants'
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat'
import Co2Icon from '@mui/icons-material/Co2'
import SensorDoorIcon from '@mui/icons-material/SensorDoor'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import React, { memo } from 'react'
import PropTypes from 'prop-types'

SensorTypeIcon.propTypes = {
  sensorType: PropTypes.string.isRequired,
  style: PropTypes.object,
  color: PropTypes.string
}

function SensorTypeIcon ({ sensorType, style, color }) {
  switch (sensorType) {
    case sensorTypes.TEMP: {
      return <DeviceThermostatIcon style={style} color={color} />
    }
    case sensorTypes.CO2: {
      return <Co2Icon style={style} color={color} />
    }
    case sensorTypes.DOOR: {
      return <SensorDoorIcon style={style} color={color} />
    }
    default: {
      return <DeviceHubIcon style={style} color={color} />
    }
  }
}

export default memo(SensorTypeIcon)
