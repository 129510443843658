import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { getSensorsThunk } from './getSensorsThunk'
import { newRelicAddPageActionThunk } from './newRelicAddPageActionThunk'
import {updateArchiveSensorImageAction} from "../slices/sensors";

export const archiveSensorImageThunk = createAsyncThunk(
  'util/archiveSensorImage',
  async ({ sensorImageId, archive, sensorId }, { dispatch, getState }) => {
    const currentState = getState()
    const storeIdForSensors = currentState.sensors.storeIdOfSensors
    await dispatch(await fetchBackendAPIThunk({
      method: 'post',
      url: 'archive_sensor_image',
      data: {
        sensorImageId,
        archive
      },
      params: {
        storeId: storeIdForSensors
      }
    })).unwrap()
    await dispatch(newRelicAddPageActionThunk({
      eventName: 'Sensor Image Archive',
      payload: {
        sensorImageId,
        archive,
        storeId: storeIdForSensors
      }
    })).unwrap()
    dispatch(updateArchiveSensorImageAction({
      sensorId,
      sensorImageId,
      archive
    }))
  })
