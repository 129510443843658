import { createSlice } from '@reduxjs/toolkit';

const initState = {
    loadingItems: [],
    isTicketStatusLoading: false,
}

const loadingSlice = createSlice({
    name:'loadings',
    initialState: initState,
    reducers: {
        setTicketStatusLoading: (state, action) => { //SET_TICKET_STATUS_LOADING
            state.isTicketStatusLoading = action.payload
        },
        addLoadingItem: (state, action) => { //ADD_LOADING_ITEM
          state.loadingItems = [...state.loadingItems, action.payload]
        },
        removeLoadingItem: (state, action) => { //REMOVE_LOADING_ITEM
          state.loadingItems = state.loadingItems.filter(item => item !== action.payload)
        }
    },
    extraReducers: (builder) => {
    }
})

export const { setTicketStatusLoading, addLoadingItem, removeLoadingItem } = loadingSlice.actions;
export default loadingSlice.reducer;