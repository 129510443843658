import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

HelpSection.propTypes = {
  sectionName: PropTypes.string.isRequired,
  helpDetail: PropTypes.object.isRequired
}

function HelpSection ({ sectionName, helpDetail }) {
  const searchText = useSelector(state => state.helpSearch.text.val)
  const theme = useTheme()
  const helpFragment = []
  for (const helpRecord of helpDetail) {
    if (searchText !== '' && !helpRecord.title.toLowerCase().includes(searchText.toLowerCase())) {
      continue
    }
    helpFragment.push(
      <Accordion key={helpRecord.title}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{helpRecord.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {helpRecord.content}
        </AccordionDetails>
      </Accordion>
    )
  }
  return helpFragment.length === 0
    ? <></>
    : (
    <Box>
      <Typography style={{
        padding: '5px',
        marginTop: '10px',
        fontSize: '16px',
        color: theme.palette.primary.light
      }}>
        {sectionName}
      </Typography>
      {helpFragment}
    </Box>
      )
}

export default memo(HelpSection)
