import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { getSensorsThunk } from './getSensorsThunk'
import { newRelicAddPageActionThunk } from './newRelicAddPageActionThunk'

export const postDeviceImageToBackendThunk = createAsyncThunk(
  'newDeviceImageToBackendThunk/newDeviceImageToBackendThunk',
  async ({ deviceId, deviceImageBlobKey, onUploadProgress }, { getState, dispatch }) => {
    const currentState = getState()
    const storeIdForDevices = currentState.devices.storeIdOfDevice
    const sensorImage = await dispatch(fetchBackendAPIThunk({
      method: 'post',
      url: '/device_image',
      data: {
        deviceId,
        deviceImageBlobKey
      },
      onUploadProgress,
      params: {
        storeId: storeIdForDevices
      },
      blockUi: false
    })).unwrap()
    await dispatch(newRelicAddPageActionThunk({
      eventName: 'Device Image Upload',
      payload: {
        deviceId,
        storeId: storeIdForDevices
      }
    })).unwrap()
    return sensorImage
  }
)
