import { helpSearchTextUpdateAction } from '../../../slices/helpSearch'
import AppTextField from '../../AppTextField'
import React, { memo } from 'react'
import { Box } from '@mui/material'

function HelpSearch () {
  return (
    <Box style={{
      margin: '10px 5px 10px 5px'
    }}>
      <AppTextField
        label={'Search for Questions'}
        fieldSelector={state => state.helpSearch.text}
        updateAction={helpSearchTextUpdateAction}
        variant={'standard'}
      />
    </Box>
  )
}

export default memo(HelpSearch)
