import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  addBlockLoadingItemAction,
  addLoadingItemAction,
  dropBlockLoadingItemAction,
  dropLoadingItemAction
} from '../slices/loading'
import { showSnackbarAction } from '../slices/snackbar'

export const fetchAPIThunk = createAsyncThunk(
  'util/fetchAPI',
  async ({ config, blockUi }, { dispatch, rejectWithValue }) => {
    if (blockUi) {
      dispatch(addBlockLoadingItemAction('fetchApi'))
    } else {
      dispatch(addLoadingItemAction('fetchApi'))
    }
    try {
      const response = await axios(config)
      return response.data
    } catch (e) {
      if (!e.response) {
        dispatch(showSnackbarAction({
          severity: 'error',
          message: 'Network error'
        }))
      }
      return rejectWithValue(e)
    } finally {
      if (blockUi) {
        dispatch(dropBlockLoadingItemAction('fetchApi'))
      } else {
        dispatch(dropLoadingItemAction('fetchApi'))
      }
    }
  }
)
