import {Tab, Tabs} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";

function DeviceSensorTab() {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    storeId
  } = useParams()
  const currentValue = location.pathname.endsWith('devices') ? 2 : 1

  const tabOnChange = (event, newValue) => {
    const newPath = `/snapshot/home/${storeId}` + (newValue === 2 ? '/devices' : '')
    navigate(newPath, {
      replace: true
    })
  }
  return (
    <Tabs
      style={{
        width: '100%'
      }}
      value={currentValue}
      onChange={tabOnChange}
    >
      <Tab
        style={{
          width: '50%'
        }}
        value={1} label={'Sensors'} />
      <Tab
        style={{
          width: '50%'
        }}
        value={2} label={'Devices'} />
    </Tabs>
  )
}

export default DeviceSensorTab