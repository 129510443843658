const Request = {
  azureLogin(email) {
    return {
      email: email,
    };
  },
  getRefreshToken(refreshToken) {
    return {
      refreshToken: refreshToken,
    };
  },
  setUserPersona(email, roleId, userPersonaConfig) {
    return {
      email: email,
      role_id: Number(roleId),
      userPersonaConfig:
        userPersonaConfig === "all" ? undefined : userPersonaConfig,
    };
  },
  setFeedback(request) {
    return {
      feedback: request?.feedback,
      first_name: request?.name,
      last_name: request?.lastName,
    };
  },
  // getIncidentCount(storeId, timeRange) {
  //   return {
  //     storeId: storeId,
  //     timeRange: timeRange
  //   }
  // },
  configureDevice(device_id, store_id, location, image_urls) {
    return {
      device_id,
      store_id,
      location,
      image_urls,
    };
  },
  getRunOpsConnectivityData() {
    return {};
  },

  //      //   if (obj.type === "Device")
  //     return obj.franke_device_id === actionPayload?.franke_device_id;
  //   if (obj.type === "Gateway") return obj.id === actionPayload?.id;
  //   if (obj.type === "Sensor")
  //     return obj.device_id === actionPayload?.device_id;

  addConnectivity(request) {
    //add
    return {
      storeId: request?.store_id ?? undefined, //gateway
      gatewayEui: request?.gatewayEui ?? undefined, //gateway
      name:
        request?.gatewayName ??
        request?.deviceName ??
        request?.sensor_type ??
        undefined, //gateway, device & sensor
      deviceId: request?.device_id ?? undefined, //sensor
      devEui: request?.devEui ?? undefined, //sensor
      joinEui: request?.joinEui ?? undefined, //sensor
      appKey: request?.appKey ?? undefined, //sensor
      store_id: request?.store_id ?? request?.franke_store_id ?? undefined, //sensor & device,
      device_id: request?.franke_device_id ?? undefined, //device
      serial_number: request?.franke_serial_number ?? undefined, //device
      device_type: request?.franke_device_id
        ? request?.device_type ?? undefined
        : undefined, //device
    };
  },
  updateConnectivity(request) {
    //edit
    return {
      store_id: request?.store_id ?? undefined, //gateway
      image_urls: request?.id || request?.device_id ? [] : undefined, //gateway & sensor
      deviceId: request?.device_id ?? undefined, //sensor
      store_id: request?.store_id ?? request?.franke_store_id ?? undefined, //sensor & device,
      device_id: request?.franke_device_id ?? undefined, //device
      serial_number: request?.franke_serial_number ?? undefined, //device
      device_type: request?.franke_device_id
        ? request?.device_type ?? undefined
        : undefined, //device
    };
  },
  deleteConnectivity(request) {
    return {
      deviceId: request?.device_id ?? undefined, //sensor
      ...(request.type === "Gateway" && {
        gatewayId: request?.id ?? undefined,
      }), //gateway
      // ...(gatewayId: request?.id ?? undefined,
      device_id: request?.franke_device_id ?? undefined, //device
    };
  },
  getRunOpsUserData() {
    return {};
  },
  deleteUser(request) {
    return { userId: request?.email };
  },
  updateUserData(request) {
    const { first_name, last_name, role_id, email } = request;
    return {
      userId: email,
      first_name: first_name,
      last_name: last_name,
      // role_id: role_id,
    };
  },
  getRunOpsAnalyticsData() {
    return {};
  },
  getRunOpsQueueData() {
    return {};
  },
  updateAccessRequest(request, email) {
    return {
      status: request?.status,
      accessRequestId: Number(request?.accessRequestId),
      actionBy: request?.actionBy ?? email
    };
  },
  createTicketForNotification(
    alert_id,
    store_id,
    short_description,
    description
  ) {
    return { alert_id, store_id, short_description, description };
  },
  setTicketStatus(request) {
    return { 
      alert_id: request?.alertId, 
      ticket_number: request?.ticketNumber, 
      status: request?.status 
    };
  },
};
export default Request;
