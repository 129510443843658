import { Box, useTheme } from '@mui/material'
import React, { memo } from 'react'
import PropTypes from 'prop-types'

FormTitle.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node)
}

function FormTitle ({ children }) {
  const theme = useTheme()
  return (
    <>
      <Box style={{
        color: theme.palette.primary.main,
        fontSize: '12px',
        margin: '20px 0 5px 5px'
      }}>
        {children}
      </Box>
    </>
  )
}

export default memo(FormTitle)
