import { Box, Button, Collapse } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import React from 'react'
import { useSelector } from 'react-redux'

function StoreSearchBarCloseKeyboardButton () {
  const storeSearchFocus = useSelector(state => state.storeSearch.text.focus)
  return (
    <Collapse
      orientation={'horizontal'}
      in={storeSearchFocus}
    >
      <Box style={{
        width: '85px'
      }}>
        <Button
          variant={'contained'}
          style={{
            height: '55px'
          }}
        >
          <KeyboardArrowDownIcon style={{
            fontSize: '30px'
          }}/>
        </Button>
      </Box>
    </Collapse>
  )
}

export default StoreSearchBarCloseKeyboardButton
