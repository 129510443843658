import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addRunOpsLoadingItem, removeRunOpsLoadingItem } from "./loadingSlice";
import RunOpsDataTemp from "../../Temp/RunOpsDataTemp";
import continuousGetDataRequest from "../../utlities/rest/continuousGetDataRequest";
import RestClient from "../../api/RestClient";
import Request from "../../request/Request";
import { worker } from "../../worker";

const runOpsRestDataTemp = RunOpsDataTemp.getInst();

const initState = {
  isError: false,
  cardData: [],
  miscellaneousData: {},
};

export const loadAnalyticsData = createAsyncThunk(
  //loadCategoryData - loadCategoryData(pageId)
  "analytics/loadAnalyticsData",
  async (pageId, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const request = Request.getRunOpsAnalyticsData();
    const state = getState();

    dispatch(addRunOpsLoadingItem("analyticsData"));
    let parsedResult = runOpsRestDataTemp.get({
      requestCalls: JSON.stringify(request),
      newRelicApiName: "analyticsData",
    });

    if (parsedResult) {
      dispatch(removeRunOpsLoadingItem("analyticsData"));
      return fulfillWithValue({
        analyticsData: parsedResult?.analyticsData,
        error: parsedResult.error,
      });
    }

    const res = await continuousGetDataRequest(
      RestClient.getConnectivityData,
      request,
      dispatch,
      true
    );
    // const res = state?.runOpsData?.pages?.analytics?.cardData
    if (res) {
      const analyticsData = await worker("processAnalyticsData", res);
      runOpsRestDataTemp.set(
        {
          requestCalls: JSON.stringify(request),
          newRelicApiName: "analyticsData",
        },
        { analyticsData: analyticsData, error: false }
      );
      dispatch(removeRunOpsLoadingItem("analyticsData"));
      return fulfillWithValue({ analyticsData: analyticsData, error: false });
    } else {
      // runOpsRestDataTemp.set(
      //   {
      //     requestCalls: JSON.stringify(request),
      //     newRelicApiName: "analyticsData",
      //   },
      //   { analyticsData: [], error: true }
      // );
      return rejectWithValue();
    }
  }
);

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: initState,
  reducers: {
    updateAnalyticsData: (state, action) => {
      //UPDATE_ANALYTICS_DATA
      state[action.payload?.key] = action.payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAnalyticsData.fulfilled, (state, action) => {
      state.cardData = action.payload?.analyticsData?.list;
      state.isError = action.payload?.error;
      state.miscellaneousData = action.payload?.analyticsData?.countData;
    });
    builder.addCase(loadAnalyticsData.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export const { getAnalyticsData } = analyticsSlice.actions;
export default analyticsSlice.reducer;
