import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const DEFSTATE = {
  snackBar: {
    isOpen: false,
    severity: "success", //"success,warning,info,error"
    message: "",
    vertical: "bottom",
    horizontal: "left",
  },
  runOps: {
    hardwareType: "all",
    quickSearchText: "",
  },
};

export const getTokenValues = createAsyncThunk( //getTokenValues - getTokenValues({errorMessage, cb})
  'globalData/getTokenValues',
  async (actionPayload, { rejectWithValue, fulfillWithValue, getState, dispatch }) => { //thunkAPI is the second argument
    const {cb} = actionPayload  
    cb({
      sessionId: getState()?.authData?.sessionId, 
      csrfToken: getState()?.authData?.csrfToken,
      email: getState()?.authData?.email
    })
  }
)

export const globalSlice = createSlice({
  name:'globalData',
  initialState: DEFSTATE,
  reducers: {
      showSnackBar: (state, action) => { //showSnackBar - showSnackBar({severity, message, vertical, horizontal})
        const {severity, message, vertical, horizontal} = action.payload
        state.snackBar = { 
          isOpen: true, 
          severity: severity, 
          message: message, 
          vertical: vertical,
          horizontal: horizontal, 
        }
      },
      hideSnackBar: (state, action) => { //hideSnackBar - hideSnackBar()
        state.snackBar = { isOpen: false, severity: "success", message: "" }
      }
      // testAsyncDispatch:(state)=>{
      //     state.test = true
      // }
  },
  extraReducers: (builder) => {
  }
})

export const { showSnackBar, hideSnackBar } = globalSlice.actions;
export default globalSlice.reducer;