import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";

import './pages/InsightDashboard/index'

// @ag-grid-community/core will always be implicitly available
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

import reducers from "./reducers/index";
import './style/tiny-scrollbar.css'
import App from "./App";
import './chartjsInit';
import {ErrorBoundary} from "react-error-boundary";
import {newRelicNoticeError} from "../src/utlities/newrelic/newRelicNoticeError";
import Snapshot from "./subsites/Snapshot";
import ThemeSelector from "./ThemeSelector";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ClipboardModule,
  CsvExportModule,
  ExcelExportModule,
  MasterDetailModule,
  MenuModule,
  RowGroupingModule,
  SetFilterModule,
]);

let nodeEnv = process.env.NODE_ENV
if (!nodeEnv) {
  nodeEnv = 'production'
}

const {
  REACT_APP_AGGRID_LICENSE,
} = process.env;
LicenseManager.setLicenseKey(REACT_APP_AGGRID_LICENSE);

const email = reducers.getState()?.authData?.email;
const sessionId = reducers.getState()?.authData?.sessionId;

const component = (
  <Provider store={reducers}>
    <App />
  </Provider>
)
const componentForProd = (
  <ErrorBoundary
    onError={(e) => {
      newRelicNoticeError(e, sessionId, email)
      setTimeout(() => {
        window.location.href = '/error'
      }, 1000)
    }}
    fallback={<></>}
  >
    {component}
  </ErrorBoundary>
)
const componentForRender = nodeEnv === 'development' ? component : componentForProd

const root = ReactDOM.createRoot(document.getElementById("root") || document.createElement('div'))

const pathname = window.location.pathname

if (pathname.startsWith('/snapshot')) {
  root.render(
      <ThemeSelector>
        <Snapshot />
      </ThemeSelector>
  )
} else {
  root.render(
    <ThemeSelector>
      {componentForRender}
    </ThemeSelector>
  )
}