export function convertKeysToLowerCase(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToLowerCase(item));
  }

  const lowerCaseObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const lowerCaseKey = key.toLowerCase();
      lowerCaseObj[lowerCaseKey] = convertKeysToLowerCase(obj[key]);
    }
  }

  return lowerCaseObj;
}
