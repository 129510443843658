import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  username: null,
  token: null,
  iam: null,
  azureBlobSas: null,
  ssoTokens: {
    accessToken: null,
    refreshToken: null,
    idToken: null
  }
}

const setStateToSession = (state) => {
  sessionStorage.setItem('global', JSON.stringify(state))
}

const getStateFromSession = () => {
  const globalSession = sessionStorage.getItem('global')
  return globalSession ? JSON.parse(globalSession) : null
}

const stateFromSession = getStateFromSession()

const globalSlice = createSlice({
  name: 'global',
  initialState: stateFromSession || initialState,
  reducers: {
    signOutAction: () => {
      sessionStorage.removeItem('global')
      return initialState
    },
    updateGlobalAction: (state, action) => {
      const newState = {
        ...state,
        ...action.payload
      }
      setStateToSession(newState)
      return newState
    }
  }
})

export const { signOutAction, updateGlobalAction } = globalSlice.actions

export const globalReducer = globalSlice.reducer
