import React, { memo, useState } from 'react'
import { Box, ImageList, ImageListItem, ListSubheader } from '@mui/material'

import SensorImageListItem from '../../../components/SensorImageListItem'
import NewImgItemButton from './NewImgItemButton'
import ConditionalRenderByPermission from '../../../components/ConditionalRenderByPermission'
import { useSensor } from '../../../hooks/useSensor'
import HelpModal from '../../../components/HelpModal'

function SensorImageList () {
  const sensor = useSensor()
  let numOfImg = 0
  for (const sensorImage of sensor.sensorImages) {
    if (!sensorImage.isArchived) {
      numOfImg += 1
    }
  }
  return (
    <ImageList
      cols={2}
      gap={8}
    >
      <ImageListItem
        key={'Subheader'}
        cols={2}
      >
        <ListSubheader
          id='store-list'
          style={{
            display: 'flex',
            position: 'static',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            {
              numOfImg === 0
                ? 'Click to upload image'
                : `${numOfImg} images found`
            }
          </Box>
          <HelpModal />
        </ListSubheader>
      </ImageListItem>
      <ConditionalRenderByPermission permissionType={'uploadSensorImage'}>
        <NewImgItemButton />
      </ConditionalRenderByPermission>
      {sensor.sensorImages.map(sensorImage => {
        if (!sensorImage.isArchived) {
          return (
            <SensorImageListItem key={sensorImage.sensorImageId} sensorImage={sensorImage}/>
          )
        } else {
          return <></>
        }
      })}
    </ImageList>
  )
}

export default memo(SensorImageList)
