import React, { memo } from 'react'
import PropTypes from 'prop-types'

MemorizedChildren.propTypes = {
  element: PropTypes.arrayOf(PropTypes.node)
}

function MemorizedChildren ({ element }) {
  return <>{element}</>
}

export default memo(MemorizedChildren)
