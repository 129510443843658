import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  storeId: {
    val: '',
    err: false,
    required: true
  }
}

const newStoreSlice = createSlice({
  name: 'newStore',
  initialState,
  reducers: {
    updateNewStoreFieldAction: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    clearNewStoreAction: () => {
      return initialState
    }
  }
})

export const { updateNewStoreFieldAction, clearNewStoreAction } = newStoreSlice.actions

export const newStoreReducer = newStoreSlice.reducer
