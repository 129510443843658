export function formatDateToUSFormat(time) { // 1. convert yyyy-mm-dd -> yyyy/mm/dd, 2. convert to date format
    return new Date(time?.split(' ')[0].replace(/-/g, '\/').replace(/T/, ' ').replace(/Z/, ' '));
}

export function formatDateToSwedenFormat(date) { // mm/dd/yyyy -> yyyy-mm-dd
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


export const convertUTCDateToLocalDate = (date) => {
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
}

export function formatUtcToLocal(time) { // (utc -> local)
    let newTime = convertUTCDateToLocalDate(new Date(time));
    return newTime?.toLocaleString("en-US")
}

export function formatLocalToUtc(date) { // (local -> utc)
    let isoDateString = new Date(date)?.toISOString();
    const utcDate = new Date(isoDateString?.replace(/-/g, '\/').replace(/T/, ' ').replace(/Z/, ' '))?.toLocaleDateString();
    var d = new Date(utcDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}