import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllStoresThunk } from '../../thunks/getAllStoresThunk'
import { Box } from '@mui/material'
import AppError from '../../components/AppError'

export const loadStoreDataWrap = (Component, renderAfterLoad = false, Skeleton) => {
  return memo(function Wrapped () {
    const dispatch = useDispatch()
    const allStores = useSelector(state => state.allStores)
    const storeLoaded = Object.keys(allStores.data).length > 0
    const error = allStores.err
    useEffect(() => {
      if (!storeLoaded) {
        dispatch(getAllStoresThunk({}))
      }
    }, [])
    if (error) {
      return (
        <Box>
          <AppError />
        </Box>
      )
    }
    if (renderAfterLoad) {
      if (storeLoaded) {
        return <Component />
      } else {
        return <Skeleton />
      }
    } else {
      return <Component />
    }
  })
}
