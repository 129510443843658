import RestClient from "../../api/RestClient";
import CategoryRestDataTemp from "../../Temp/CategoryRestDataTemp";
import continuousGetDataRequest from "./continuousGetDataRequest";

export const getCategoryDataFromBackend = async (pageCategoryDetails, obj, dispatch) => {
    const {newRelicApiName, components} = pageCategoryDetails;
    const {requests, apiCall} = obj;
    if(components) {
      const categoryRestDataTemp = CategoryRestDataTemp.getInst()

      let restResult = categoryRestDataTemp.get({
        requestCalls: JSON.stringify(requests),
        newRelicApiName
      })
      if (restResult) {
        return restResult
      }

      restResult = new Array()
      restResult[0] = await continuousGetDataRequest(RestClient[apiCall[0]], requests[0], dispatch)

      restResult = await Promise.all(
        requests.map(async (requestDetails, index) => {
           if(index === 0) return restResult[index]
           else return await continuousGetDataRequest(RestClient[apiCall[index]], requestDetails, dispatch)
        })
      );

      if (restResult.some(result => result === null)) {
        return null
      }
      categoryRestDataTemp.set({
        requestCalls: JSON.stringify(requests),
        newRelicApiName
      }, restResult)
      
      return restResult
    }
}