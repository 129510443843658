import { Box } from '@mui/material'
import React, { memo } from 'react'
import PropTypes from 'prop-types'

FormHeader.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node)
}

function FormHeader ({ children }) {
  return (
    <Box style={{
      padding: '40px',
      display: 'flex',
      justifyContent: 'center'
    }}>
      {children}
    </Box>
  )
}

export default memo(FormHeader)
