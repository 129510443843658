import React, {memo, useState} from 'react'
import {Backdrop, Box, Fab, SpeedDial, SpeedDialAction} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch } from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import { cleanSensorInfoFormAction } from '../../../slices/sensorInfoForm'
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import SensorsIcon from '@mui/icons-material/Sensors';

function StoreNavbarAddSensorButton () {
  const dispatch = useDispatch()
  const {
    storeId
  } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [open, setOpen] = useState()
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        icon={<AddIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        <SpeedDialAction
          icon={<SensorsIcon />}
          tooltipTitle={'Sensor'}
          tooltipOpen
          onClick={() => {
            dispatch(cleanSensorInfoFormAction())
            navigate(`/snapshot/home/${storeId}/new-sensor`)
          }}
        />
        <SpeedDialAction
          icon={<DeviceHubIcon />}
          tooltipTitle={'Device'}
          tooltipOpen
          onClick={() => {
            navigate(`/snapshot/home/${storeId}/add-device`)
          }}
        />
      </SpeedDial>
    </>
  )
}

export default memo(StoreNavbarAddSensorButton)
