import { worker } from "../../worker";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AllNotificationTemp from "../../Temp/AllNotificationTemp";
import { getNotificationFromBackend } from "../../utlities/rest/getNotificationFromBackend";
import continuousGetDataRequest from "../../utlities/rest/continuousGetDataRequest";
import RestClient from "../../api/RestClient";
import { addLoadingItem, removeLoadingItem } from "./loadingSlice";
import { showSnackBar } from "../globalSlice";
import { formatDateToSwedenFormat } from "../../utlities/formatTime"

/*
DetailedNotification - IotDashboard Main Page elaborated notifications
Notification - Notification Page elaborated notifications
'' - notification data for table in Error codes and Ticket Category
*/

const initState = {
  notificationFilter: {
    sortBy: "A-Z",
    allEquipmentType: [],
    equipmentType: [],
    alertType: 'All',
    sortByDetailedNotification: "A-Z",
    allEquipmentTypeDetailedNotification: [],
    equipmentTypeDetailedNotification: [],
    sortByNotification: "A-Z",
    alertTypeNotification: 'All',
    alertTypeDetailedNotification: 'All',
    allEquipmentTypeNotification: [],
    equipmentTypeNotification: []
  },
  allNotifications: [],
  notifications: [],
  filteredNotifications: [],
  filteredNotificationsDetailedNotification: [],
  filteredNotificationsNotification: [],
  syncTime: '',
  notificationReloadTrigger: 0,
  notificationModal: null,
  notificationStoresLength: 5,
  storeIdForNotification: null
}

export const loadAllNotifications = createAsyncThunk(
  //loadAllNotifications - loadAllNotifications(pageId)
  "notificationData/loadAllNotifications",
  async (pageId, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const state = getState();
    const pageAlertDetails =
      state.iotDashboardData?.setting?.pageAlertDetails;
    const {
      store,
      region,
      endTimeRange
    } = state?.iotDashboardData?.setting?.filter
    const maxStartTimeRange = state?.iotDashboardData?.setting?.maxStartTimeRange
    const storeLength = state.iotDashboardData.notificationData.notificationStoresLength
    const oldNotifications = state?.iotDashboardData?.notificationData?.allNotifications
    const storeIdForNotification = state?.iotDashboardData?.notificationData?.storeIdForNotification
    // const allNotificationTemp = AllNotificationTemp.getInst()

    dispatch(addLoadingItem('allNotifications'))
    const startDate = new Date(formatDateToSwedenFormat(maxStartTimeRange))
    const today = new Date(formatDateToSwedenFormat(endTimeRange))
    const startDurationInMs = today.getTime() - startDate.getTime()
    const startDuration = Math.floor(startDurationInMs / 1000 / 60 / 60)
    const request = {
      filters: {
        startDuration: startDuration > 72 ? 72 : startDuration,
        durationType: "HOURS",
        store_id: storeIdForNotification ?? (store?.length > 5 ? store?.slice(storeLength - 5, storeLength) : store),
      },
      deviceType: {
        "sensor": [
          "Temperature",
          "StatusDoor"
        ],
        "appliance": [
          "Franke"
        ]
      }
      // deviceType: {
      //   "sensor": pageAlertDetails?.filter(item => item.deviceType === "sensor")?.map(item => item?.Type),
      //   "appliance": pageAlertDetails?.filter(item => item.deviceType === "appliance")?.map(item => item?.Type)
      // },
    }

    let rawNotifications;
    if (region.length <= 2 && store.length <= 30) {
      rawNotifications = await getNotificationFromBackend(request, dispatch)
    } else {
      dispatch(
        showSnackBar({
          severity: "warning",
          message:
            "Cannot select more than 2 regions or 30 stores, please adjust your filter",
        })
      );
    }
    if (rawNotifications) {
      const { processedNotifications, equipmentTypes } = await worker('processNotifications', {
        notifications: (storeIdForNotification || storeLength === 5) ? [...rawNotifications] : [...oldNotifications, ...rawNotifications],
        pageAlertDetails
      })

      const allNotifications = await worker('filterNotificationsByTimeRange', {
        notifications: processedNotifications,
        startTimeRange: maxStartTimeRange,
        endTimeRange: endTimeRange
      })

      dispatch(removeLoadingItem('allNotifications'));
      return fulfillWithValue({
        equipmentTypes: Array.from(equipmentTypes),
        allNotifications: allNotifications,
      });
    } else {
      return rejectWithValue()
    }
  }
);

export const loadFilteredNotification = createAsyncThunk(
  //loadFilteredNotification - loadFilteredNotification(source)
  "notificationData/loadFilteredNotification",
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const state = getState();
    const { source, duration } = obj;
    const pageAlertDetails =
      state.iotDashboardData?.setting?.pageAlertDetails;
    const allNotifications = state?.iotDashboardData?.notificationData?.allNotifications
    const sortBy = state?.iotDashboardData?.notificationData?.notificationFilter[`sortBy${source}`]
    const alertType = state?.iotDashboardData?.notificationData?.notificationFilter[`alertType${source}`]
    const equipmentType = state?.iotDashboardData?.notificationData?.notificationFilter[`equipmentType${source}`]
    const { endTimeRange } = state?.iotDashboardData?.setting?.filter
    // const allNotificationTemp = AllNotificationTemp.getInst()

    dispatch(addLoadingItem('filteredNotifications'))

    let newFilteredNotifications = [...allNotifications]
    newFilteredNotifications = await worker('filterNotificationsByTimeRange', {
      notifications: allNotifications,
      startTimeRange: duration,
      endTimeRange: endTimeRange
    })
    newFilteredNotifications = await worker('filterNotificationsByEquipmentType', {
      notifications: newFilteredNotifications,
      equipmentType: equipmentType
    })
    newFilteredNotifications = await worker('filterNotificationsByAlertType', {
      notifications: newFilteredNotifications,
      alertType
    })
    newFilteredNotifications = await worker('sortNotifications', {
      notifications: newFilteredNotifications,
      sortBy: sortBy
    })

    dispatch(removeLoadingItem('filteredNotifications'));
    return fulfillWithValue({
      filteredNotification: newFilteredNotifications,
      source,
    });
  }
);

export const createTicket = createAsyncThunk(
  //createTicket - createTicket({alertId, storeId, title, content, address, errorData, sensor, deviceId, deviceName})
  "notificationData/createTicket",
  async (actionPayload, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const { alertId, storeId, title, content, address, errorData, sensor, deviceId, deviceName, errorType } = actionPayload
    dispatch(addLoadingItem('createTicket'))
    const allNotificationTemp = AllNotificationTemp.getInst()
    let result
    try {
      result = await continuousGetDataRequest(
        RestClient.createTicketForNotification,
        {
          alert_id: alertId,
          description: content,
          short_description: title,
          store_id: storeId,
          error_data: errorData,
          address: address,
          sensor: sensor,
          device_id: deviceId,
          device_name: deviceName,
          error_type: errorType
        },
        dispatch,
        false,
        false,
        false,
        false,
        false,
        true
      )
    } catch (e) {
      throw e
    } finally {
      dispatch(removeLoadingItem('createTicket'))
    }
    if (result) {
      dispatch(showSnackBar({
        severity: "success",
        message: `Ticket ${result?.ticket_number} has been created`
      }))
      allNotificationTemp.clear()
      dispatch(notificationReload({
        alertId,
        ticketNumber: result?.ticket_number,
        isDismissed: false,
        resolved: false
      }))
    }
  }
);

export const dismissTicket = createAsyncThunk(
  //dismissTicket - dismissTicket({deviceId, alertId, isResolved})
  "notificationData/dismissTicket",
  async (actionPayload, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const { deviceId, alertId, isResolved, storeId, createTicket, errorType } = actionPayload
    const action = isResolved ? "resolved" : "dismissed";
    dispatch(addLoadingItem("dismissTicket"));
    const allNotificationTemp = AllNotificationTemp.getInst();
    const result = await continuousGetDataRequest(
      RestClient.dismissTicketForNotification,
      {
        device_id: deviceId,
        alert_id: alertId,
        is_resolved: isResolved,
        store_id: storeId, 
        is_service_now: createTicket === 1, 
        error_type: errorType, 
        channel: "dashboard"
      },
      dispatch
    );
    dispatch(removeLoadingItem("dismissTicket"));
    if (result) {
      dispatch(showSnackBar({
        severity: "success",
        message: `Alert has been ${action}`
      }))
      allNotificationTemp.clear();
      dispatch(notificationReload({
        alertId,
        ticketNumber: null,
        isDismissed: !isResolved,
        resolved: isResolved
      }))
    }
  }
);

const notificationSlice = createSlice({
  name: 'notificationData',
  initialState: initState,
  reducers: {
    setAlertsData: (state, action) => {
      //GET_DATAS
      state[action.payload?.key] = action.payload?.data;
    },
    updateNotificationFilter: (state, action) => { //UPDATE_NOTIFICATION_FILTER
      const { sortBy, equipmentType, alertType, source } = action.payload
      const newNotificationFilter = {
        ...state.notificationFilter,
        [`sortBy${source}`]: sortBy ? sortBy : state.notificationFilter[`sortBy${source}`],
        [`equipmentType${source}`]: equipmentType ? equipmentType : state.notificationFilter[`equipmentType${source}`],
        [`alertType${source}`]: alertType ? alertType : state.notificationFilter[`alertType${source}`],
      }
      state.notificationFilter = newNotificationFilter
    },
    updateFilteredNotifications: (state, action) => { //UPDATE_FILTERED_NOTIFICATIONS
      const { filteredNotification, source } = action.payload
      state[`filteredNotifications${source}`] = filteredNotification
    },
    updateTicketStatus: (state, action) => { //UPDATE_TICKET_STATUS
      const { alertId, status } = action.payload
      const newNotifications = [...state.allNotifications]
      for (const notification of newNotifications) {
        if (notification.Alert_Id === alertId) {
          notification.Status = status
        }
      }
      state.notifications = newNotifications
    },
    notificationReload: (state, action) => { //NOTIFICATION_RELOAD
      const { alertId, ticketNumber, isDismissed, resolved } = action.payload
      const newNotifications = [...state.allNotifications]
      for (const notification of newNotifications) {
        if (notification.Alert_Id === alertId) {
          notification.Status = ticketNumber === null ? notification.Status : "success"
          notification.Ticket_Number = ticketNumber
          notification.isDismissed = isDismissed
          notification.resolved = resolved
        }
      }
      state.allNotifications = newNotifications
    },
    setNotificationModal: (state, action) => { //SET_NOTIFICATION_MODAL
      state.notificationModal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAllNotifications.fulfilled, (state, action) => {
      const { equipmentTypes, allNotifications } = action.payload
      state.notificationFilter = {
        ...state.notificationFilter,
        allEquipmentType: equipmentTypes,
        equipmentType: equipmentTypes,
        allEquipmentTypeNotification: equipmentTypes,
        equipmentTypeNotification: equipmentTypes,
        allEquipmentTypeDetailedNotification: equipmentTypes,
        equipmentTypeDetailedNotification: equipmentTypes
      }
      state.allNotifications = allNotifications
    });
    builder.addCase(loadFilteredNotification.fulfilled, (state, action) => {
      const { filteredNotification, source } = action.payload
      state[`filteredNotifications${source}`] = filteredNotification
    });
  }
})

export const { setAlertsData, updateNotificationFilter, updateFilteredNotifications, updateTicketStatus, notificationReload,
  setNotificationModal } = notificationSlice.actions;
export default notificationSlice.reducer;