import { Box } from '@mui/material'
import AppSelect from '../AppSelect'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { updateSensorInfoFormFieldAction } from '../../slices/sensorInfoForm'
import { getLocationCategories } from '../../utils/sensorDetail'

function LocationCategorySelect () {
  const currentSensorType = useSelector(state => state.sensorInfoForm.sensorType)
  const updateAction = (val) => updateSensorInfoFormFieldAction({
    locationCategory: val
  })
  return (
    <Box style={{
      margin: '10px 0'
    }}>
      <AppSelect
        updateAction={updateAction}
        fieldSelector={state => state.sensorInfoForm.locationCategory}
        label={'Location Category'}
        availableOptions={getLocationCategories(currentSensorType.val)}
      />
    </Box>
  )
}

export default memo(LocationCategorySelect)
