import {createAsyncThunk} from "@reduxjs/toolkit";
import {confirmThunk} from "./confirmThunk";
import {Typography} from "@mui/material";
import moment from "moment/moment";

export const reboardConfirmThunk = createAsyncThunk(
  'appConfirmEvent/reboardConfirmTHunk',
  async ({sensorDetail}, {getState, dispatch}) => {
    const sensorCreatedAt = moment(sensorDetail.createdAt)
    return await dispatch(confirmThunk({
      content: (
        <Typography>
          <Typography>
            This sensor has already onboarded:
          </Typography>
          <ul style={{
            fontSize: '14px',
            padding: '10px',
            color: '#666'
          }}>
            <li>
              Store: #{sensorDetail.storeDetail.store_id}
            </li>
            <li>
              Location Category: {sensorDetail.locationCategory}
            </li>
            <li>
              Location Name: {sensorDetail.locationName}
            </li>
            <li>
              Sensor Name: {sensorDetail.sensorName}
            </li>
            <li>
              When: {sensorCreatedAt.format('lll')}
            </li>
          </ul>
          <Typography>
            Do you want to reboard this sensor?
          </Typography>
        </Typography>
      )
    })).unwrap()
  }
)