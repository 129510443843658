import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Alert, AlertTitle } from "@mui/material";
import { hideSnackBar } from "../../reducers/globalSlice";

function SnackBar() {
  const dispatch = useDispatch();
  const {
    isOpen,
    severity,
    message,
    vertical = "bottom",
    horizontal = "left",
  } = useSelector((state) => state.globalData.snackBar);

  const handleClose = () => dispatch(hideSnackBar());
  return (
    <Snackbar
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      className="snackbar"
    >
      <Alert
        severity={severity}
        onClose={handleClose}
        variant="filled"
        className="alert"
      >
        <AlertTitle>
          <strong>
            {severity?.charAt(0).toUpperCase() + severity?.slice(1)}
          </strong>
        </AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackBar;
