import { Box } from '@mui/material'
import React, { memo } from 'react'
import { sensorNavbarWrap } from '../../wraps/sensorNavbarWrap'
import SensorImageList from './SensorImageList'
import { loadSensorDataWrap } from '../../wraps/loadSensorDataWrap'
import { loadStoreDataWrap } from '../../wraps/loadStoreDataWrap'
import SensorPageSkeleton from '../../components/pageSkeletons/SensorPageSkeleton'
import SensorInfoCard from './SensorInfoCard'

function SensorPage () {
  return (
    <Box>
      <SensorInfoCard />
      <SensorImageList/>
    </Box>
  )
}

export
default loadStoreDataWrap(
  loadSensorDataWrap(
    sensorNavbarWrap(memo(SensorPage)),
    true, SensorPageSkeleton
  ),
  true,
  SensorPageSkeleton
)
