import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material'
import StoreIcon from '@mui/icons-material/Store'
import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'
import TouchListeningBox from '../../../../components/TouchListeningBox'
import StoreButtonDrawer from './StoreButtonDrawer'
import { useDispatch, useSelector } from 'react-redux'
import { storeSelector } from '../../../../appSelectors/storeSelector'
import { updateStoreSearchTextAction } from '../../../../slices/storeSearch'

StoreButton.propTypes = {
  storeId: PropTypes.string.isRequired
}

function StoreButton ({ storeId }) {
  const navigate = useNavigate()
  const store = useSelector(storeSelector(storeId))
  const [drawerOpen, setDrawerOpen] = useState(false)
  const theme = useTheme()
  const dispatch = useDispatch()
  const storeNeedHelp = store.numOfSensorDown !== 0
  return (
    <>
      <TouchListeningBox
        onTap={() => {
          navigate(`/snapshot/home/${storeId}`)
          dispatch(updateStoreSearchTextAction({
            focus: false
          }))
        }}
        onTapHold={() => setDrawerOpen(true)}
      >
        <ListItemButton>
          <ListItemIcon>
            <StoreIcon color={storeNeedHelp ? 'secondary' : 'primary'}/>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography>
                <Typography
                  style={{
                    color: storeNeedHelp ? theme.palette.secondary.main : theme.palette.primary.main,
                    marginRight: '5px',
                    fontSize: '14px'
                  }}
                  component={'span'}
                >
                  STORE
                </Typography>
                {storeId}
              </Typography>
            )}
            secondary={(
              <Typography
                style={{
                  color: '#999',
                  fontSize: '12px'
                }}
              >
                <Typography style={{
                  fontSize: 'inherit'
                }}>
                  {`${store.address}, ${store.city}`}
                </Typography>
                {store.numOfSensorDown !== 0 && (
                  <Typography style={{
                    fontSize: 'inherit',
                    color: theme.palette.error.main
                  }}>
                    {`${store.numOfSensorDown} sensors down`}
                  </Typography>
                )}
              </Typography>
            )}
          />
        </ListItemButton>
      </TouchListeningBox>
      <Divider />
      <StoreButtonDrawer setOpen={setDrawerOpen} storeId={storeId} open={drawerOpen} />
    </>
  )
}

export default memo(StoreButton)
