import { IconButton } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'

function NavBackButton () {
  const navigate = useNavigate()
  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      onClick={() => navigate(-1)}
      sx={{
        marginLeft: 0
      }}
    >
      <ArrowBackIosIcon/>
    </IconButton>
  )
}

export default memo(NavBackButton)
