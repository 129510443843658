import {Box, Typography} from '@mui/material'
import React from 'react'

import howToUpload1 from '../../../assets/imgs/help/howToUpload/1.png'
import howToArchiveImg1 from '../../../assets/imgs/help/howToArchiveImg/1.png'
import howToUnarchiveImg1 from '../../../assets/imgs/help/howToUnarchiveImg/1.png'
import howToUnarchiveImg2 from '../../../assets/imgs/help/howToUnarchiveImg/2.png'



import HelpImg from "../HelpImg";

export const imageHelpDetail = [
  {
    title: 'How to upload image',
    content: (
      <>
        <Typography>
          Go to sensor page, click on the camera icon
        </Typography>
        <HelpImg src={howToUpload1} />
        <Typography>
          Choose a image from your gallery or just take a photo.
        </Typography>
      </>
    )
  },
  {
    title: 'How to archive image',
    content: (
      <>
        <Typography>
          If you don't like the image you uploaded, you can archive the image
        </Typography>
        <Typography>
          Go to sensor page, then long press the image you want to archive
        </Typography>
        <HelpImg src={howToArchiveImg1} />
        <Typography>
          Then, click on archive in the drawer.
        </Typography>
      </>
    )
  },
  {
    title: 'How to unarchive image',
    content: (
      <>
        <Typography>
          Go to sensor page, then click on the top right archive folder button
        </Typography>
        <HelpImg src={howToUnarchiveImg1} />
        <Typography>
          Then, long press on the image you want to unarchive.
        </Typography>
        <HelpImg src={howToUnarchiveImg2} />
        <Typography>
          Then, click on the unarchive button in drawer.
        </Typography>
      </>
    )
  },
]
