import { sensorLocationCategoryNameMap } from './sensorLocationCategoryNameMap'
import { sensorTypes } from '../../constants'

export const getLocationCategories = (sensorType) => {
  if (!sensorType) {
    return Object.keys(sensorLocationCategoryNameMap)
  }
  const locationCategoryList = []
  for (const locationCategory in sensorLocationCategoryNameMap) {
    if (sensorLocationCategoryNameMap[locationCategory].sensorType.includes(sensorType)) {
      locationCategoryList.push(locationCategory)
    }
  }
  return locationCategoryList
}

export const getLocationNamesByCategory = (locationCategory) => {
  if (!sensorLocationCategoryNameMap[locationCategory]) {
    return []
  }
  return sensorLocationCategoryNameMap[locationCategory].locationNames
}

export const getSensorNamePrefixByCategory = (locationCategory, sensorType) => {
  if (!sensorLocationCategoryNameMap[locationCategory]) {
    return []
  }
  if (sensorType === sensorTypes.DOOR) {
    const resultSensorNames = sensorLocationCategoryNameMap[locationCategory].sensorNames.map(sensorName => {
      return `${sensorName} ${sensorType}`
    })
    return resultSensorNames
  }
  return sensorLocationCategoryNameMap[locationCategory].sensorNames
}
