import {
  Divider,
  List
} from '@mui/material'
import React, { memo } from 'react'
import LocationSkeleton from './LocationSkeleton'
import SensorSkeleton from './SensorSkeleton'

function LocationSensorSkeleton () {
  const fragment = []
  for (let i = 0; i < 2; i++) {
    fragment.push(<LocationSkeleton />)
    fragment.push(<Divider />)
    const sensorsFrag = []
    for (let j = 0; j < 2; j++) {
      sensorsFrag.push(<SensorSkeleton />)
    }
    fragment.push(<List>{sensorsFrag}</List>)
  }
  return fragment
}

export default memo(LocationSensorSkeleton)
