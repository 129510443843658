import { appNavbarWrap } from '../appNavbarWrap'
import React, { memo } from 'react'
import { Toolbar, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import NavBackButton from '../../components/NavBackButton'
import AddSensorNavbarSubmitButton from './AddSensorNavbarSubmitButton'

export const addSensorNavbarWrap = (Component) => {
  return appNavbarWrap(Component, memo(
    function Wrapped () {
      const { storeId } = useParams()
      return (
        <Toolbar style={{
          justifyContent: 'space-between'
        }}>
          <NavBackButton/>
          <Typography style={{
            marginTop: '2px',
            marginLeft: '10px'
          }}>
            New Sensor for # {storeId}
          </Typography>
          <AddSensorNavbarSubmitButton />
        </Toolbar>
      )
    }
  ))
}
