import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import {Box, List, ListSubheader, Typography, useTheme} from '@mui/material'
import StoreButton from './StoreButton'
import ConditionalRenderByPermission from '../../../components/ConditionalRenderByPermission'
import HelpModal from '../../../components/HelpModal'
import { useLoading } from '../../../hooks/useLoading'
import StoreButtonsSkeleton from './StoreButtonsSkeleton'
import AppListSubheaderSkeleton from '../../../components/AppListSubheaderSkeleton'
import StoreListScrollControl from './StoreListScrollControl'

function StoreButtonList () {
  const allStores = useSelector(state => state.allStores.data)
  const searchText = useSelector(state => state.storeSearch.text.val)
  const theme = useTheme()
  const storesLoading = useLoading('gettingStores')
  const filteredStores = []
  let newStoreListStr = ''
  let numStoresNeedHelp = 0
  for (const storeId in allStores) {
    const store = allStores[storeId]
    if (store.numOfSensorDown !== 0) {
      numStoresNeedHelp += 1
    }
    if (
      String(store.storeId).includes(searchText) ||
      String(store.address).toLowerCase().includes(searchText.toLowerCase()) ||
      String(store.city).toLowerCase().includes(searchText.toLowerCase())
    ) {
      newStoreListStr += String(store.storeId)
      if (store.numOfSensorDown !== 0) {
        filteredStores.unshift(
          <ConditionalRenderByPermission key={store.id} permissionType={'viewStore'} keyword={store.storeId}>
            <StoreButton storeId={storeId}/>
          </ConditionalRenderByPermission>
        )
      } else {
        filteredStores.push(
          <ConditionalRenderByPermission key={store.id} permissionType={'viewStore'} keyword={store.storeId}>
            <StoreButton storeId={storeId}/>
          </ConditionalRenderByPermission>
        )
      }
    }
  }
  return (
    <>
      <StoreListScrollControl storeListStr={newStoreListStr} />
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper'
        }}
        aria-labelledby='store-list'
        subheader={
          <ListSubheader
            id='store-list'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {storesLoading
              ? (
                <AppListSubheaderSkeleton />
                )
              : (
                <Box>
                  {`${filteredStores.length} stores found`}
                  {numStoresNeedHelp !== 0 && (
                    <Typography
                      component={'span'}
                      style={{
                        fontSize: 'inherit',
                        color: theme.palette.secondary.main,
                      }}
                    >
                      {` - ${numStoresNeedHelp} stores need help`}
                    </Typography>
                  )}
                </Box>
                )}
            <HelpModal />
          </ListSubheader>
        }
      >
        {storesLoading
          ? (
            <StoreButtonsSkeleton />
            )
          : filteredStores
        }
      </List>
    </>
  )
}

export default memo(StoreButtonList)
