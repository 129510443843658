import { createSlice } from '@reduxjs/toolkit';

const initState = {
    loadingItems: []
}

const loadingSlice = createSlice({
    name:'loadings',
    initialState: initState,
    reducers: {
        addRunOpsLoadingItem: (state, action) => { //ADD_RUNOPS_LOADING_ITEM
          state.loadingItems = [...state.loadingItems, action.payload]
        },
        removeRunOpsLoadingItem: (state, action) => { //REMOVE_RUNOPS_LOADING_ITEM
          state.loadingItems = state.loadingItems.filter(item => item !== action.payload)
        }
    },
    extraReducers: (builder) => {
    }
})

export const { addRunOpsLoadingItem, removeRunOpsLoadingItem } = loadingSlice.actions;
export default loadingSlice.reducer;