import { getUserInfo } from "../../reducers/authSlice";
import { convertKeysToLowerCase } from "../convertKeysToLowerCase";

export async function getUserDetails(accessToken, dispatch) {
  const result = new Promise((resolve, reject) => {
    dispatch(
      getUserInfo({
        token: accessToken,
        cb: async (res) => {
          if (res && res.sub) {
            const result = convertKeysToLowerCase(res);
            resolve({
              ...result,
              firstName: result.firstname,
              lastName: result.lastname,
            });
          }
        },
      })
    );
  });
  let userDetails = await result;
  return userDetails;
}

export async function getUserProfile_graphService(
  accessToken,
  refreshToken,
  dispatch,
  cb
) {
  try {
    if (accessToken) {
      // Get the user's profile from open id user info endpoint
      let user = await getUserDetails(accessToken, dispatch);

      cb({
        isAuthenticated: true,
        user,
        error: null,
        token: accessToken,
        refreshToken: refreshToken,
      });
    }
  } catch (e) {
    cb({
      isAuthenticated: false,
      user: {},
      error: e,
    });
  }
}

// export function logout_graphService(cb) {
//   userAgentApplication.logout();
//   cb({
//     isAuthenticated: false,
//     user: {},
//     error: "",
//   })
//   sessionStorage.clear();
// }
