import {getErrorAttributesByAxiosError} from "./getErrorAttributesByAxiosError";

/**
 * Report an error to newrelic
 * Example:
 *     const err = new Error('test')
 *     newRelicNoticeError(err)
 * @param err Any kind of error
 */
export function newRelicNoticeError(err, sessionId, email) {
  const presetErrorAttr = {
    userEmail: email,
    sessionId: sessionId,
  }
  let errorAttr = {}

  switch (err.name) {
    case 'AxiosError': {
      errorAttr = getErrorAttributesByAxiosError(err)
      break
    }
    default:
      break
  }
  window.newrelic.noticeError(err, {
    ...presetErrorAttr,
    ...errorAttr
  })
}