import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  deviceType: {
    val: '',
    required: true
  }
}

const addDeviceFormSlice = createSlice({
  name: 'addDevice',
  initialState,
  reducers: {
    updateAddDeviceFormFieldAction: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    cleanAddDeviceFormAction: () => {
      return initialState
    },
    cleanAddDeviceFormThenPredefineFieldsAction: (state, action) => {
      return {
        ...initialState,
        ...action.payload
      }
    }
  }
})

export const { updateAddDeviceFormFieldAction, cleanAddDeviceFormAction, cleanAddDeviceFormThenPredefineFieldsAction } = addDeviceFormSlice.actions

export const addDeviceFormReducer = addDeviceFormSlice.reducer
