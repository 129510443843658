import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { storeSelector } from '../../appSelectors/storeSelector'

export function useStore () {
  const { storeId } = useParams()
  const store = useSelector(storeSelector(storeId))
  if (!store) {
    window.location.href = '/'
  }
  return store
}
