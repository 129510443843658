import React, { memo, useState } from 'react'
import { Box, CircularProgress, Modal } from '@mui/material'
import PropTypes from 'prop-types'

SensorImageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired
}

function SensorImageModal ({ open, setOpen, imageUrl }) {
  const [loading, setLoading] = useState(true)
  const handleClose = () => {
    setOpen(false)
    setLoading(true)
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '90%',
        left: '5%',
        outline: 'none'
      }}>
        {loading && (
          <Box style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <CircularProgress style={{
              color: '#fff'
            }} />
          </Box>
        )}
        <img
          alt={''}
          src={imageUrl}
          style={{
            width: '100%',
            display: loading ? 'none' : 'block'
          }}
          onLoad={() => setLoading(false)}
        />
      </Box>
    </Modal>
  )
}

export default memo(SensorImageModal)
