import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSensorThunk } from '../../../../../thunks/deleteSensorThunk'
import AppDrawerList from '../../../../../components/AppDrawerList'
import ConditionalRenderByPermission from '../../../../../components/ConditionalRenderByPermission'
import { sensorSelector } from '../../../../../appSelectors/sensorSelector'

SensorInStoreItemDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  sensorId: PropTypes.string.isRequired
}

function SensorInStoreItemDrawer ({ sensorId, open, setOpen }) {
  const dispatch = useDispatch()
  const sensor = useSelector(sensorSelector(sensorId))

  const deleteSensor = () => {
    dispatch(deleteSensorThunk({ sensorId }))
  }

  return (
    <AppDrawerList
      setOpen={setOpen}
      drawerActionsList={(
        <>
          <ConditionalRenderByPermission permissionType={'deleteSensor'}>
            <ListItemButton onClick={deleteSensor}>
              <ListItemIcon>
                <DeleteIcon/>
              </ListItemIcon>
              <ListItemText>
                Delete Sensor
              </ListItemText>
            </ListItemButton>
          </ConditionalRenderByPermission>
        </>
      )}
      drawerTitle={sensor.sensorName}
      open={open}
      anchor={'bottom'}
    />
  )
}

export default memo(SensorInStoreItemDrawer)
