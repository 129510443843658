import { combineReducers } from "redux"
import { configureStore } from '@reduxjs/toolkit';
import globalSlice from "./globalSlice.js";
import authSlice from "./authSlice.js"
import iotDashboardReducer from "./iotDashboardReducer";
import runOpsReducer from "./runOpsReducer";
import {insightReducer} from "./insightSlice";

const initReducer = combineReducers({
    authData: authSlice,
    globalData: globalSlice,
    iotDashboardData: iotDashboardReducer,
    runOpsData: runOpsReducer,
  insightData: insightReducer
})

const rootReducer = configureStore({
    reducer: initReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
         immutableCheck: false,
         serializableCheck: false,
    })
})


export default rootReducer;