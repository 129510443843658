import React, {memo, useEffect} from 'react'
import { AppBar, Box } from '@mui/material'
import AppNavbarFlexibleWrap from './AppNavbarFlexibleWrap'

export const appNavbarWrap = (Component, AppToolbar, AppBottomBar) => {
  return memo(function Wrapped () {

    useEffect(() => {
      window.scrollTo(0,0)
    }, [])

    return (
      <>
        <Box>
          <AppBar position='fixed'>
            <AppToolbar/>
          </AppBar>
        </Box>
        <AppNavbarFlexibleWrap>
          <Component />
          <Box style={{
            height: '200px'
          }}></Box>
        </AppNavbarFlexibleWrap>
        {AppBottomBar && (
          <Box style={{
            position: 'fixed',
            bottom: '3%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <AppBottomBar/>
          </Box>
        )}
      </>
    )
  })
}
