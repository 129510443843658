import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import SignInPage from './pages/SignInPage'
import IndexPage from './pages/IndexPage'
import Loading from './components/Loading'
import HomePage from './pages/HomePage'
import AppSnackbar from './components/AppSnackbar'
import AppNavigation from './components/AppNavigation'
import StorePage from './pages/StorePage'
import SensorPage from './pages/SensorPage'
import AddSensorPage from './pages/AddSensorPage'
import AddStorePage from './pages/AddStorePage'
import ArchivedImagesPage from './pages/ArchivedImagesPage'
import NewrelicRouteTracer from './components/NewrelicRouteTracer'
import ReactNativeConnector from './components/ReactNativeConnector'
import EditSensorPage from './pages/EditSensorPage'
import AppWindowResizeListener from './components/AppWindowResizeListener'
import AppDialogConfirm from "./components/AppDialogConfirm";
import DevicesPage from "./pages/DevicesPage";
import DeviceImagePage from "./pages/DeviceImagePage";
import AddDevicePage from "./pages/AddDevicePage";
import SsoLoginHandle from "./pages/SsoLoginHandle";

function App () {
  return (
    <>
      <AppWindowResizeListener />
      <AppDialogConfirm />
      <Loading />
      <AppSnackbar />
      <BrowserRouter>
        <AppNavigation />
        <NewrelicRouteTracer />
        <ReactNativeConnector />
        <Routes>
          <Route path={'/snapshot/login'} element={<SignInPage />} />
          <Route path={'/snapshot/'} element={<IndexPage />} />
          <Route path={'/snapshot/home'} element={<HomePage />} />
          <Route path={'/snapshot/home/:storeId'} element={<StorePage />} />
          <Route path={'/snapshot/home/:storeId/new-sensor'} element={<AddSensorPage />} />
          <Route path={'/snapshot/home/:storeId/devices'} element={<DevicesPage />} />
          <Route path={'/snapshot/home/:storeId/add-device'} element={<AddDevicePage />} />
          <Route path={'/snapshot/home/:storeId/device/:deviceId'} element={<DeviceImagePage />} />
          <Route path={'/snapshot/home/:storeId/:sensorId'} element={<SensorPage />} />
          <Route path={'/snapshot/home/:storeId/:sensorId/edit'} element={<EditSensorPage />} />
          <Route path={'/snapshot/home/add-store'} element={<AddStorePage />} />
          <Route path={'/snapshot/home/:storeId/:sensorId/archived'} element={<ArchivedImagesPage />} />
          <Route path={'/snapshot/sso-login-handle'} element={<SsoLoginHandle />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App