import { Box } from '@mui/material'
import React, { memo } from 'react'
import { addStoreNavbarWrap } from '../../wraps/addStoreNavbarWrap'
import FormHeader from '../../components/FormHeader'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory'
import AppTextField from '../../components/AppTextField'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { updateNewStoreFieldAction } from '../../slices/newStore'
import {useNavigate, useParams} from 'react-router-dom'
import RedirectIfForbidden from '../../components/RedirectIfForbidden'
import AppSubmitButton from '../../components/AppSubmitButton'
import { loadStoreDataWrap } from '../../wraps/loadStoreDataWrap'
import { newStoreThunk } from '../../thunks/newStoreThunk'
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import DeviceTypeSelect from "./DeviceTypeSelect";
import DoneIcon from "@mui/icons-material/Done";
import {newDeviceThunk} from "../../thunks/newDeviceThunk";

function AddDevicePage () {
  const {storeId} = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <Box style={{
      padding: '0 10px'
    }}>
      <RedirectIfForbidden permissionType={'createStore'} />
      <FormHeader>
        <DeviceHubIcon
          style={{
            fontSize: '50px'
          }}
          color={'primary'}
        />
      </FormHeader>
      <Box style={{
        marginTop: '10px',
        gap: '5px'
      }}>
        <DeviceTypeSelect />
        <AppSubmitButton
          fieldsSelector={state => state.addDeviceForm}
          variant={'contained'}
          size={'large'}
          fullWidth
          onClick={() => {
            dispatch(newDeviceThunk({
              navigate, storeId
            }))
          }}
        >
          <DoneIcon style={{
            fontSize: '32px'
          }} />
        </AppSubmitButton>
      </Box>
    </Box>
  )
}

export default loadStoreDataWrap(addStoreNavbarWrap(memo(AddDevicePage)))
