
/**
 * This function should be called when user interact with frontend, such as :
 * * Click on button
 * * Input something
 * * Goto a page
 * * Update a filter
 * @param eventName Name of that event, such as "Go to XXX"
 * @param payload Payload data, optional, the data you think it is important. Should be an object
 */
export function newRelicCreatePageAction(eventName, payload) {
  window.newrelic?.addPageAction(eventName, payload)
}