import { createAsyncThunk } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { addLoadingItemAction, dropLoadingItemAction } from '../slices/loading'
import { uploadImgToBlob } from '../utils/uploadImgToBlob'
import imageCompression from 'browser-image-compression'
import { convertSpaceToUnderscores } from '../utils/convertSpaceToUnderscores'
import { getImageExt } from '../utils/getImageExt'
import { postSensorImageToBackendThunk } from './postSensorImageToBackendThunk'
import {
  addLocalImageToSensorAction,
  convertLocalImageInSensorToUploadedImageAction,
  updateLocalImageInSensorUploadProgressAction
} from "../slices/sensors";

export const addSensorImageThunk = createAsyncThunk(
  'stores/addSensorImage',
  async ({ sensorId, image }, { dispatch, getState }) => {
    const currentState = getState()
    const tempSensorImageId = String(uuidv4())
    dispatch(addLocalImageToSensorAction({
      sensorId,
      sensorImageId: tempSensorImageId,
      sensorImageUrl: URL.createObjectURL(image)
    }))
    const storeIdForSensors = currentState.sensors.storeIdOfSensors
    const sensor = currentState.sensors.data[sensorId]
    const sensorImageBlobKey = `${storeIdForSensors}/${convertSpaceToUnderscores(sensor.locationName)}/${sensorId}/${uuidv4()}/${convertSpaceToUnderscores(sensor.sensorName)}.${getImageExt(image)}`
    dispatch(addLoadingItemAction('uploadImg'))
    try {
      const compressedImage = await imageCompression(image, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        onProgress: progress => {
          dispatch(updateLocalImageInSensorUploadProgressAction({
            sensorId,
            sensorImageId: tempSensorImageId,
            uploadProgress: Math.ceil(progress / 2)
          }))
        }
      })
      await uploadImgToBlob(sensorImageBlobKey, compressedImage, percentage => {
        dispatch(updateLocalImageInSensorUploadProgressAction({
          sensorId,
          sensorImageId: tempSensorImageId,
          uploadProgress: Math.ceil(50 + percentage * 0.4)
        }))
      })
      const newSensorImage = await dispatch(postSensorImageToBackendThunk({
        sensorId,
        sensorImageBlobKey,
        onUploadProgress: percentage => {
          dispatch(updateLocalImageInSensorUploadProgressAction({
            sensorId,
            sensorImageId: tempSensorImageId,
            uploadProgress: Math.ceil(90 + percentage * 0.1)
          }))
        }
      })).unwrap()
      delete newSensorImage.sensor
      dispatch(convertLocalImageInSensorToUploadedImageAction({
        sensorId,
        oldSensorImageId: tempSensorImageId,
        newSensorImage
      }))
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(dropLoadingItemAction('uploadImg'))
    }
  }
)
