import React, { memo } from 'react'
import LoadingBar from './LoadingBar'
import LoadingCircle from './LoadingCircle'

function Loading () {
  return (
    <>
      <LoadingBar />
      <LoadingCircle />
    </>
  )
}

export default memo(Loading)
