import { List } from '@mui/material'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import SensorInStoreItem from '../SensorInStoreItem'

SensorInStoreList.propTypes = {
  sensors: PropTypes.array.isRequired
}

function SensorInStoreList ({ sensors }) {
  return (
    <List>
      {sensors.map(sensor => {
        return (<SensorInStoreItem key={sensor.sensorId} sensorId={sensor.sensorId} />)
      })}
    </List>
  )
}

export default memo(SensorInStoreList)
