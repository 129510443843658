import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  text: {
    val: ''
  }
}

const helpSearch = createSlice({
  name: 'helpSearch',
  initialState,
  reducers: {
    helpSearchTextUpdateAction: (state, action) => {
      state.text = action.payload
    }
  }
})

export const helpSearchReducer = helpSearch.reducer

export const { helpSearchTextUpdateAction } = helpSearch.actions
