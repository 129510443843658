import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAPIThunk } from './fetchApiThunk'
import {revocatePingToken} from "../../../reducers/authSlice";
import {logoutThunk} from "./logoutThunk";

const {REACT_APP_ID, REACT_APP_CLIENT_SECRET, REACT_APP_PING_FEDERATE_ENDPOINT} = process.env

export const fetchPingFederateAPIThunk = createAsyncThunk(
  'util/fetchBackendAPI',
  async ({ method, url, params, data }, { getState, dispatch, rejectWithValue }) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        "Basic " + btoa(`${REACT_APP_ID}:${REACT_APP_CLIENT_SECRET}`),
    }
    let result
    try {
      result = await dispatch(fetchAPIThunk({
        config: {
          method,
          url,
          baseURL: REACT_APP_PING_FEDERATE_ENDPOINT,
          params,
          data,
          headers
        }
      })).unwrap()
    } catch (e) {
      return rejectWithValue(e)
    }
    return result
  }
)
