import { IconButton, InputAdornment, TextField } from '@mui/material'
import React, { memo, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ClearIcon from '@mui/icons-material/Clear'

AppTextField.propTypes = {
  fieldSelector: PropTypes.func.isRequired,
  updateAction: PropTypes.func.isRequired,
  validator: PropTypes.func,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  endAdornment: PropTypes.arrayOf(PropTypes.node),
  displayRequired: PropTypes.bool,
  variant: PropTypes.string
}

function AppTextField ({
  fieldSelector,
  updateAction,
  type = 'text',
  validator = () => null,
  label,
  endAdornment,
  autoFocus,
  displayRequired = true,
  variant = 'outlined'
}) {
  const dispatch = useDispatch()
  const inputRef = useRef()
  const currentValue = useSelector(fieldSelector)
  let helperText
  if (currentValue.val !== '') {
    helperText = validator(currentValue.val)
    if (!!helperText !== currentValue.err) {
      dispatch(updateAction({
        ...currentValue,
        val: currentValue.val,
        err: !!helperText
      }))
    }
  }
  const clearData = () => {
    dispatch(updateAction({
      ...currentValue,
      val: '',
      err: false,
      focus: true
    }))
  }
  useEffect(() => {
    if (currentValue.focus) {
      inputRef.current.focus()
    }
  }, [currentValue.focus])
  return <TextField
    onFocus={() => {
      dispatch(updateAction({
        ...currentValue,
        focus: true
      }))
    }}
    onBlur={() => {
      dispatch(updateAction({
        ...currentValue,
        focus: false
      }))
    }}
    autoCorrect={'off'}
    autoCapitalize={'off'}
    required={displayRequired && currentValue.required}
    type={type}
    inputRef={inputRef}
    label={label}
    autoFocus={autoFocus}
    value={currentValue.val}
    error={!!helperText}
    helperText={helperText}
    focused={currentValue.focus}
    variant={variant}
    onChange={(event) => {
      dispatch(updateAction({
        ...currentValue,
        val: event.target.value,
        err: !!helperText
      }))
    }}
    fullWidth
    InputProps={{
      endAdornment: (
        <InputAdornment position='end'>
          {currentValue.val !== '' && (
            <IconButton onClick={clearData}>
              <ClearIcon color={'error'}/>
            </IconButton>
          )}
          {endAdornment}
        </InputAdornment>
      )
    }}
  />
}

export default memo(AppTextField)
