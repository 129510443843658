import handleRequestException from "./handleRequestException";
import getTokenDispatch from "./getTokenDispatch";

async function continuousGetDataRequest(
  requestFunction,
  requestParam,
  dispatch,
  showApiError = false,
  navError = false,
  isLoginApi = false,
  isUserInfo = false,
  isTokenEndpoint = false,
  throwException = false
) {
  let restResult = null;
  let continueSendRequest = true;
  while (continueSendRequest) {
    let {sessionId, csrfToken, email} = await getTokenDispatch(dispatch);
    try {
      restResult = await requestFunction(sessionId, csrfToken, requestParam, isLoginApi, isUserInfo, isTokenEndpoint);
      continueSendRequest = false;
    } catch (e) {
      if (e.response) {
        continueSendRequest = await handleRequestException(
           e,
           dispatch,
           showApiError,
           navError,
           isUserInfo,
           sessionId, 
           email
        );
      } else {
        continueSendRequest = false;
      }
      if (throwException) {
        throw e
      }
    }
  }
  return restResult ? restResult?.data : (requestFunction === undefined ? [] : null);
}

export default continuousGetDataRequest;
