import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

AppSelect.propTypes = {
  fieldSelector: PropTypes.func.isRequired,
  updateAction: PropTypes.func.isRequired,
  availableOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired
}

function AppSelect ({ fieldSelector, updateAction, availableOptions, label }) {
  const dispatch = useDispatch()
  const currentValue = useSelector(fieldSelector)
  useEffect(() => {
    if (availableOptions && currentValue.val !== '' && !availableOptions.includes(currentValue.val)) {
      dispatch(updateAction({
        ...currentValue,
        val: ''
      }))
    }
  }, [availableOptions])
  return (
    <FormControl
      disabled={currentValue.disabled}
      fullWidth
    >
      <InputLabel
        id={`${label}-select-label`}
        sx={{
          '&.Mui-disabled': {
            color: '#cbcbcb'
          }
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        value={currentValue.val}
        label={label}
        onChange={(event) => {
          dispatch(updateAction({
            ...currentValue,
            val: event.target.value
          }))
        }}
      >
        {availableOptions && availableOptions.map(option => (
          <MenuItem key={option} value={option}>{option}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default memo(AppSelect)
