import {loadStoreDataWrap} from "../../wraps/loadStoreDataWrap";
import {loadDeviceDataWrap} from "../../wraps/loadDeviceDataWrap";
import {storeNavbarWrap} from "../../wraps/storeNavbarWrap";
import React, {memo} from "react";
import {Box, ImageList, ImageListItem, ListSubheader} from "@mui/material";
import HelpModal from "../../components/HelpModal";
import ConditionalRenderByPermission from "../../components/ConditionalRenderByPermission";
import NewImgItemButton from "../SensorPage/SensorImageList/NewImgItemButton";
import SensorImageListItem from "../../components/SensorImageListItem";
import {useDevice} from "../../hooks/useDevice";
import DeviceImageListItem from "../../components/DeviceImageListItem";
import NewDeviceImageButton from "./NewDeviceImageButton";
import {deviceNavbarWrap} from "../../wraps/deviceNavbarWrap";

function DeviceImagePage() {
  const device = useDevice()
  return (
    <ImageList
      cols={2}
      gap={8}
    >
      <ImageListItem
        key={'Subheader'}
        cols={2}
      >
        <ListSubheader
          id='store-list'
          style={{
            display: 'flex',
            position: 'static',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            Upload device image here
          </Box>
          <HelpModal />
        </ListSubheader>
      </ImageListItem>
      <NewDeviceImageButton />
      {/*<ConditionalRenderByPermission permissionType={'uploadSensorImage'}>*/}
      {/*  <NewImgItemButton />*/}
      {/*</ConditionalRenderByPermission>*/}
      {device.deviceImages.map(deviceImage => {
        if (!deviceImage.isArchived) {
          return (
            <DeviceImageListItem key={deviceImage.deviceImageId} deviceImage={deviceImage} />
          )
        } else {
          return <></>
        }
      })}
    </ImageList>
  )
}

export default loadStoreDataWrap(loadDeviceDataWrap(deviceNavbarWrap(memo(DeviceImagePage))))