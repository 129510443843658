import {useSelector} from "react-redux";
import {updateSensorInfoFormFieldAction} from "../../../slices/sensorInfoForm";
import {Box} from "@mui/material";
import AppSelect from "../../../components/AppSelect";
import {getLocationCategories} from "../../../utils/sensorDetail";
import React from "react";
import {updateAddDeviceFormFieldAction} from "../../../slices/addDeviceForm";

function DeviceTypeSelect() {
  const currentSensorType = useSelector(state => state.addDeviceForm.deviceType)
  const updateAction = (val) => updateAddDeviceFormFieldAction({
    deviceType: val
  })
  return (
    <Box style={{
      margin: '10px 0'
    }}>
      <AppSelect
        updateAction={updateAction}
        fieldSelector={state => state.addDeviceForm.deviceType}
        label={'Device Type'}
        availableOptions={[
          'Lorawan',
          'Franke'
        ]}
      />
    </Box>
  )
}

export default DeviceTypeSelect