import axios from "axios";
import { getSessionStorageData } from "../utils";
import {newRelicCreatePageAction} from "../utlities/newrelic/newRelicCreatePageAction";
import {jwtDecode} from "jwt-decode";
import {generateUUID} from "@microsoft/microsoft-graph-client/lib/es/middleware/MiddlewareUtil";
const {
  REACT_APP_API_URL,
  REACT_APP_API_URL_BASE,
  REACT_APP_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_PING_FEDERATE_ENDPOINT,
  REACT_APP_7CMS_VERSION,
} = process.env;

const RestClient = {
  doRestAPIGetPromise: async (
    sessionId,
    csrfToken,
    url,
    method,
    request,
    isUserInfo,
    params,
    isTokenEndpoint,
    isLoginApi
  ) => {
    const requestInfo = {
      method: method,
    };

    requestInfo.headers = {
      "Content-Type": "application/json",
    };

    if (isTokenEndpoint) {
      //token ping federate api
      requestInfo.headers = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic " + btoa(`${REACT_APP_ID}:${REACT_APP_CLIENT_SECRET}`),
      };
    } else if (isLoginApi) {
      //login api
      requestInfo.headers = {
        Authorization: getSessionStorageData("pingFederateToken"),
        ...requestInfo.headers,
      };
    } else if (isUserInfo) {
      //user info ping federate api
      requestInfo.headers = {
        Authorization: "Bearer " + getSessionStorageData("pingFederateToken"),
        ...requestInfo.headers,
      };
    } else if (sessionId && csrfToken) {
      requestInfo.headers = {
        "x-auth-token": sessionId,
        "x-csrf-token": csrfToken,
        ...requestInfo.headers,
      };
    }

    if (request !== null) requestInfo.data = JSON.stringify(request);
    if (params !== null) requestInfo.params = params;
    const newRelicPayload = {
      url,
      method,
      req: requestInfo.data,
      params,
      requestId: generateUUID()
    }
    const token = getSessionStorageData("pingFederateToken")
    if (token) {
      newRelicPayload.token = token
      newRelicPayload.userEmail = jwtDecode(token).email
    }
    newRelicCreatePageAction('AJAX start', newRelicPayload)
    const restResult = await axios(url, requestInfo);
    newRelicCreatePageAction('AJAX response', {
      requestId: newRelicPayload.requestId,
      response: JSON.stringify(restResult)
    })
    return restResult;
  },
  makeHealthCheck(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL_BASE}/health`,
      "GET",
      request,
      null,
      null
    );
  },
  // AUTHENTICATION
  tokenEndpointLogin(
    sessionId,
    csrfToken,
    params,
    isLoginApi,
    isUserInfo,
    isTokenEndpoint
  ) {
    //params, false, false, true
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_PING_FEDERATE_ENDPOINT}/as/token.oauth2`,
      "POST",
      {},
      isUserInfo,
      params,
      isTokenEndpoint,
      isLoginApi
    );
  },
  userInfoEndpoint(
    sessionId,
    csrfToken,
    params,
    isLoginApi,
    isUserInfo,
    isTokenEndpoint
  ) {
    //params, false, true, false
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_PING_FEDERATE_ENDPOINT}/idp/userinfo.openid`,
      "GET",
      {},
      isUserInfo,
      params,
      isTokenEndpoint,
      isLoginApi
    );
  },
  revocateTokenEndpointLogin(
    sessionId,
    csrfToken,
    params,
    isLoginApi,
    isUserInfo,
    isTokenEndpoint
  ) {
    //params, false, false, true
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_PING_FEDERATE_ENDPOINT}/as/revoke_token.oauth2`,
      "POST",
      {},
      isUserInfo,
      params,
      isTokenEndpoint,
      isLoginApi
    );
  },
  azureLogin(
    sessionId,
    csrfToken,
    request,
    isLoginApi,
    token,
    isTokenEndpoint
  ) {
    //request, true, null, false
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/auth/login`,
      "POST",
      request,
      token,
      null,
      isTokenEndpoint,
      isLoginApi
    );
  },
  getVersionConfig(sessionId, csrfToken, params) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/config/iot-config`,
      "GET",
      {},
      null,
      params
    );
  },
  getConfigDetails(sessionId, csrfToken, params) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/config/get-iot-config/${REACT_APP_7CMS_VERSION}`,
      "GET",
      {},
      null,
      params
    );
  },
  setConfigData(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/config/save/iot-config`,
      "POST",
      request,
      null,
      null
    );
  },
  getLookupData(sessionId, csrfToken, params) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/config/lookup`,
      "GET",
      {},
      null,
      params
    );
  },
  getRoleIdDetails(sessionId, csrfToken, params) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/auth/roles`,
      "GET",
      {},
      null,
      params
    );
  },
  restartPowerCycle(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/devices/ACSwitch`,
      "POST",
      request,
      null,
      null
    );
  },
  setUserPersona(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/config/user-persona`,
      "POST",
      request,
      null,
      null
    );
  },
  createTicketForNotification(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/ticket/create`,
      "POST",
      request,
      null
    );
  },
  dismissTicketForNotification(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/alerts/alert-dismiss`,
      "POST",
      request,
      null
    );
  },
  setFeedback(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/feedback`,
      "POST",
      request,
      null,
      null
    );
  },
  setTicketStatus(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/ticket/status`,
      "POST",
      request,
      null,
      null
    );
  },
  getDynamicSensorData(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/home/dynamic-info`,
      "POST",
      request,
      null,
      null
    );
  },
  getPowerCycleStatus(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/devices/getACOutputState`,
      "POST",
      request,
      null,
      null
    );
  },
  getDynamicApplianceData(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/franke/getDynamicData`,
      "POST",
      request,
      null,
      null
    );
  },
  getErrorCodesData(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/franke/get-error-codes`,
      "POST",
      request,
      null,
      null
    );
  },
  getDynamicNotificationData(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/notifications/dynamic-notifications-list`,
      "POST",
      request,
      null,
      null
    );
  },
  getStoreTableData(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/home/get-store-info`,
      "POST",
      request,
      null,
      null
    );
  },
  getApplianceConnectionStatusData(sessionId, csrfToken, params) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/franke/connection-status-count`,
      "GET",
      {},
      null,
      params
    );
  },
  //RunOps
  getConnectivityData(sessionId, csrfToken, params) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/analytics`,
      "GET",
      {},
      null,
      params
    );
  },
  createDevice(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/create/device`,
      "POST",
      request,
      null,
      null
    );
  },
  createSensor(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/create/sensor`,
      "POST",
      request,
      null,
      null
    );
  },
  createGateway(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/create/gateway`,
      "POST",
      request,
      null,
      null
    );
  },
  updateDevice(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/update/device`,
      "PUT",
      request,
      null,
      null
    );
  },
  updateSensor(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/update/sensor`,
      "PUT",
      request,
      null,
      null
    );
  },
  updateGateway(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/update/gateway`,
      "PUT",
      request,
      null,
      null
    );
  },
  deleteDevice(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/delete/device`,
      "DELETE",
      request,
      null,
      null
    );
  },
  deleteSensor(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/delete/sensor`,
      "DELETE",
      request,
      null,
      null
    );
  },
  deleteGateway(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/delete/gateway`,
      "DELETE",
      request,
      null,
      null
    );
  },
  getUserManagementData(sessionId, csrfToken, params) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/user-management/user`,
      "GET",
      {},
      null,
      params
    );
  },
  createUser(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/user-management/user`,
      "POST",
      request,
      null,
      null
    );
  },
  updateUser(sessionId, csrfToken, request) {
    const url = `${REACT_APP_API_URL}/runops/user-management/user/${request?.userId}`;
    delete request.userId;
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      url,
      "PUT",
      request,
      null,
      null
    );
  },
  deleteUser(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/user-management/user/${request?.userId}`,
      "DELETE",
      request,
      null,
      null
    );
  },
  getQueueData(sessionId, csrfToken, params) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/queue/access-request`,
      "GET",
      null,
      null,
      params
    );
  },
  updateAccessRequest(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/queue/access-request`,
      "PUT",
      request,
      null,
      null
    );
  },
  addAccessRequest(sessionId, csrfToken, request) {
    return RestClient.doRestAPIGetPromise(
      sessionId,
      csrfToken,
      `${REACT_APP_API_URL}/runops/queue/access-request`,
      "POST",
      request,
      null,
      null
    );
  },
};

export default RestClient;
