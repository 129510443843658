import AppSelect from '../AppSelect'
import { updateSensorInfoFormFieldAction } from '../../slices/sensorInfoForm'
import React, { memo } from 'react'
import { Box } from '@mui/material'
import { sensorTypes } from '../../constants'

function SensorTypeSelect () {
  const updateAction = (val) => updateSensorInfoFormFieldAction({
    sensorType: val
  })
  return (
    <Box style={{
      margin: '10px 0'
    }}>
      <AppSelect
        updateAction={updateAction}
        fieldSelector={state => state.sensorInfoForm.sensorType}
        label={'Sensor Type'}
        availableOptions={Object.values(sensorTypes)}
      />
    </Box>
  )
}

export default memo(SensorTypeSelect)
