import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useSensor } from '../../../hooks/useSensor'
import EditIcon from '@mui/icons-material/Edit'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ConditionalRenderByPermission from '../../../components/ConditionalRenderByPermission'
import { usePermission } from '../../../hooks/usePermission'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { cleanSensorInfoFormThenPredefineFieldsAction } from '../../../slices/sensorInfoForm'
import WifiTetheringErrorIcon from '@mui/icons-material/WifiTetheringError'
import WifiTetheringIcon from '@mui/icons-material/WifiTethering'
function SensorInfoCard () {
  const sensor = useSensor()
  const { storeId, sensorId } = useParams()
  const hasAccess = usePermission('editSensor')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const SensorStatusIcon = sensor.isActive ? WifiTetheringIcon : WifiTetheringErrorIcon
  return (
    <Box
      onClick={() => {
        if (hasAccess) {
          dispatch(cleanSensorInfoFormThenPredefineFieldsAction({
            sensorId: {
              val: sensor.sensorId,
              err: false,
              required: true
            },
            sensorType: {
              val: sensor.sensorType,
              required: true
            },
            locationCategory: {
              val: sensor.locationCategory,
              required: true
            },
            locationName: {
              val: sensor.locationName,
              required: true
            },
            sensorName: {
              val: sensor.sensorName,
              required: true
            }
          }))
          navigate(`/snapshot/home/${storeId}/${sensorId}/edit`)
        }
      }}
      style={{
        backgroundColor: sensor.isActive ? theme.palette.primary.main : theme.palette.secondary.main,
        padding: '15px 25px',
        borderRadius: '10px',
        color: '#fff',
        marginTop: '10px',
        display: 'flex',
        boxShadow: '2px 2px 6px #aaa',
        justifyContent: 'space-between'
      }}
    >
      <Box>
        <Box style={{
          display: 'flex',
          gap: '7px',
          marginLeft: '-3px'
        }}>
          <SensorStatusIcon style={{
            fontSize: '16px',
            marginTop: '5px'
          }} />
          <Typography style={{
            fontSize: '18px'
          }}>
            {sensor.sensorName}
          </Typography>
        </Box>
        <Box style={{
          display: 'flex',
          gap: '8px',
          marginTop: '3px',
          marginLeft: '-4px'
        }}>
          <LocationOnIcon style={{
            fontSize: '16px',
            marginTop: '1px'
          }}/>
          <Typography style={{
            fontSize: '14px'
          }}>
            {sensor.locationCategory} • {sensor.locationName}
          </Typography>
        </Box>
      </Box>
      <Box>
        <ConditionalRenderByPermission permissionType={'editSensor'}>
          <EditIcon style={{
            marginTop: '10px',
            fontSize: '25px'
          }} />
        </ConditionalRenderByPermission>
      </Box>
    </Box>
  )
}

export default SensorInfoCard
