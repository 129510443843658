import {Box, Button} from '@mui/material'
import React, { memo } from 'react'
import { storeNavbarWrap } from '../../wraps/storeNavbarWrap'
import {useNavigate, useParams} from 'react-router-dom'
import RedirectIfForbidden from '../../components/RedirectIfForbidden'
import { useSelector } from 'react-redux'
import AppError from '../../components/AppError'
import LocationSensorInStoreList from './LocationSensorInStoreList'
import { loadStoreDataWrap } from '../../wraps/loadStoreDataWrap'
import { loadSensorDataWrap } from '../../wraps/loadSensorDataWrap'
import DeviceSensorTab from "../../components/DeviceSensorTab";

function StorePage () {
  const { storeId } = useParams()
  const navigate = useNavigate()
  const sensors = useSelector(state => state.sensors)
  return sensors.err
    ? (
      <Box>
        <AppError/>
      </Box>
      )
    : (
      <Box>
        <RedirectIfForbidden permissionType={'viewStore'} keyword={storeId}/>
        <DeviceSensorTab />
        <LocationSensorInStoreList />
      </Box>
      )
}

export default loadStoreDataWrap(loadSensorDataWrap(storeNavbarWrap(memo(StorePage))))
