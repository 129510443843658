import { Box, IconButton } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import React, { memo, useState } from 'react'
import UserDrawerLogout from './UserDrawerLogout'
import AppDrawerList from '../AppDrawerList'
import { useSelector } from 'react-redux'

function UserDrawer () {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const username = useSelector(state => state.global.username)
  return (
    <>
      <Box style={{
        marginLeft: 'auto'
      }}>
        <IconButton
          id="user-button"
          aria-controls={drawerOpen ? 'user-dropdown-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={drawerOpen ? 'true' : undefined}
          onClick={() => setDrawerOpen(true)}
          style={{
            color: 'inherit'
          }}
        >
          <PersonIcon/>
        </IconButton>
        <AppDrawerList
          setOpen={setDrawerOpen}
          drawerActionsList={(
            <>
              <UserDrawerLogout/>
            </>
          )}
          open={drawerOpen}
          anchor={'bottom'}
          drawerTitle={`Hi ${username}`}
        />
      </Box>
    </>
  )
}

export default memo(UserDrawer)
