import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material'
import SensorTypeIcon from '../../../../components/SensorTypeIcon'
import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import TouchListeningBox from '../../../../components/TouchListeningBox'
import SensorInStoreItemDrawer from './SensorInStoreItemDrawer'
import { useSelector } from 'react-redux'
import { sensorSelector } from '../../../../appSelectors/sensorSelector'
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4'
import moment from 'moment'

SensorInStoreItem.propTypes = {
  sensorId: PropTypes.string.isRequired
}

function SensorInStoreItem ({ sensorId }) {
  const navigate = useNavigate()
  const location = useLocation()
  const sensor = useSelector(sensorSelector(sensorId))
  const theme = useTheme()
  const [drawerOpen, setDrawerOpen] = useState(false)
  return (
    <>
      <TouchListeningBox
        onTap={() => {
          navigate(`${location.pathname}/${sensorId}`)
        }}
        onTapHold={() => setDrawerOpen(true)}
      >
        <ListItemButton
          key={sensorId}
          style={{
            paddingLeft: '40px'
          }}
        >
          <ListItemIcon>
            <SensorTypeIcon
              sensorType={sensor.sensorType}
              color={sensor.isActive ? 'primary' : 'secondary'}
            />
          </ListItemIcon>
          <ListItemText primary={sensor.sensorName} secondary={(
            <Box>
              <Typography>
                {`# ${sensorId}`}
              </Typography>
              <Typography
                color={sensor.isActive ? theme.palette.primary.main : theme.palette.secondary.main}
                style={{
                  fontSize: '12px'
                }}
              >
                {sensor.lastSeenAt ? `last active ${moment(sensor.lastSeenAt).fromNow()}` : 'never reported'}
              </Typography>
            </Box>
          )} />
          {!sensor.isActive && (
            <ListItemSecondaryAction>
              <SignalWifiConnectedNoInternet4Icon color={'secondary'}/>
            </ListItemSecondaryAction>
          )}
        </ListItemButton>
      </TouchListeningBox>
      <SensorInStoreItemDrawer setOpen={setDrawerOpen} open={drawerOpen} sensorId={sensorId} />
    </>
  )
}

export default memo(SensorInStoreItem)
