import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import HideImageIcon from '@mui/icons-material/HideImage'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { archiveSensorImageThunk } from '../../../../thunks/archiveSensorImageThunk'
import {useSensor} from "../../../../hooks/useSensor";
SensorImageDrawerArchive.propTypes = {
  sensorImage: PropTypes.object.isRequired
}
function SensorImageDrawerArchive ({ sensorImage }) {
  const dispatch = useDispatch()
  const sensor = useSensor()
  const archiveSensorImage = () => {
    dispatch(archiveSensorImageThunk({ sensorImageId: sensorImage.sensorImageId, archive: true, sensorId: sensor.sensorId }))
  }
  return (
    <ListItemButton onClick={archiveSensorImage}>
      <ListItemIcon>
        <HideImageIcon />
      </ListItemIcon>
      <ListItemText>
        Archive
      </ListItemText>
    </ListItemButton>
  )
}

export default memo(SensorImageDrawerArchive)
