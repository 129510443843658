import { createAsyncThunk } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { addLoadingItemAction, dropLoadingItemAction } from '../slices/loading'
import { uploadImgToBlob } from '../utils/uploadImgToBlob'
import imageCompression from 'browser-image-compression'
import { convertSpaceToUnderscores } from '../utils/convertSpaceToUnderscores'
import { getImageExt } from '../utils/getImageExt'
import { postSensorImageToBackendThunk } from './postSensorImageToBackendThunk'
import {
  addLocalImageToSensorAction,
  convertLocalImageInSensorToUploadedImageAction,
  updateLocalImageInSensorUploadProgressAction
} from "../slices/sensors";
import {postDeviceImageToBackendThunk} from "./postDeviceImageToBackendThunk";
import {getDevicesThunk} from "./getDevicesThunk";

export const addDeviceImageThunk = createAsyncThunk(
  'stores/addDeviceImage',
  async ({ deviceId, image }, { dispatch, getState }) => {
    const currentState = getState()
    const storeIdForSensors = currentState.sensors.storeIdOfSensors
    const devices = currentState.devices.data[deviceId]
    const deviceImageBlobKey = `${storeIdForSensors}/devices/${deviceId}/${uuidv4()}.${getImageExt(image)}`
    dispatch(addLoadingItemAction('uploadImg'))
    try {
      const compressedImage = await imageCompression(image, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
      })
      await uploadImgToBlob(deviceImageBlobKey, compressedImage)
      const newSensorImage = await dispatch(postDeviceImageToBackendThunk({
        deviceId,
        deviceImageBlobKey
      })).unwrap()
      await dispatch(getDevicesThunk({
        storeId: storeIdForSensors,
        refresh: true
      })).unwrap()
      // delete newSensorImage.sensor
      // dispatch(convertLocalImageInSensorToUploadedImageAction({
      //   sensorId,
      //   oldSensorImageId: tempSensorImageId,
      //   newSensorImage
      // }))
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(dropLoadingItemAction('uploadImg'))
    }
  }
)
