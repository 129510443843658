import RedirectIfForbidden from '../../components/RedirectIfForbidden'
import React, { memo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SensorForm from '../../components/SensorForm'
import { loadStoreDataWrap } from '../../wraps/loadStoreDataWrap'
import { loadSensorDataWrap } from '../../wraps/loadSensorDataWrap'
import { editSensorNavbarWrap } from '../../wraps/editSensorNavbarWrap'
import { useDispatch } from 'react-redux'
import { editSensorThunk } from '../../thunks/editSensorThunk'

function EditSensorPage () {
  const { storeId, sensorId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <>
      <RedirectIfForbidden permissionType={'editSensor'} keyword={storeId} />
      <SensorForm onSubmit={() => {
        dispatch(editSensorThunk({
          originalSensorId: sensorId,
          navigate
        }))
      }} />
    </>
  )
}

export default loadStoreDataWrap(loadSensorDataWrap(editSensorNavbarWrap(memo(EditSensorPage))))
