import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  err: null,
  data: {},
  storeIdOfDevice: null
}

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    updateDeviceAction: (state, action) => {
      const { data, storeId } = action.payload
      state.err = null
      state.data = data
      state.storeIdOfDevice = storeId
    },
    updateDeviceErrAction: (state, action) => {
      state.storeIdOfDevice = null
      state.data = {}
      state.err = action.payload
    },
    addLocalImageToDeviceAction: (state, action) => {
      const {
        sensorId,
        sensorImageId,
        sensorImageUrl
      } = action.payload
      const sensor = state.data[sensorId]
      if (sensor) {
        const now = new Date()
        sensor.sensorImages.push({
          sensorImageId,
          sensorImageBlobKey: '',
          sensorTempUrl: sensorImageUrl,
          isArchived: false,
          createdAt: now.getTime(),
          lastUpdate: now.getTime(),
          uploadProgress: 0
        })
      }
    },
    updateLocalImageInDeviceUploadProgressAction: (state, action) => {
      const {
        sensorId,
        sensorImageId,
        uploadProgress
      } = action.payload
      const sensor = state.data[sensorId]
      if (sensor) {
        for (const sensorImage of sensor.sensorImages) {
          if (sensorImage.sensorImageId === sensorImageId) {
            sensorImage.uploadProgress = uploadProgress
            break
          }
        }
      }
    },
    convertLocalImageInDeviceToUploadedImageAction: (state, action) => {
      const {
        sensorId,
        oldSensorImageId,
        newSensorImage
      } = action.payload
      const sensor = state.data[sensorId]
      if (sensor) {
        for (let i = 0; i < sensor.sensorImages.length; i ++) {
          const sensorImage = sensor.sensorImages[i]
          if (sensorImage.sensorImageId === oldSensorImageId) {
            sensor.sensorImages[i] = newSensorImage
            break
          }
        }
      }
    },
    deleteDeviceImageAction: (state, action) => {
      const {
        sensorId,
        sensorImageId,
      } = action.payload
      const sensor = state.data[sensorId]
      if (sensor) {
        for (let i = 0; i < sensor.sensorImages.length; i ++) {
          const sensorImage = sensor.sensorImages[i]
          if (sensorImage.sensorImageId === sensorImageId) {
            sensor.sensorImages.splice(i, 1)
            break
          }
        }
      }
    },
    updateArchiveDeviceImageAction: (state, action) => {
      const {
        sensorId,
        sensorImageId,
        archive
      } = action.payload
      const sensor = state.data[sensorId]
      if (sensor) {
        for (let i = 0; i < sensor.sensorImages.length; i ++) {
          const sensorImage = sensor.sensorImages[i]
          if (sensorImage.sensorImageId === sensorImageId) {
            sensorImage.isArchived = archive
            break
          }
        }
      }
    }
  }
})

export const { updateDeviceAction, updateDeviceErrAction, addLocalImageToDeviceAction, updateLocalImageInDeviceUploadProgressAction, convertLocalImageInDeviceToUploadedImageAction, deleteDeviceImageAction, updateArchiveDeviceImageAction } = devicesSlice.actions

export const devicesReducer = devicesSlice.reducer
