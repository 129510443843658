import { Button, ImageListItem } from '@mui/material'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import React, { memo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { addSensorImageThunk } from '../../../../thunks/addSensorImageThunk'
import { useSensor } from '../../../../hooks/useSensor'
import PropTypes from 'prop-types'


function NewImgItemButton () {
  const imageInputRef = useRef()
  const sensor = useSensor()
  const dispatch = useDispatch()
  const onImage = () => {
    const imgFile = imageInputRef.current.files[0]
    dispatch(addSensorImageThunk({
      sensorId: sensor.sensorId,
      image: imgFile
    }))
  }

  return (
    <ImageListItem
      key={'AddImg'}
      style={{
        height: '200px'
      }}
    >
      <Button
        variant="outlined"
        component='label'
        fullWidth={true}
        style={{
          height: '100%'
        }}
      >
        <CameraAltIcon style={{
          fontSize: '40px'
        }} />
        <input hidden accept='image/*' type='file' ref={imageInputRef} onChange={onImage} />
      </Button>
    </ImageListItem>
  )
}

export default memo(NewImgItemButton)
