import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {updateConfirmAction} from "../../slices/confirm";

function AppDialogConfirm() {
  const confirm = useSelector(state => state.confirm)

  const dispatch = useDispatch()

  const makeConfirm = (yesOrNo) => {
    document.dispatchEvent(new CustomEvent('appConfirm', {
      detail: {
        yesOrNo
      }
    }))
    dispatch(updateConfirmAction({
      open: false
    }))
  }

  return (
    <Dialog
      open={confirm.open}
      onClose={() => makeConfirm(false)}
    >
      <DialogContent>
        <DialogContentText>
          {confirm.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color={'primary'}
          onClick={() => makeConfirm(true)}
        >
          Yes
        </Button>
        <Button
          color={'error'}
          onClick={() => makeConfirm(false)}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppDialogConfirm