import { ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material'
import React, { memo } from 'react'

function LocationSkeleton () {
  return (
    <ListItem>
      <ListItemIcon>
        <Skeleton variant="circular" height={35} width={35}/>
      </ListItemIcon>
      <ListItemText
        primary={(
          <Skeleton variant={'text'} />
        )}
        secondary={(
          <Skeleton variant={'text'} />
        )}
      />
    </ListItem>
  )
}

export default memo(LocationSkeleton)
