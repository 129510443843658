import { Chart as ChartJS, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import { getChartLabelPlugin, PLUGIN_ID } from 'chart.js-plugin-labels-dv';

ChartJS.registry?.plugins.get(PLUGIN_ID)

ChartJS.register(
  annotationPlugin,
  zoomPlugin,
  ChartDataLabels,
  getChartLabelPlugin(),
  ...registerables
);
ChartJS.defaults.font = {
  size: 10,
};
