import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sensorId: {
    val: '',
    err: false,
    required: true
  },
  sensorType: {
    val: '',
    required: true
  },
  locationCategory: {
    val: '',
    required: true
  },
  locationName: {
    val: '',
    required: true
  },
  sensorName: {
    val: '',
    required: true
  }
}

const sensorInfoFormSlice = createSlice({
  name: 'newSensor',
  initialState,
  reducers: {
    updateSensorInfoFormFieldAction: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    cleanSensorInfoFormAction: () => {
      return initialState
    },
    cleanSensorInfoFormThenPredefineFieldsAction: (state, action) => {
      return {
        ...initialState,
        ...action.payload
      }
    }
  }
})

export const { updateSensorInfoFormFieldAction, cleanSensorInfoFormAction, cleanSensorInfoFormThenPredefineFieldsAction } = sensorInfoFormSlice.actions

export const sensorInfoFormReducer = sensorInfoFormSlice.reducer
