import {createAsyncThunk} from "@reduxjs/toolkit";
import {revokeTokenApiRequest} from "../apiRequests/revokeTokenApiRequest";
import {getSessionStorageData} from "../../../utils";

const {REACT_APP_PING_FEDERATE_ENDPOINT, REACT_APP_REDIRECT_URI} = process.env

export const logoutThunk = createAsyncThunk(
  'insight/fetchAPI',
  async (arg, { dispatch, rejectWithValue }) => {
    const pingFederateRefreshToken = getSessionStorageData(
      "pingFederateRefreshToken"
    );
    const queryData = {
      token: pingFederateRefreshToken,
      token_type_hint: `refresh_token`,
    };
    await revokeTokenApiRequest.fetch({
      dispatch,
      queryData
    })


    let singOffUrl = `${REACT_APP_PING_FEDERATE_ENDPOINT}/idp/startSLO.ping`;
    singOffUrl = singOffUrl.concat(
      `?TargetResource=${REACT_APP_REDIRECT_URI}&InErrorResource=${REACT_APP_REDIRECT_URI}`
    );
    sessionStorage.clear();
    window.location.href = singOffUrl

  }
)