import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { deleteSensorImageThunk } from '../../../../thunks/deleteSensorImageThunk'
import {useSensor} from "../../../../hooks/useSensor";
SensorImageDrawerDelete.propTypes = {
  sensorImage: PropTypes.object.isRequired
}

function SensorImageDrawerDelete ({ sensorImage }) {
  const dispatch = useDispatch()
  const sensor = useSensor()
  const deleteSensorImage = () => {
    dispatch(deleteSensorImageThunk({
      sensorImageId: sensorImage.sensorImageId,
      archive: false,
      sensorId: sensor.sensorId
    }))
  }
  return (
    <ListItemButton onClick={deleteSensorImage}>
      <ListItemIcon>
        <DeleteIcon color={'error'} />
      </ListItemIcon>
      <ListItemText>
        Delete Image
      </ListItemText>
    </ListItemButton>
  )
}

export default memo(SensorImageDrawerDelete)
