import { Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { closeSnackbarAction } from '../../slices/snackbar'
import MuiAlert from '@mui/material/Alert'
import React, { forwardRef, memo } from 'react'

const Alert = forwardRef(function Alert (
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function AppSnackbar () {
  const dispatch = useDispatch()
  const snackbar = useSelector(state => state.snackbar)
  const handleClose = () => {
    dispatch(closeSnackbarAction())
  }
  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <Alert onClose={handleClose} severity={snackbar.severity} sx={{ width: '100%' }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  )
}

export default memo(AppSnackbar)
