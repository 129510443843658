import {combineReducers} from "redux";
import dashboardSettingSlice from "./dashboardSettingSlice";
import loadingSlice from "./loadingSlice";
import analyticsSlice from "./analyticsSlice";
import connectivitySlice from "./connectivitySlice";
import userManagementSlice from "./userManagementSlice";
import queueSlice from "./queueSlice";

const runOpsReducer = combineReducers({
    setting: dashboardSettingSlice,
    loadings: loadingSlice,
    pages: combineReducers({
        analytics: analyticsSlice,
        user_management: userManagementSlice,
        connectivity: connectivitySlice,
        queue: queueSlice
    })
})

export default runOpsReducer