import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  regionSearch: '',
  marketSearch: '',
  areaLeaderSearch: '',
  storeSearch: ''
}

const dashboardFilterSearchSlice = createSlice({
  name: 'iotDashboardFilter',
  initialState,
  reducers: {
    updateDashboardFilterSearchAction: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    clearDashboardFilterSearchAction: state => initialState
  }
})

export const dashboardFilterSearchReducer = dashboardFilterSearchSlice.reducer

export const {updateDashboardFilterSearchAction, clearDashboardFilterSearchAction} = dashboardFilterSearchSlice.actions