import AppSubmitIconButton from '../../../components/AppSubmitIconButton'
import DoneIcon from '@mui/icons-material/Done'
import React from 'react'
import { editSensorThunk } from '../../../thunks/editSensorThunk'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

function EditSensorNavbarSubmitButton () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sensorId } = useParams()
  return (
    <AppSubmitIconButton
      fieldsSelector={state => state.newSensor}
      style={{
        marginLeft: 'auto',
        color: 'inherit'
      }}
      onClick={() => {
        dispatch(editSensorThunk({
          originalSensorId: sensorId,
          navigate
        }))
      }}
    >
      <DoneIcon />
    </AppSubmitIconButton>
  )
}

export default EditSensorNavbarSubmitButton
