import RestClient from "../../api/RestClient";
import continuousGetDataRequest from "./continuousGetDataRequest";
import AllNotificationTemp from "../../Temp/AllNotificationTemp";

export const getNotificationFromBackend = async (request, dispatch) => {
  const allNotificationTemp = AllNotificationTemp.getInst()

  let restResult = allNotificationTemp.get({
    requestCalls: JSON.stringify(request),
    newRelicApiName: "Notification API"
  })

  if (restResult) {
    return restResult
  }

  restResult = await continuousGetDataRequest(RestClient.getDynamicNotificationData, request, dispatch)

  allNotificationTemp.set({
    requestCalls: JSON.stringify(request),
    newRelicApiName: "Notification API"
  }, restResult)
  return restResult
}