import React, {lazy, Suspense} from "react";
import { getSessionStorageData } from "./utils";

const DashboardTheme = lazy(() => import('./dashboardTheme.js'));
const SnapshotTheme = lazy(() => import('./subsites/Snapshot/snapshotTheme.js'));

const ThemeSelector = ({ children }) => {
    const CHOSEN_THEME = getSessionStorageData('signInFor');
    return (
      <>
        <Suspense fallback={<></>}>
          {(CHOSEN_THEME === "snapshot") && <SnapshotTheme />}
          {(CHOSEN_THEME !== "snapshot") && <DashboardTheme />}
        </Suspense>
        {children}
      </>
    )
}

export default ThemeSelector;