import { sensorTypes } from '../../constants'

export const sensorLocationCategoryNameMap = {
  Bathroom: {
    locationNames: ['Bathroom'],
    sensorNames: ['Bathroom'],
    sensorType: [sensorTypes.DOOR]
  },
  Beer: {
    locationNames: ['Cooler'],
    sensorNames: ['Beer Door Cooler', 'Beer Cave'],
    sensorType: [sensorTypes.DOOR, sensorTypes.TEMP]
  },
  'Frozen Food': {
    locationNames: ['Cooler'],
    sensorNames: ['Frozen Food Display Freezer'],
    sensorType: [sensorTypes.DOOR, sensorTypes.TEMP]
  },
  'Ice Bag': {
    locationNames: ['Freezer', 'Cooler'],
    sensorNames: ['Ice Freezer'],
    sensorType: [sensorTypes.DOOR, sensorTypes.TEMP]
  },
  'Ice Cream': {
    locationNames: ['Cooler', 'Freezer'],
    sensorNames: ['Ice Cream Display Freezer', 'Ice Cream Novelty Case'],
    sensorType: [sensorTypes.DOOR, sensorTypes.TEMP]
  },
  'Main Door': {
    locationNames: ['Main Door'],
    sensorNames: ['Main'],
    sensorType: [sensorTypes.DOOR]
  },
  'Open Air Case': {
    locationNames: ['Cooler'],
    sensorNames: ['Open Air Case'],
    sensorType: [sensorTypes.TEMP]
  },
  Soda: {
    locationNames: ['Beverage Wall'],
    sensorNames: ['Level Co2'],
    sensorType: [sensorTypes.CO2]
  },
  'Soft Drink': {
    locationNames: ['Cooler'],
    sensorNames: ['Drink Cooler', 'Drink Vault'],
    sensorType: [sensorTypes.DOOR, sensorTypes.TEMP]
  },
  Dairy: {
    locationNames: ['Cooler'],
    sensorNames: ['Dairy Section'],
    sensorType: [sensorTypes.TEMP]
  },
  'Vault': {
    locationNames: ['Vault'],
    sensorNames: ['Vault'],
    sensorType: [sensorTypes.DOOR, sensorTypes.TEMP]
  },
  Backroom: {
    locationNames: ['Backroom'],
    sensorNames: ['Backroom Freezer', 'Under Counter Freezer', 'Under Counter Refrigerator'],
    sensorType: [sensorTypes.TEMP]
  }
}
