import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loadingItems: [],
  blockLoadingItems: [],
  loadingPercentage: {}
}

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    addLoadingItemAction: (state, action) => {
      state.loadingItems.push(action.payload)
    },
    dropLoadingItemAction: (state, action) => {
      for (let i = 0; i < state.loadingItems.length; i++) {
        if (action.payload === state.loadingItems[i]) {
          state.loadingItems.splice(i, 1)
          break
        }
      }
    },
    addBlockLoadingItemAction: (state, action) => {
      state.blockLoadingItems.push(action.payload)
    },
    dropBlockLoadingItemAction: (state, action) => {
      for (let i = 0; i < state.blockLoadingItems.length; i++) {
        if (action.payload === state.blockLoadingItems[i]) {
          state.blockLoadingItems.splice(i, 1)
          break
        }
      }
    },
    putLoadingPercentage: (state, action) => {
      const { key, val } = action.payload
      state.loadingPercentage[key] = val
    },
    dropLoadingPercentage: (state, action) => {
      delete state.loadingPercentage[action.payload]
    }
  }
})

export const {
  addLoadingItemAction,
  dropLoadingItemAction,
  addBlockLoadingItemAction,
  dropBlockLoadingItemAction,
  putLoadingPercentage,
  dropLoadingPercentage
} = loadingSlice.actions

export const loadingReducer = loadingSlice.reducer
