import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { cleanSensorInfoFormAction } from '../slices/sensorInfoForm'
import { getSensorsThunk } from './getSensorsThunk'
import { newRelicAddPageActionThunk } from './newRelicAddPageActionThunk'

export const editSensorThunk = createAsyncThunk(
  'sensor/editSensor',
  async ({ originalSensorId, navigate }, { getState, dispatch }) => {
    const currentState = getState()
    const sensorForm = currentState.sensorInfoForm
    const storeIdForSensors = currentState.sensors.storeIdOfSensors
    const {
      sensorId,
      sensorType,
      locationCategory,
      locationName,
      sensorName
    } = sensorForm
    await dispatch(fetchBackendAPIThunk({
      method: 'patch',
      url: '/sensor',
      data: {
        originalSensorId,
        sensorDetails: {
          sensorId: sensorId.val,
          sensorType: sensorType.val,
          sensorName: sensorName.val,
          locationName: locationName.val,
          locationCategory: locationCategory.val
        }
      },
      params: {
        storeId: storeIdForSensors
      },
      blockUi: true
    })).unwrap()
    dispatch(cleanSensorInfoFormAction())
    navigate(-2)
    await dispatch(getSensorsThunk({})).unwrap()
    await dispatch(newRelicAddPageActionThunk({
      eventName: 'Sensor Update',
      payload: {
        sensorId: sensorId.val,
        sensorType: sensorType.val,
        sensorName: sensorName.val,
        locationName: locationName.val,
        locationCategory: locationCategory.val,
        storeId: storeIdForSensors
      }
    })).unwrap()
    setTimeout(() => {
      navigate(`/snapshot/home/${storeIdForSensors}/${sensorId.val}`)
    }, 10)
  }
)
