import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  err: null,
  data: {}
}

const allStores = createSlice({
  name: 'allStores',
  initialState,
  reducers: {
    updateAllStoresAction: (state, action) => {
      state.err = null
      state.data = action.payload
    },
    updateAllStoresErrAction: (state, action) => {
      state.err = action.payload
    },
    updateStoreInAllStoreAction: (state, action) => {
      const { storeId, newStoreData } = action.payload
      state.data[String(storeId)] = {
        ...state.data[storeId],
        ...newStoreData
      }
    }
  }
})

export const { updateAllStoresAction, updateAllStoresErrAction, updateStoreInAllStoreAction } = allStores.actions

export const allStoresReducer = allStores.reducer
