import React, { memo, useEffect, useState } from 'react'
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode'
import { Box, CircularProgress, Modal } from '@mui/material'
import PropTypes from 'prop-types'
import QrCodeScannerFromImage from './QrCodeScannerFromImage'
import { startHtml5QrCode } from './startHtml5QrCode'

QrCodeScannerModal.propTypes = {
  scannerOpen: PropTypes.bool.isRequired,
  setScannerOpen: PropTypes.func.isRequired,
  handleScan: PropTypes.func.isRequired
}

function QrCodeScannerModal ({ scannerOpen, setScannerOpen, handleScan }) {
  const [html5QrCode, setHtml5QrCode] = useState(null)
  useEffect(() => {
    setTimeout(async () => {
      if (scannerOpen) {
        let currentHtml5QrCode = html5QrCode
        if (!currentHtml5QrCode) {
          currentHtml5QrCode = new Html5Qrcode('qr-code-scanner', {
            formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE]
          })
          setHtml5QrCode(currentHtml5QrCode)
        }
        await startHtml5QrCode(currentHtml5QrCode, handleScan)
      } else {
        if (html5QrCode) {
          const currentScannerState = html5QrCode.getState()
          if (currentScannerState === 2) {
            await html5QrCode.stop()
            await html5QrCode.clear()
          }
        }
      }
    }, 10)
  }, [scannerOpen])
  return (
    <Modal
      open={scannerOpen}
      onClose={() => setScannerOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#fff'
        }}>
          <CircularProgress color={'inherit'} />
        </Box>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%'
        }}>
          <Box
            id={'qr-code-scanner'}
          />
        </Box>
        <QrCodeScannerFromImage handleScan={handleScan} html5QrCode={html5QrCode} />
      </Box>
    </Modal>
  )
}

export default memo(QrCodeScannerModal)
