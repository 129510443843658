import { Typography } from '@mui/material'
import React, { memo } from 'react'
import {appVersion} from "../../constants";

function AppVersionDisplay () {
  return (
    <Typography style={{
      color: '#ccc'
    }}>
      Snapshot V. {appVersion}
    </Typography>
  )
}

export default memo(AppVersionDisplay)
