import { getMainComponentDetails } from "./getMainComponentDetails";
import { formatDateToSwedenFormat } from "../../../utlities/formatTime"
import { getDateRange } from "../../../utlities/dateCalculation";
// import metadataConfig from "../../../actions/metadata.json";

export const generateRequest = (
  component,
  cIndex,
  dateEnqueued,
  targetTime,
  storeIds,
  pageId,
  performanceType,
  storeCoverageType,
  region,
  market,
  al,
  metadataConfig
) => {
  let { yAxisMetrics, xAxis, threshold } =
    getMainComponentDetails(component);
  let yAxisMetric =
    yAxisMetrics?.length > 0 ? yAxisMetrics[cIndex] : "";
  xAxis = xAxis?.length > 0 ? xAxis[cIndex] : "";
  let dataType = component?.dataType?.[cIndex]
  let dataSubType = component?.dataSubType?.[cIndex]  
  
  let { request, apiCall } = structuredClone(
    metadataConfig[`${dataType}-${dataSubType}`] || metadataConfig[dataType]
  );

  if (dataType === "Sensor" && dataSubType === "Co2" && threshold) {
    request["filters"] = {
        ...request["filters"],
        "sensor_data": {
            "operator": "<="
        }
    }
  }

  if(dataType === "Sensor" && dataSubType === "Door") {
    request["filters"] = {
        ...request["filters"],
        "sensor_data_type": [
            yAxisMetric === "count" ? "OpenCount" : "OpenDuration"
        ]
    }
  }

  if(dataType === "Appliance" && dataSubType === "Franke") {
    request?.["top"]?.["by"]?.unshift((xAxis === "date_enqueued" || xAxis === "time_of_day") ? xAxis : "product_type_value")
    request["top"] = {
      ...request["top"],
      "field": yAxisMetric,
    }
    if (xAxis !== "date_enqueued")
      request["fields"]?.push(xAxis === "time_of_day" ? "time_of_day" : "product_type_value")
    if (xAxis !== "date_enqueued" && xAxis !== "time_of_day")
      request["filters"] = {
        ...request?.["filters"],
        "product_type_name": [xAxis]
      }
  }
  
  if (request?.isChartMetric) {
    request["chart_metric"] = [{
      "x_axis": xAxis === "date_enqueued" ? "date" : xAxis,
      "metric": yAxisMetric,
      "y_axis": (dataType === "Sensor" && dataSubType === "Cigarette Counting" && yAxisMetric === "latest_datetime") ? "date_time" : yAxisMetric
    }]
    if (component?.isLatestData)
      request["chart_metric"] = [{
        ...request["chart_metric"][0],
        metric_period: "latest"
      }]
    delete request.isChartMetric
  }

  if (request?.isLogMetric) {
    request["chart_metric"] = [{
      "x_axis": "date",
      "metric": "max",
      "y_axis": "sensor_data"
    }]
    delete request.isLogMetric
  }

  if(yAxisMetric === "distinct_store_count") {
    request["groupBy"] = ["date_enqueued"]
  }

  if (component?.isSingleDayData && request?.filters) {
    request["filters"]["date_enqueued"] = {
      value: formatDateToSwedenFormat(targetTime),
      operator: "=",
    };
    request["filters"]["store_id"] = storeIds;
  } else if (request?.filters) {
    if (pageId !== "power_cycle") {
      if (component?.isLatestData || component?.isLast3DaysData)
        delete request.filters.date_enqueued
      else if (component?.isLast7DaysData)
        request["filters"]["date_enqueued"] =
          formatDateToSwedenFormat(getDateRange("LAST 7 DAYS"));
      else if (apiCall !== "getDynamicNotificationData") //720 hrs is set for getDynamicNotificationData
        request["filters"]["date_enqueued"] = formatDateToSwedenFormat(dateEnqueued);
    }

    if (pageId === "co2" && threshold && request["filters"]["sensor_data"]) {
      request["filters"]["sensor_data"]["value"] = Number(threshold) * 100
    }
    request["filters"]["store_id"] = storeIds;
  } else if (request?.storeId) {
    request["storeId"] = storeIds;
  }

  if (pageId === "franke" && request?.filters && request?.top) {
    request["top"]["order"] = performanceType;
    if (storeCoverageType === "Stores") {
      request["fields"] = [...request["fields"], "store_id"]
    } else if (storeCoverageType === "Regions") {
      request["filters"]["zone_code"] = region;
      request["fields"] = [...request["fields"], "zone_code"]
    } else if (storeCoverageType === "Markets") {
      request["filters"]["market_code"] = market;
      request["fields"] = [...request["fields"], "market_code"]
    } else if (storeCoverageType === "Areas") {
      request["filters"]["area_leader_name"] = al;
      request["fields"] = [...request["fields"], "area_leader_name"]
    }
  }

  return { request, apiCall };
};