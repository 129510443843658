import { updateStoreSearchTextAction } from '../../../slices/storeSearch'
import AppTextField from '../../../components/AppTextField'
import React from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import StoreSearchBarCloseKeyboardButton from './StoreSearchBarCloseKeyboardButton'

function StoreSearchBar () {
  const storeSearchOpen = useSelector(state => state.storeSearch.open)
  const visualViewportHeight = useSelector(state => state.uiSize.visualViewportHeight)
  return storeSearchOpen
    ? (
    <Box
      style={{
        position: 'fixed',
        top: `${visualViewportHeight - 75}px`,
        width: '96%',
        backgroundColor: '#fff',
        padding: '10px 0',
        display: 'flex',
        gap: '5px',
        zIndex: 10
      }}
    >
      <Box
        style={{
          width: '100%'
        }}
      >
        <AppTextField
          label={'Search'}
          fieldSelector={state => state.storeSearch.text}
          updateAction={updateStoreSearchTextAction}
        />
      </Box>
      <StoreSearchBarCloseKeyboardButton />
    </Box>
      )
    : <></>
}

export default StoreSearchBar
