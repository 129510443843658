import { appNavbarWrap } from '../appNavbarWrap'
import React, { memo } from 'react'
import { Toolbar } from '@mui/material'
import UserDrawer from '../../components/UserDrawer'
import StoreSearchToggleButton from './StoreSearchToggleButton'
import ConditionalRenderByPermission from '../../components/ConditionalRenderByPermission'
import AddStoreNavbarButton from './AddStoreNavbarButton'

export const homeNavbarWrap = (Component) => {
  return appNavbarWrap(Component, memo(
    function Wrapped () {
      return (
        <Toolbar>
          <StoreSearchToggleButton />
          <UserDrawer />
        </Toolbar>
      )
    }
  ),
  memo(
    function BottomNav () {
      return (
          <ConditionalRenderByPermission permissionType={'createStore'}>
            <AddStoreNavbarButton />
          </ConditionalRenderByPermission>
      )
    }
  )
  )
}
