import {fetchBackendAPIThunk} from "../../insightThunks/fetchBackendApiThunk";
import AsyncLock from "async-lock";
import {fetchAPIThunk} from "../../insightThunks/fetchApiThunk";
import {fetchPingFederateAPIThunk} from "../../insightThunks/fetchPingFederateApiThunk";
import {API_TYPE} from "../../constants";

export class ApiRequest {
  constructor({
                apiRequestId,
                apiType,
                url,
                method,
                query,
                bodyData,
                bodyDataPreprocessor,
                responseDataProcessor,
                updater,
                disableMemo=false
              }) {
    this.apiMemo = new Map()
    this.componentsUpdateFunctions = new Map()
    this.lock = new AsyncLock()
    this.lastApiResponse = null
    this.apiRequestId = apiRequestId
    this.apiType = apiType
    this.url = url
    this.method = method
    this.query = query
    this.bodyData = bodyData
    this.responseDataProcessor = responseDataProcessor
    this.bodyDataPreprocessor = bodyDataPreprocessor
    this.updater = updater
    this.disableMemo = disableMemo
  }
  updateMountedComponents() {
    this.componentsUpdateFunctions.forEach((updateFunction, componentId) => {
      updateFunction()
    })
  }

  clearMemo() {
    this.apiMemo.clear()
  }

  updateApiMemo({bodyData, queryData, reducer}) {
    const requestKey = JSON.stringify({bodyData, queryData})
    const responseFromMemo = this.apiMemo.get(requestKey)
    this.clearMemo()
    if (responseFromMemo) {
      this.apiMemo.set(requestKey, reducer(responseFromMemo))
    }
  }

  async fetchNoMemo({dispatch, bodyData, queryData}) {
    let result
    if (this.bodyDataPreprocessor) {
      bodyData = await this.bodyDataPreprocessor(bodyData)
    }
    switch (this.apiType) {
      case API_TYPE.BACKEND_API: {
        result = await dispatch(fetchBackendAPIThunk({
          method: this.method,
          url: this.url,
          data: bodyData
        })).unwrap()
        break
      }
      case API_TYPE.PING_FEDERATE_API: {
        result = await dispatch(fetchPingFederateAPIThunk({
          method: this.method,
          url: this.url,
          params: queryData
        })).unwrap()
        break
      }
      case API_TYPE.EXTERNAL_API: {
        result = await dispatch(fetchAPIThunk({
          config: {
            method: this.method,
            url: this.url,
            params: queryData,
            data: bodyData,
          }
        })).unwrap()
        break
      }
    }
    if (this.responseDataProcessor) {
      result = await this.responseDataProcessor(result)
    }
    return result
  }

  async fetch({
    dispatch,
    bodyData,
    queryData
              }) {
    const requestKey = JSON.stringify({bodyData, queryData})
    let result
    let self = this
    await this.lock.acquire(requestKey, async () => {
      result = self.apiMemo.get(requestKey)
      if (requestKey)
        if (!result) {
          try {
            result = await this.fetchNoMemo({dispatch, bodyData, queryData})
          } catch (e) {
            throw e
          } finally {
            if (!result) {
              result = 'error'
            }
            self.apiMemo.set(requestKey, result)
          }
        } else if (result === 'error') {
          throw new Error('API request failed')
        }
      self.lastApiResponse = result
      if (self.updater) {
        dispatch(self.updater(result))
      }
    })
    return result
  }
}