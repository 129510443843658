export const iamPermissionCheck = (permission, keyword) => {
  if (typeof permission === 'boolean') {
    return permission
  }
  for (const eachPermissionItem of permission) {
    if (new RegExp(eachPermissionItem).test(String(keyword))) {
      return true
    }
  }
  return false
}
