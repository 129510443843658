import {useState} from "react";

function ExtraLargeButton({onClick, children}) {
    const [buttonColor, setButtonColor] = useState('#107f62')
    const makeButtonLight = () => {
        setButtonColor('#388c79')
    }
    const makeButtonDark = () => {
        setButtonColor('#107f62')
    }
    return(
        <button
            onClick={onClick}
            style={{
                background: buttonColor,
                fontSize: '18px',
                color: '#fff',
                fontWeight: 'bold',
                border: 'none',
                width: '203px',
                height: '50px',
                borderRadius: '3px'
            }}
            onMouseEnter={makeButtonLight}
            onMouseLeave={makeButtonDark}
            onMouseDown={makeButtonDark}
            onMouseUp={makeButtonLight}
        >
            {children}
        </button>
    )
}

export default ExtraLargeButton