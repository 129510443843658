import React, { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { getNewrelic } from '../../utils/getNewrelic'
const newrelic = getNewrelic()

function NewrelicRouteTracer () {
  const location = useLocation()
  useEffect(() => {
    newrelic.setCurrentRouteName(location.pathname)
  }, [location.pathname])
  return <></>
}

export default memo(NewrelicRouteTracer)
