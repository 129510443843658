import React, { memo } from 'react'
import SensorTypeIcon from '../SensorTypeIcon'
import { useSelector } from 'react-redux'
import FormHeader from '../FormHeader'
import HelpModal from '../HelpModal'
import { Box } from '@mui/material'

function SensorFormHeader () {
  const sensorType = useSelector(state => state.sensorInfoForm.sensorType)
  return (
    <FormHeader>
      <SensorTypeIcon
        sensorType={sensorType.val}
        style={{
          fontSize: '50px'
        }}
        color={'primary'}
      />
      <Box style={{
        position: 'absolute',
        left: '85%',
        top: '8%'
      }}>
        <HelpModal />
      </Box>
    </FormHeader>
  )
}

export default memo(SensorFormHeader)
