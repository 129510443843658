import React, {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchSsoLoginTokenThunk} from "../../thunks/fetchSsoLoginTokenThunk";
import {ssoSignInThunk} from "../../thunks/ssoSignInThunk";

let handled = false

function SsoLoginHandle() {
  const [searchParams, setSearchParams] = useSearchParams()
  const accessToken = useSelector(state => state.global.ssoTokens.accessToken)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ssoSignIn = async (code) => {
    await dispatch(fetchSsoLoginTokenThunk({code})).unwrap()
  }

  useEffect(() => {
    (async function() {
      if (accessToken) {
        await dispatch(ssoSignInThunk({
          accessToken
        })).unwrap()
        navigate('/snapshot/home')
      }
    })()
  }, [accessToken])

  useEffect(() => {
    if (handled) {
      return
    }
    handled = true
    const code = searchParams.get('code')
    if (code) {
      void ssoSignIn(code)
    }
  }, [searchParams])
  return <></>
}

export default SsoLoginHandle