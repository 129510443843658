import { useDispatch, useSelector } from 'react-redux'
import { updateSensorInfoFormFieldAction } from '../../slices/sensorInfoForm'
import { Box } from '@mui/material'
import AppSelect from '../AppSelect'
import { getLocationNamesByCategory } from '../../utils/sensorDetail'
import React, { memo, useEffect } from 'react'

function LocationNameSelect () {
  const dispatch = useDispatch()
  const currentLocationCategory = useSelector(state => state.sensorInfoForm.locationCategory)
  const currentLocationName = useSelector(state => state.sensorInfoForm.locationName)
  const updateAction = (val) => updateSensorInfoFormFieldAction({
    locationName: val
  })
  useEffect(() => {
    if (currentLocationCategory.val === '' && !currentLocationName.disabled) {
      dispatch(updateAction({
        ...currentLocationName,
        disabled: true
      }))
    } else if (currentLocationCategory.val !== '' && currentLocationName.disabled) {
      dispatch(updateAction({
        ...currentLocationName,
        disabled: false
      }))
    }
  }, [currentLocationCategory.val])
  return (
    <Box style={{
      margin: '10px 0'
    }}>
      <AppSelect
        updateAction={updateAction}
        fieldSelector={state => state.sensorInfoForm.locationName}
        label={'Location Name'}
        availableOptions={getLocationNamesByCategory(currentLocationCategory.val)}
      />
    </Box>
  )
}

export default memo(LocationNameSelect)
