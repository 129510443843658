import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addRunOpsLoadingItem, removeRunOpsLoadingItem } from "./loadingSlice";
import RunOpsDataTemp from "../../Temp/RunOpsDataTemp";
import continuousGetDataRequest from "../../utlities/rest/continuousGetDataRequest";
import RestClient from "../../api/RestClient";
import Request from "../../request/Request";
import { worker } from "../../worker";
import { showSnackBar } from "../globalSlice";

const runOpsRestDataTemp = RunOpsDataTemp.getInst();

const initState = {
  isError: false,
  cardData: [],
};

export const loadUserManagementData = createAsyncThunk(
  //loadCategoryData - loadCategoryData(pageId)
  "user_management/loadUserManagementData",
  async (pageId, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const request = Request.getRunOpsUserData();
    const state = getState();

    dispatch(addRunOpsLoadingItem("userManagementData"));
    let parsedResult = runOpsRestDataTemp.get({
      requestCalls: JSON.stringify(request),
      newRelicApiName: "userManagementData",
    });

    if (parsedResult) {
      dispatch(removeRunOpsLoadingItem("userManagementData"));
      return fulfillWithValue({
        userManagementData: parsedResult?.userManagementData,
        error: parsedResult.error,
      });
    }

    const res = await continuousGetDataRequest(
      RestClient.getUserManagementData,
      request,
      dispatch,
      true
    );
    // const res =  state?.runOpsData?.pages?.user_management?.cardData
    if (res) {
      const userManagementData = await worker("processUserManagementData", res);
      runOpsRestDataTemp.set(
        {
          requestCalls: JSON.stringify(request),
          newRelicApiName: "userManagementData",
        },
        { userManagementData: userManagementData, error: false }
      );
      dispatch(removeRunOpsLoadingItem("userManagementData"));
      return fulfillWithValue({
        userManagementData: userManagementData,
        error: false,
      });
    } else {
      // runOpsRestDataTemp.set(
      //   {
      //     requestCalls: JSON.stringify(request),
      //     newRelicApiName: "userManagementData",
      //   },
      //   { userManagementData: [], error: true }
      // );
      return rejectWithValue();
    }
  }
);

export const addUserData = createAsyncThunk(
  "user_management/addUserData",
  async (
    actionPayload,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    const addRequest = Request.getRunOpsUserData(actionPayload);
    const state = getState();

    const res = await continuousGetDataRequest(
      RestClient.createUser,
      addRequest,
      dispatch,
      true
    );
    if (res) {
      dispatch(showSnackBar({
        severity: "success", 
        message: "User has been added.",
        vertical: "top",
        horizontal: "center"
      }))
      runOpsRestDataTemp.clear();
      dispatch(loadUserManagementData(""));
    } else {
      return rejectWithValue();
    }
  }
);

export const editUserData = createAsyncThunk(
  "user_management/editUserData",
  async (
    actionPayload,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    const updateRequest = Request.updateUserData(actionPayload);
    const state = getState();

    const res = await continuousGetDataRequest(
      RestClient.updateUser,
      updateRequest,
      dispatch,
      true
    );
    if (res) {
      dispatch(showSnackBar({
        severity: "success", 
        message: "User is updated.",
        vertical: "top",
        horizontal: "center"
      }))
      runOpsRestDataTemp.clear();
      dispatch(loadUserManagementData(""));
    } else {
      return rejectWithValue();
    }
  }
);

export const deleteUserData = createAsyncThunk(
  "user_management/deleteUserData",
  async (
    actionPayload,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    const deleteRequest = Request.deleteUser(actionPayload);
    const state = getState();

    const res = await continuousGetDataRequest(
      RestClient.deleteUser,
      deleteRequest,
      dispatch,
      true
    );
    if (res) {
      dispatch(showSnackBar({
        severity: "success", 
        message: "User is deleted.",
        vertical: "top",
        horizontal: "center"
      }))
      runOpsRestDataTemp.clear();
      dispatch(loadUserManagementData(""));
    } else {
      return rejectWithValue();
    }
  }
);

const userManagementSlice = createSlice({
  name: "user_management",
  initialState: initState,
  reducers: {
    updateUserManagementData: (state, action) => {
      //UPDATE_USER_MANAGEMENT_DATA
      state[action.payload?.key] = action.payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUserManagementData.fulfilled, (state, action) => {
      state.cardData = action.payload?.userManagementData;
      state.isError = action.payload?.error;
    });
    builder.addCase(loadUserManagementData.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export const { updateUserManagementData } = userManagementSlice.actions;
export default userManagementSlice.reducer;
