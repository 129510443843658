import React, { memo } from 'react'
import PropTypes from 'prop-types'
import AppDrawerList from '../../AppDrawerList'
import SensorImageDrawerDelete from './SensorImageDrawerDelete'
import SensorImageDrawerArchive from './SensorImageDrawerArchive'
import SensorImageDrawerUnarchive from './SensorImageDrawerUnarchive'
import ConditionalRenderByPermission from '../../ConditionalRenderByPermission'
import { extractFileNameFromUrl } from '../../../utils/extractFileNameFromBlobKey'

SensorImageDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  sensorImage: PropTypes.object.isRequired
}

function SensorImageDrawer ({ open, setOpen, sensorImage }) {
  return (
    <AppDrawerList
      open={open}
      setOpen={setOpen}
      drawerActionsList={(
        <>
          <ConditionalRenderByPermission permissionType={'deleteSensorImage'}>
            <SensorImageDrawerDelete sensorImage={sensorImage} />
          </ConditionalRenderByPermission>
          <ConditionalRenderByPermission permissionType={'archiveSensorImage'}>
            {sensorImage.isArchived
              ? (
              <SensorImageDrawerUnarchive sensorImage={sensorImage} />
                )
              : (
              <SensorImageDrawerArchive sensorImage={sensorImage} />
                )}
          </ConditionalRenderByPermission>
        </>
      )}
      drawerTitle={extractFileNameFromUrl(sensorImage.sensorImageBlobKey)}
      anchor={'bottom'}
    />
  )
}

export default memo(SensorImageDrawer)
