import TouchListeningBox from "../../../components/TouchListeningBox";
import {ListItemButton, ListItemIcon} from "@mui/material";
import SensorTypeIcon from "../../../components/SensorTypeIcon";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";

function DeviceListItem({device}) {
  const navigate = useNavigate()
  const {
    storeId
  } = useParams()
  return (
    <TouchListeningBox onTap={() => {
      navigate(`/snapshot/home/${storeId}/device/${device.deviceId}`)
    }}>
      <ListItemButton>
        <ListItemIcon>
          <SensorTypeIcon color={'primary'} />
        </ListItemIcon>
        {device.deviceType}
      </ListItemButton>
    </TouchListeningBox>
  )
}

export default DeviceListItem