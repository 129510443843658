import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchAPIThunk} from "./fetchApiThunk";

const {REACT_APP_ID, REACT_APP_CLIENT_SECRET, REACT_APP_PING_FEDERATE_ENDPOINT} = process.env

export const fetchTfamApiThunk = createAsyncThunk(
  'util/fetchSsoLoginToken',
  async ({method, url, params}, {getState, dispatch}) => {
    const result = await dispatch(fetchAPIThunk({
      config: {
        method,
        url,
        baseURL: REACT_APP_PING_FEDERATE_ENDPOINT,
        params,
        headers: {
          Authorization: `Basic ${btoa(`${REACT_APP_ID}:${REACT_APP_CLIENT_SECRET}`)}`
        }
      },
      blockUi: true,
    })).unwrap()
    return result
  }
)