import { BlobServiceClient } from '@azure/storage-blob'

const {
  REACT_APP_SNAPSHOT_BLOB_SAS,
  REACT_APP_SNAPSHOT_BLOB_BASE_URL,
  REACT_APP_SNAPSHOT_CONTAINER_NAME
} = process.env

const blobService = new BlobServiceClient(`${REACT_APP_SNAPSHOT_BLOB_BASE_URL}/?${REACT_APP_SNAPSHOT_BLOB_SAS}`)

export const containerClient = blobService.getContainerClient(REACT_APP_SNAPSHOT_CONTAINER_NAME)
