import { Divider, IconButton, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RoomIcon from '@mui/icons-material/Room'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import { cleanSensorInfoFormThenPredefineFieldsAction } from '../../../../slices/sensorInfoForm'
import ConditionalRenderByPermission from '../../../../components/ConditionalRenderByPermission'

LocationListItem.propTypes = {
  locationName: PropTypes.string,
  locationCategory: PropTypes.string
}

function LocationListItem ({ locationName, locationCategory }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch()
  const location = useLocation()
  const addSensorButtonOnClick = () => {
    dispatch(cleanSensorInfoFormThenPredefineFieldsAction({
      locationCategory: {
        val: locationCategory,
        required: true
      },
      locationName: {
        val: locationName,
        required: true
      }
    }))
    navigate(location.pathname + '/new-sensor')
  }
  return (
    <>
      <ListItem secondaryAction={
        <ConditionalRenderByPermission permissionType={'createSensor'}>
          <IconButton onClick={addSensorButtonOnClick}>
            <AddIcon color={'primary'} />
          </IconButton>
        </ConditionalRenderByPermission>
      }>
        <ListItemIcon>
          <RoomIcon color={'primary'}/>
        </ListItemIcon>
        <ListItemText
          primary={locationName}
          secondary={
            <>
              <Typography variant={'span'} color={theme.palette.primary.main}>
                Category:
              </Typography> {locationCategory}
            </>
          }
        />
      </ListItem>
      <Divider />
    </>
  )
}

export default memo(LocationListItem)
