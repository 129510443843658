import {Box, Typography} from '@mui/material'
import React from 'react'
import HelpImg from "../HelpImg";
import howToOnboard1 from '../../../assets/imgs/help/howToOnboardSensor/1.png'
import howToOnboard2 from '../../../assets/imgs/help/howToOnboardSensor/2.png'
import howToOnboard3 from '../../../assets/imgs/help/howToOnboardSensor/3.png'
import howToEditSensor1 from '../../../assets/imgs/help/howToEditSensor/1.png'
import howToDeleteSensor1 from '../../../assets/imgs/help/howToDeleteSensor/1.png'

export const sensorHelpDetail = [
  {
    title: 'How to add a sensor',
    content: (
      <>
        <Typography>
          Go to a store, then click on the "+" button on the bottom
        </Typography>
        <HelpImg src={howToOnboard1} />
        <Typography>
          Then, you can either input the sensor ID by scanning QR code from the sensor box, or type it by your self.
        </Typography>
        <HelpImg src={howToOnboard2} />
        <Typography>
          When you scanning QR code, make sure you aim your camera to the QR code.
        </Typography>
        <HelpImg src={howToOnboard3} />
        <Typography>
          If your cellphone does not scan that, you can click on the image button at the bottom for taking picture of the qr code.
        </Typography>
        <Typography>
          Make sure you correctly input the sensor type, location, and name.
        </Typography>
      </>
    )
  },
  {
    title: 'How to edit/update a sensor I have created',
    content: (
      <>
        <Typography>
          Go to sensor page, then click on the pencil button.
        </Typography>
        <HelpImg src={howToEditSensor1} />
        <Typography>
          Edit the sensor information, then click on "✓"
        </Typography>
      </>
    )
  },
  {
    title: 'How to delete a sensor',
    content: (
      <>
        <Typography>
          Go to the store page, then long press on the sensor I don't want.
        </Typography>
        <HelpImg src={howToDeleteSensor1} />
        <Typography>
          Then, click on delete sensor in the drawer
        </Typography>
      </>
    )
  }
]
