import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { getSensorsThunk } from './getSensorsThunk'
import { newRelicAddPageActionThunk } from './newRelicAddPageActionThunk'

export const postSensorImageToBackendThunk = createAsyncThunk(
  'newSensorImageToBackendThunk/newSensorImageToBackendThunk',
  async ({ sensorId, sensorImageBlobKey, onUploadProgress }, { getState, dispatch }) => {
    const currentState = getState()
    const storeIdForSensors = currentState.sensors.storeIdOfSensors
    const sensorImage = await dispatch(fetchBackendAPIThunk({
      method: 'post',
      url: '/sensor_image',
      data: {
        sensorId,
        sensorImageBlobKey
      },
      onUploadProgress,
      params: {
        storeId: storeIdForSensors
      },
      blockUi: false
    })).unwrap()
    await dispatch(newRelicAddPageActionThunk({
      eventName: 'Sensor Image Upload',
      payload: {
        sensorId,
        storeId: storeIdForSensors
      }
    })).unwrap()
    return sensorImage
  }
)
