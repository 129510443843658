import AppDrawerList from '../../../../../components/AppDrawerList'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { deleteStoreThunk } from '../../../../../thunks/deleteStoreThunk'
import ConditionalRenderByPermission from '../../../../../components/ConditionalRenderByPermission'

StoreButtonDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  storeId: PropTypes.string.isRequired
}

function StoreButtonDrawer ({ storeId, open, setOpen }) {
  const dispatch = useDispatch()
  const deleteStoreOnClick = () => {
    dispatch(deleteStoreThunk({ storeId }))
  }
  return (
    <AppDrawerList
      open={open}
      setOpen={setOpen}
      drawerActionsList={(
        <>
          <ConditionalRenderByPermission permissionType={'deleteStore'}>
            <ListItemButton onClick={deleteStoreOnClick}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>
                Delete Store
              </ListItemText>
            </ListItemButton>
          </ConditionalRenderByPermission>
        </>
      )}
      drawerTitle={`STORE ${storeId}`}
      anchor={'bottom'}
    />
  )
}

export default memo(StoreButtonDrawer)
