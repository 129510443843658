import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { usePermission } from '../../hooks/usePermission'

ConditionalRenderByPermission.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  permissionType: PropTypes.string.isRequired,
  keyword: PropTypes.string
}

function ConditionalRenderByPermission ({ children, permissionType, keyword }) {
  const hasAccess = usePermission(permissionType, keyword)
  return hasAccess ? children : <></>
}

export default memo(ConditionalRenderByPermission)
