import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { updateAllStoresAction, updateAllStoresErrAction } from '../slices/allStores'
import { addLoadingItemAction, dropLoadingItemAction } from '../slices/loading'

export const getAllStoresThunk = createAsyncThunk(
  'allStores/getAllStores',
  async ({ refresh }, { getState, dispatch }) => {
    const currentState = getState()
    const currentErr = currentState.allStores.err
    const currentAllStores = currentState.allStores.data
    if (Object.keys(currentAllStores).length === 0 || refresh) {
      try {
        if (currentErr) {
          dispatch(updateAllStoresErrAction(null))
        }
        dispatch(addLoadingItemAction('gettingStores'))
        const result = await dispatch(fetchBackendAPIThunk({
          method: 'get',
          url: '/store',
          blockUi: false
        })).unwrap()
        const allStores = {}
        for (const store of result) {
          allStores[store.storeId] = store
        }
        dispatch(updateAllStoresAction(allStores))
      } catch (e) {
        console.log(e)
        dispatch(updateAllStoresErrAction('Network failure'))
      } finally {
        dispatch(dropLoadingItemAction('gettingStores'))
      }
    }
  }
)
