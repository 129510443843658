import React, { memo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { usePermission } from '../../hooks/usePermission'

RedirectIfForbidden.propTypes = {
  permissionType: PropTypes.string.isRequired,
  keyword: PropTypes.string
}

function RedirectIfForbidden ({ permissionType, keyword }) {
  const navigate = useNavigate()
  const hasAccess = usePermission(permissionType, keyword)
  useEffect(() => {
    if (!hasAccess) {
      navigate('/snapshot')
    }
  }, [hasAccess])
  return <></>
}
export default memo(RedirectIfForbidden)
