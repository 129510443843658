import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { iamPermissionCheck } from '../../utils/iamPermissionCheck'

export function usePermission (permissionType, keyword) {
  const { storeId } = useParams()
  const iam = useSelector(state => state.global.iam)
  const hasAccess = iam && iam[permissionType] && iamPermissionCheck(iam[permissionType], keyword || storeId)
  return hasAccess
}
