import AppTextField from '../AppTextField'
import { updateSensorInfoFormFieldAction } from '../../slices/sensorInfoForm'
import { useDispatch } from 'react-redux'
import React, { memo, useState } from 'react'

import { Box, IconButton } from '@mui/material'
import CropFreeIcon from '@mui/icons-material/CropFree'
import QrCodeScannerModal from '../../components/QrCodeScannerModal'
import { useReactNativeMessageListener } from '../../hooks/useReactNativeMessageListener'

function SensorIdTextField () {
  const [scannerOpen, setScannerOpen] = useState(false)
  const dispatch = useDispatch()
  const updateAction = (val) => updateSensorInfoFormFieldAction({
    sensorId: val
  })
  const handleScan = async (scanData) => {
    if (scanData && scanData !== '') {
      dispatch(updateAction({
        val: `eui-${scanData.toLowerCase()}`,
        err: false
      }))
      setScannerOpen(false)
    }
  }

  useReactNativeMessageListener((action) => {
    if (action.type === 'SENSOR_INFO') {
      const data = { val: `eui-${action.payload.toLowerCase()}`, err: false }
      dispatch(updateAction(data))
    }
  })

  return (
    <>
      <Box style={{ margin: '10px 0' }}>
        <AppTextField
          updateAction={updateAction}
          label={'Sensor ID'}
          fieldSelector={state => state.sensorInfoForm.sensorId}
          endAdornment={(
            <IconButton
              id='qr-scan-icon'
              onClick={() => setScannerOpen(true)}
            >
              <CropFreeIcon />
            </IconButton>
          )}
        />
        <QrCodeScannerModal handleScan={handleScan} setScannerOpen={setScannerOpen} scannerOpen={scannerOpen} />
      </Box>
    </>
  )
}

export default memo(SensorIdTextField)
