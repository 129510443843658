import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { getSensorsThunk } from './getSensorsThunk'
import { newRelicAddPageActionThunk } from './newRelicAddPageActionThunk'

export const deleteSensorThunk = createAsyncThunk(
  'stores/deleteSensor',
  async ({ sensorId, storeId }, { dispatch, getState }) => {
    const currentState = getState()
    const storeIdForSensors = currentState.sensors.storeIdOfSensors
    await dispatch(await fetchBackendAPIThunk({
      method: 'delete',
      url: 'sensor',
      data: {
        sensorId
      },
      params: {
        storeId: storeId || storeIdForSensors
      }
    })).unwrap()
    await dispatch(newRelicAddPageActionThunk({
      eventName: 'Sensor Deleted',
      payload: {
        sensorId,
        storeId: storeIdForSensors
      }
    })).unwrap()
    await dispatch(getSensorsThunk({})).unwrap()
  }
)
