import { Fab } from '@mui/material'
import React, { memo } from 'react'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearNewStoreAction } from '../../../slices/newStore'
import { usePermission } from '../../../hooks/usePermission'

function AddStoreNavbarButton () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const hasAccess = usePermission('createStore')
  console.log(hasAccess)
  return hasAccess
    ? (
    <Fab
      color={'primary'}
      onClick={() => {
        dispatch(clearNewStoreAction())
        navigate('/snapshot/home/add-store')
      }}
    >
      <AddBusinessIcon/>
    </Fab>
      )
    : (
    <></>
      )
}

export default memo(AddStoreNavbarButton)
