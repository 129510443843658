const {
  REACT_APP_PING_FEDERATE_ENDPOINT,
  REACT_APP_ID,
  REACT_APP_REDIRECT_URI,
  REACT_APP_AUTH_RESPONSE_TYPE,
  REACT_APP_AUTH_SCOPE,
} = process.env;

export function navigateToSsoSignIn() {
  window.location.href = `${REACT_APP_PING_FEDERATE_ENDPOINT}/as/authorization.oauth2?client_id=${REACT_APP_ID}&redirect_uri=${REACT_APP_REDIRECT_URI}&response_type=${REACT_APP_AUTH_RESPONSE_TYPE}&scope=${REACT_APP_AUTH_SCOPE}`
}