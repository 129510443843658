import React, { memo } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { newSensorThunk } from '../../../thunks/newSensorThunk'
import AppSubmitIconButton from '../../../components/AppSubmitIconButton'

function AddSensorNavbarSubmitButton () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <AppSubmitIconButton
      fieldsSelector={state => state.newSensor}
      style={{
        marginLeft: 'auto',
        color: 'inherit'
      }}
      onClick={() => {
        dispatch(newSensorThunk({ navigate }))
      }}
    >
      <DoneIcon />
    </AppSubmitIconButton>
  )
}

export default memo(AddSensorNavbarSubmitButton)
