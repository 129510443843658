import { worker } from "../../../worker";
import { getMainComponentDetails } from "./getMainComponentDetails";

const distinctProductTypeValues = [
  "product_size",
  "product_flavor_type",
  "product_type",
  "product_expressoval",
  "product_milk_type",
  "product_tempval",
  "product_bean_type",
];

export const RenderWorkerDetails = async ({
  componentDetails,
  sIndex,
  cardData,
  lookupDetails,
  pageId,
  storeCoverageType,
  performanceType,
}) => {
  const { id } = componentDetails;
  const { parserName, yAxisMetrics, xAxis, isLegend } = getMainComponentDetails(
    componentDetails,
    pageId
  );

  let reduxKey = id[sIndex];
  if (!parserName) return { [reduxKey]: cardData };
  let data = await worker(parserName, {
    inputKey: reduxKey,
    groupByParams:
      cardData?.[0]?.["sensor_name"]
        ? ["sensor_name", "sensor_data_type", "sensor"]
        : cardData?.[0]?.["sensor_location_category"]
        ? ["sensor_location_category", "sensor_id"]
        : ["device_name", "error_type", "sensor"],
    yAxisMetric: yAxisMetrics ? yAxisMetrics[sIndex] : "",
    xAxisMetric: xAxis
      ? distinctProductTypeValues?.includes(xAxis[sIndex])
        ? "product_type_value"
        : xAxis[sIndex]
      : "",
    data: structuredClone(cardData),
    lookupDetails: lookupDetails,
    isLegend,
    storeCoverageType,
    performanceType,
  });
  return data;
};
