import { ImageListItem, Skeleton } from '@mui/material'
import React from 'react'

function AppImageListItemSkeleton () {
  return (
    <ImageListItem>
      <Skeleton variant={'rectangular'} height={200} />
    </ImageListItem>
  )
}

export default AppImageListItemSkeleton
