import {Box, CircularProgress, ImageListItem, useTheme} from '@mui/material'
import React, {memo, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import SensorImageModal from './SensorImageModal'
import TouchListeningBox from '../TouchListeningBox'
import SensorImageDrawer from './SensorImageDrawer'
import {generateImgDownloadUrl} from '../../utils/generateImgDownloadUrl'
import {useDispatch} from 'react-redux'
import {showSnackbarAction} from '../../slices/snackbar'
import {extractFileNameFromUrl} from '../../utils/extractFileNameFromBlobKey'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import SensorImageListItemFailedDisplay from "./SensorImageListItemFailedDisplay";
import SensorImageListItemUploadProgressBar from "./SensorImageListItemUploadProgressBar";
import {updateAppNavbarFlexibleWrapScrollToAction} from "../../slices/scrolling";

SensorImageListItem.propTypes = {
  sensorImage: PropTypes.object.isRequired
}

function SensorImageListItem({sensorImage}) {
  const [loading, setLoading] = useState(true)
  const [failed, setFailed] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const imageUrl = sensorImage.sensorImageBlobKey ? generateImgDownloadUrl(sensorImage.sensorImageBlobKey) : sensorImage.sensorTempUrl
  const theme = useTheme()
  const dispatch = useDispatch()
  const imageListItemRef = useRef()
  useEffect(() => {
    if (sensorImage.sensorTempUrl) {
      const offsetTop = imageListItemRef.current.offsetTop
      dispatch(updateAppNavbarFlexibleWrapScrollToAction(offsetTop))
    }
  }, [sensorImage.sensorTempUrl])


  return (
    <>
      <TouchListeningBox
        onTap={
          failed
            ? null
            : () => {
              dispatch(showSnackbarAction({
                message: extractFileNameFromUrl(sensorImage.sensorImageBlobKey)
              }))
              setModalOpen(true)
            }
        }
        onTapHold={() => {
          setDrawerOpen(true)
        }}
      >
        <ImageListItem
          ref={imageListItemRef}
          style={{
            height: '200px',
            overflow: 'hidden',
            backgroundColor: failed ? theme.palette.warning.main : theme.palette.primary.main,
            borderRadius: '5px'
          }}
        >
          {loading && (
            <Box style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <CircularProgress style={{
                color: '#fff',
                marginTop: '70px'
              }}/>
            </Box>
          )}
          {failed
            ? (<SensorImageListItemFailedDisplay/>)
            : (
              <>
                <img
                  src={imageUrl} alt={''}
                  onLoad={() => setLoading(false)}
                  onError={() => {
                    setFailed(true)
                    setLoading(false)
                  }}
                  className={'touch-callout-disable'}
                  style={{
                    height: '200px',
                    visibility: loading ? 'hidden' : 'visible',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                />
                {sensorImage.uploadProgress != null && (<SensorImageListItemUploadProgressBar percentage={sensorImage.uploadProgress} />)}
              </>
            )}
        </ImageListItem>
      </TouchListeningBox>
      {sensorImage.sensorImageBlobKey && (
        <>
          <SensorImageModal
            setOpen={setModalOpen}
            open={modalOpen}
            imageUrl={imageUrl}
          />
          <SensorImageDrawer sensorImage={sensorImage} setOpen={setDrawerOpen} open={drawerOpen}/>
        </>
      )}
    </>
  )
}

export default memo(SensorImageListItem)
