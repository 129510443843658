import { createAsyncThunk } from '@reduxjs/toolkit'
import { cleanSensorInfoFormAction } from '../slices/sensorInfoForm'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { getSensorsThunk } from './getSensorsThunk'
import { newRelicAddPageActionThunk } from './newRelicAddPageActionThunk'
import {reboardConfirmThunk} from "./reboardConfirmThunk";
import {showSnackbarAction} from "../slices/snackbar";
import {deleteSensorThunk} from "./deleteSensorThunk";

export const newSensorThunk = createAsyncThunk(
  'newSensor/newSensor',
  async ({ navigate, deleteSensorPermission }, { getState, dispatch }) => {
    const currentState = getState()
    const newSensorForm = currentState.sensorInfoForm
    let storeIdForSensors = currentState.sensors.storeIdOfSensors
    const {
      sensorId,
      sensorType,
      locationCategory,
      locationName,
      sensorName
    } = newSensorForm

    const onboardSensor = async () => {
      return await dispatch(fetchBackendAPIThunk({
        method: 'post',
        url: '/sensor',
        data: {
          sensorId: sensorId.val,
          sensorType: sensorType.val,
          sensorName: sensorName.val,
          locationName: locationName.val,
          locationCategory: locationCategory.val
        },
        params: {
          storeId: storeIdForSensors
        },
        blockUi: true
      })).unwrap()
    }

    const postSensorResult = await onboardSensor()
    if (!postSensorResult.onboarded) {
      if (!deleteSensorPermission) {
        dispatch(showSnackbarAction({
          message: 'Sensor has already onboarded somewhere',
          severity: 'error'
        }))
        return
      }
      const sensorDetail = postSensorResult.sensorDetail
      const shouldReboard = await dispatch(reboardConfirmThunk({
        sensorDetail
      })).unwrap()
      if (!shouldReboard) {
        return
      }
      await dispatch(deleteSensorThunk({
        sensorId: sensorDetail.sensorId,
        storeId: sensorDetail.storeDetail.store_id
      }))
      await onboardSensor()
    }
    dispatch(cleanSensorInfoFormAction())
    await dispatch(getSensorsThunk({})).unwrap()
    await dispatch(newRelicAddPageActionThunk({
      eventName: 'New Sensor Created',
      payload: {
        sensorId: sensorId.val,
        sensorType: sensorType.val,
        sensorName: sensorName.val,
        locationName: locationName.val,
        locationCategory: locationCategory.val,
        storeId: storeIdForSensors
      }
    })).unwrap()
    navigate(-1)
    setTimeout(() => {
      navigate(`/snapshot/home/${storeIdForSensors}/${sensorId.val}`)
    }, 10)
  }
)
