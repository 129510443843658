import React from 'react'
import { Box, LinearProgress } from '@mui/material'
import { useSelector } from 'react-redux'

function LoadingBar () {
  const loadingItems = useSelector(state => state.loading.loadingItems)
  return loadingItems.length > 0
    ? (
      <Box style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        left: 0,
        zIndex: 20
      }}>
        <LinearProgress
          color={'secondary'}
          style={{
            height: '10px'
          }}
        />
      </Box>
      )
    : <></>
}

export default LoadingBar
