import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {newRelicNoticeError} from "../../../utlities/newrelic/newRelicNoticeError";
import getTokenDispatch from "../../../utlities/rest/getTokenDispatch";

export const fetchAPIThunk = createAsyncThunk(
  'insight/fetchAPI',
  async ({ config }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios(config)
      return response.data
    } catch (e) {
      // if (!e.response) {
      //   dispatch(showSnackbarAction({
      //     severity: 'error',
      //     message: 'Network error'
      //   }))
      // }
      const {sessionId, email} = await getTokenDispatch(dispatch);
      newRelicNoticeError(e, sessionId, email);
      return rejectWithValue(e)
    }
  }
)
