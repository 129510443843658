import React, { memo } from 'react'
import { Divider, ListItemButton, ListItemIcon, ListItemText, Skeleton } from '@mui/material'

function StoreButtonsSkeleton () {
  const fragment = []
  for (let i = 0; i < 5; i++) {
    fragment.push(
      <>
        <ListItemButton>
          <ListItemIcon>
            <Skeleton variant="circular" height={35} width={35}/>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Skeleton variant="text"/>
            )}
            secondary={(
              <Skeleton variant="text"/>
            )}
          />
        </ListItemButton>
        <Divider />
      </>
    )
  }
  return fragment
}

export default memo(StoreButtonsSkeleton)
