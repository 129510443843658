import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addRunOpsLoadingItem, removeRunOpsLoadingItem } from "./loadingSlice";
import RunOpsDataTemp from "../../Temp/RunOpsDataTemp";
import continuousGetDataRequest from "../../utlities/rest/continuousGetDataRequest";
import RestClient from "../../api/RestClient";
import Request from "../../request/Request";
import { worker } from "../../worker";
import { showSnackBar } from "../globalSlice";

const runOpsRestDataTemp = RunOpsDataTemp.getInst();

const initState = {
  isError: false,
  cardData: [],
};

export const loadConnectivityData = createAsyncThunk(
  //loadCategoryData - loadCategoryData(pageId)
  "connectivity/loadConnectivityData",
  async (pageId, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    //thunkAPI is the second argument
    const request = Request.getRunOpsConnectivityData();
    const state = getState();

    dispatch(addRunOpsLoadingItem("connectivityData"));
    let parsedResult = runOpsRestDataTemp.get({
      requestCalls: JSON.stringify(request),
      newRelicApiName: "connectivityData",
    });

    if (parsedResult) {
      dispatch(removeRunOpsLoadingItem("connectivityData"));
      return fulfillWithValue({
        connectivityData: parsedResult?.connectivityData,
        error: parsedResult.error,
      });
    }

    const res = await continuousGetDataRequest(
      RestClient.getConnectivityData,
      request,
      dispatch
    );
    // const res = state?.runOpsData?.pages?.connectivity?.cardData
    if (res) {
      const connectivityData = await worker("processRunOpsData", res);
      runOpsRestDataTemp.set(
        {
          requestCalls: JSON.stringify(request),
          newRelicApiName: "connectivityData",
        },
        { connectivityData: connectivityData, error: false }
      );
      dispatch(removeRunOpsLoadingItem("connectivityData"));
      return fulfillWithValue({
        connectivityData: connectivityData,
        error: false,
      });
    } else {
      runOpsRestDataTemp.set(
        {
          requestCalls: JSON.stringify(request),
          newRelicApiName: "connectivityData",
        },
        { connectivityData: [], error: true }
      );
      return rejectWithValue();
    }
  }
);

export const addConnectivityData = createAsyncThunk(
  //addConnectivityData({id: 1})
  "connectivity/addConnectivityData",
  async (
    actionPayload,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    const addRequest = Request.addConnectivity(actionPayload);
    const state = getState();

    const res = await continuousGetDataRequest(
      RestClient[`create${actionPayload?.type}`],
      addRequest,
      dispatch,
      true
    );
    if (res) {
      dispatch(showSnackBar({
        severity: "success", 
        message: `Your ${actionPayload?.type} has been added`,
        vertical: "top",
        horizontal: "center"
      }))
      runOpsRestDataTemp.clear();
      dispatch(loadConnectivityData(""));
    } else {
      return rejectWithValue();
    }
  }
);

export const editConnectivityData = createAsyncThunk(
  //editConnectivityData({id: 1})
  "connectivity/editConnectivityData",
  async (
    actionPayload,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    const updateRequest = Request.updateConnectivity(actionPayload);
    const state = getState();

    const res = await continuousGetDataRequest(
      RestClient[`update${actionPayload?.type}`],
      updateRequest,
      dispatch,
      true
    );
    // const res = "success"
    if (res) {
      dispatch(showSnackBar({
        severity: "success", 
        message: `Your ${actionPayload?.type} has been added`,
        vertical: "top",
        horizontal: "center"
      }))
      runOpsRestDataTemp.clear();
      dispatch(loadConnectivityData(""));
    } else {
      return rejectWithValue();
    }
  }
);

export const deleteConnectivityData = createAsyncThunk(
  //deleteConnectivityData({id: 1})
  "connectivity/deleteConnectivityData",
  async (
    actionPayload,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    const deleteRequest = Request.deleteConnectivity(actionPayload);
    const state = getState();

    const res = await continuousGetDataRequest(
      RestClient[`delete${actionPayload?.type}`],
      deleteRequest,
      dispatch,
      true
    );
    // const res = "success"
    if (res) {
      dispatch(showSnackBar({
        severity: "success", 
        message: `Your ${actionPayload?.type} has been deleted`,
        vertical: "top",
        horizontal: "center"
      }))
      runOpsRestDataTemp.clear();
      dispatch(loadConnectivityData(""));
    } else {
      return rejectWithValue();
    }
  }
);

const connectivitySlice = createSlice({
  name: "connectivity",
  initialState: initState,
  reducers: {
    updateConnectivityData: (state, action) => {
      //UPDATE_CONNECTIVITY_DATA
      state[action.payload?.key] = action.payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadConnectivityData.fulfilled, (state, action) => {
      state.cardData = action.payload?.connectivityData;
      state.isError = action.payload?.error;
    });
    builder.addCase(loadConnectivityData.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export const { updateConnectivityData } = connectivitySlice.actions;
export default connectivitySlice.reducer;
