import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export function useAllSensorNameSetInStore (exceptCurrent = true) {
  const sensors = useSelector(state => state.sensors.data)
  const { sensorId } = useParams()
  const sensorNameSet = new Set()
  for (const sensorIdInSensors in sensors) {
    if (exceptCurrent && sensorId) {
      if (sensorId === sensorIdInSensors) {
        continue
      }
    }
    const sensorName = sensors[sensorIdInSensors].sensorName
    sensorNameSet.add(sensorName)
  }
  return sensorNameSet
}
