import { Box } from '@mui/material'
import React, { memo } from 'react'
import AppVersionDisplay from '../../components/AppVersionDisplay'
import sevenElevenImg from "../../../../assets/images/7-eleven-logo.png";
import ExtraLargeButton from "../../../../components/iotDashboard/ExtraLargeButton";
import {asyncSleep} from "../../utils/asyncSleep";
import {navigateToSsoSignIn} from "../../utils/navigateToSsoSignIn";

function SignInPage () {
  const signInOnClick = async () => {
    sessionStorage.setItem(
      "signInFor",
      JSON.stringify('snapshot')
    );
    await asyncSleep(100)
    navigateToSsoSignIn()
  }

  return (
    <Box style={{
      margin: '0',
      position: 'absolute',
      width: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Box style={{
        width: '327px'
      }}>
        <Box style={{
          height: '269px',
          background: '#fff',
          border: '1px #d8d8d8 solid',
        }}>
          <Box style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '60px'
          }}>
            <img
              style={{
                width: '60%'
              }}
              src={sevenElevenImg}
              alt=''
            />
          </Box>
          <Box style={{
            color: '#107f62',
            fontSize: '22px',
            textAlign: 'center',
            marginTop: '12px'
          }}>
            IoT Solutions
          </Box>
          <Box style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px'
          }}>
            <ExtraLargeButton onClick={signInOnClick}>
              Login
            </ExtraLargeButton>
          </Box>
        </Box>
        <Box style={{
          color: '#a8a8a8',
          fontSize: '14px',
          marginTop: '12px',
          textAlign: 'center'
        }}>
          <AppVersionDisplay />
        </Box>
      </Box>
    </Box>
  )
}

export default memo(SignInPage)
