import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchTfamApiThunk} from "./fetchTfamApiThunk";
import {updateGlobalAction} from "../slices/global";

const {REACT_APP_REDIRECT_URI} = process.env

export const fetchSsoLoginTokenThunk = createAsyncThunk(
  'util/fetchSsoLoginToken',
  async ({code}, {getState, dispatch}) => {
    const result = await dispatch(fetchTfamApiThunk({
      method: 'POST',
      url: '/as/token.oauth2',
      params: {
        grant_type: 'authorization_code',
        redirect_uri: REACT_APP_REDIRECT_URI,
        code
      }
    })).unwrap()
    dispatch(updateGlobalAction({
      ssoTokens: {
        accessToken: result.access_token,
        refreshToken: result.refresh_token,
        idToken: result.id_token
      }
    }))
  }
)