import React, { memo } from 'react'
import { addSensorNavbarWrap } from '../../wraps/addSensorNavbarWrap'
import RedirectIfForbidden from '../../components/RedirectIfForbidden'
import { useNavigate, useParams } from 'react-router-dom'
import { loadStoreDataWrap } from '../../wraps/loadStoreDataWrap'
import SensorForm from '../../components/SensorForm'
import { useDispatch } from 'react-redux'
import { newSensorThunk } from '../../thunks/newSensorThunk'
import {usePermission} from "../../hooks/usePermission";

function AddSensorPage () {
  const { storeId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const deleteSensorPermission = usePermission('deleteSensor')
  const onSubmit = () => {
    dispatch(newSensorThunk({ navigate, deleteSensorPermission }))
  }
  return (
    <>
      <RedirectIfForbidden permissionType={'createSensor'} keyword={storeId} />
      <SensorForm onSubmit={onSubmit} />
    </>
  )
}
export default loadStoreDataWrap(addSensorNavbarWrap(memo(AddSensorPage)))
