import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {addRunOpsLoadingItem, removeRunOpsLoadingItem} from "./loadingSlice";
import RunOpsDataTemp from "../../Temp/RunOpsDataTemp";
import continuousGetDataRequest from "../../utlities/rest/continuousGetDataRequest";
import RestClient from "../../api/RestClient";
import Request from "../../request/Request";
import {worker} from "../../worker";
import { showSnackBar } from "../globalSlice";

const runOpsRestDataTemp = RunOpsDataTemp.getInst()

const initState = {
    isError: false,
    cardData: []
}

export const loadQueueData = createAsyncThunk( //loadCategoryData - loadCategoryData(pageId)
  'queue/loadQueueData',
  async (pageId, { rejectWithValue, fulfillWithValue, getState, dispatch }) => { //thunkAPI is the second argument
    const request = Request.getRunOpsQueueData()
    const state = getState();

    dispatch(addRunOpsLoadingItem("queueData"))
    let parsedResult = runOpsRestDataTemp.get({
      requestCalls: JSON.stringify(request),
      newRelicApiName: "queueData"
    });

    if (parsedResult) {
        dispatch(removeRunOpsLoadingItem("queueData"))
        return fulfillWithValue({queueData: parsedResult?.queueData, error: parsedResult.error})
    }

    const res = await continuousGetDataRequest(RestClient.getQueueData, request, dispatch, true)
    // const res = state?.runOpsData?.pages?.queue?.cardData
    if(res) {
      const queueData = await worker('processQueueData', res)
      runOpsRestDataTemp.set(
          {
            requestCalls: JSON.stringify(request),
            newRelicApiName: "queueData",
          },
          { queueData: queueData, error: false }
      );
      dispatch(removeRunOpsLoadingItem("queueData"))
      return fulfillWithValue({queueData: queueData, error: false})
    } else {
      // runOpsRestDataTemp.set(
      //     {
      //       requestCalls: JSON.stringify(request),
      //       newRelicApiName: "queueData",
      //     },
      //     { queueData: [], error: true }
      // );
      return rejectWithValue()
    }
  }
)

export const editQueueData = createAsyncThunk(
  //editQueueData({userName: ''})
  "queue/editQueueData",
  async (
    actionPayload,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    //thunkAPI is the second argument
    const updateRequest = Request.updateAccessRequest(actionPayload);
    const state = getState();

    const res = await continuousGetDataRequest(
      RestClient.updateAccessRequest,
      updateRequest,
      dispatch,
      true
    );
    // const res = "success"
    if (res) {
      dispatch(showSnackBar({
        severity: "success", 
        message: "User role has been updated. Request complete.",
        vertical: "top",
        horizontal: "center"
      }))
      runOpsRestDataTemp.clear();
      dispatch(loadQueueData(""));
    } else {
      return rejectWithValue();
    }
  }
);

export const setAccessRequest = createAsyncThunk(
    //setAccessRequest - setAccessRequest({status, accessRequestId, cb})
    "setting/setAccessRequest",
    async (actionPayload, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
      //thunkAPI is the second argument
      const state = getState()
      const {sessionId, csrfToken, email} = state?.authData;
      const request = Request.updateAccessRequest(actionPayload, email);
      try {
        await RestClient.updateAccessRequest(sessionId, csrfToken, request)
        actionPayload?.cb("success")
      } catch (e) {
        actionPayload?.cb("fail")
      }
    }
);

const queueSlice = createSlice({
    name:'queue',
    initialState: initState,
    reducers: {
        updateQueueData: (state, action) => { //UPDATE_QUEUE_DATA
          state[action.payload?.key] = action.payload?.data
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(loadQueueData.fulfilled, (state, action) => {  
            state.cardData = action.payload?.queueData
            state.isError = action.payload?.error   
        })
        builder
        .addCase(loadQueueData.rejected, (state, action) => {  
            state.isError = true  
        })
        builder
        .addCase(editQueueData.fulfilled, (state, action) => {  
            // state.cardData = action.payload;
        })
    }
})

export const { updateQueueData } = queueSlice.actions;
export default queueSlice.reducer;