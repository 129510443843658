export const sensorTypes = {
  CO2: 'CO2',
  DOOR: 'Door',
  TEMP: 'Temp'
}

export const appVersion = '1.0.1'

export const pathsWithoutAuth = [
  '/error',
  '/sso-login-handle'
]