import React from "react";
import PropTypes from "prop-types";
import {Box} from "@mui/material";

HelpImg.propTypes = {
  src: PropTypes.string.isRequired
}

function HelpImg({src}) {
  return (
    <Box style={{
      padding: '5px'
    }}>
      <img
        width={'90%'}
        src={src}
        alt={''}
      />
    </Box>
  )
}

export default HelpImg