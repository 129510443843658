import { Box } from '@mui/material'
import React, { memo } from 'react'
import { addStoreNavbarWrap } from '../../wraps/addStoreNavbarWrap'
import FormHeader from '../../components/FormHeader'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory'
import AppTextField from '../../components/AppTextField'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { updateNewStoreFieldAction } from '../../slices/newStore'
import { useNavigate } from 'react-router-dom'
import RedirectIfForbidden from '../../components/RedirectIfForbidden'
import AppSubmitButton from '../../components/AppSubmitButton'
import { loadStoreDataWrap } from '../../wraps/loadStoreDataWrap'
import { newStoreThunk } from '../../thunks/newStoreThunk'

function AddStorePage () {
  const storeId = useSelector(state => state.newStore.storeId)
  const stores = useSelector(state => state.allStores.data)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const updateAction = (val) => updateNewStoreFieldAction({
    storeId: val
  })
  let hasStore = false
  if (storeId.val in stores) {
    hasStore = true
  }
  const IconDisplay = hasStore ? StoreMallDirectoryIcon : AddBusinessIcon
  const buttonOnClick = async () => {
    await dispatch(newStoreThunk()).unwrap()
    navigate(-1)
    setTimeout(() => navigate(`/snapshot/home/${storeId.val}`), 100)
  }
  return (
    <Box style={{
      padding: '0 10px'
    }}>
      <RedirectIfForbidden permissionType={'createStore'} />
      <FormHeader>
        <IconDisplay
          style={{
            fontSize: '50px'
          }}
          color={hasStore ? 'info' : 'primary'}
        />
      </FormHeader>
      <Box style={{
        marginTop: '10px',
        display: 'flex',
        gap: '5px'
      }}>
        <AppTextField
          updateAction={updateAction}
          autoFocus={true}
          label={'Store ID'}
          validator={val => {
            if (!(/^\d+$/.test(val))) {
              return 'Store ID should be only number'
            }
          }}
          fieldSelector={state => state.newStore.storeId}
        />
        <AppSubmitButton
          fieldsSelector={state => state.newStore}
          variant={'contained'}
          color={hasStore ? 'info' : 'primary'}
          size={'large'}
          onClick={buttonOnClick}
          style={{
            height: '55px'
          }}
        >
          {hasStore ? <ArrowForwardIcon /> : <AddIcon />}
        </AppSubmitButton>
      </Box>
    </Box>
  )
}

export default loadStoreDataWrap(addStoreNavbarWrap(memo(AddStorePage)))
