import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAPIThunk } from './fetchApiThunk'
import {logoutThunk} from "./logoutThunk";

const baseURL = process.env.REACT_APP_API_URL

export const fetchBackendAPIThunk = createAsyncThunk(
  'util/fetchBackendAPI',
  async ({ method, url, params, data }, { getState, dispatch, rejectWithValue }) => {
    const state = getState()
    const authToken = state.authData.sessionId
    const csrfToken = state.authData.csrfToken
    let result
    try {
      result = await dispatch(fetchAPIThunk({
        config: {
          method,
          url,
          baseURL,
          params,
          data,
          headers: {
            'x-auth-token': authToken,
            'x-csrf-token': csrfToken
          }
        }
      })).unwrap()
    } catch (e) {
      if (!e.response) {
        return rejectWithValue(e)
      }
      switch (e.response.status) {
        case 401: {
          dispatch(logoutThunk())
          break
        }
        default: {
          console.log('unknown error')
          throw e
        }
      }
    }
    return result
  }
)
