import {Box, CircularProgress, ImageListItem, useTheme} from '@mui/material'
import React, {memo, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import SensorImageModal from './DevicemageModal'
import TouchListeningBox from '../TouchListeningBox'
import SensorImageDrawer from './DeviceImageDrawer'
import {generateImgDownloadUrl} from '../../utils/generateImgDownloadUrl'
import {useDispatch} from 'react-redux'
import {showSnackbarAction} from '../../slices/snackbar'
import {extractFileNameFromUrl} from '../../utils/extractFileNameFromBlobKey'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import SensorImageListItemFailedDisplay from "./DeviceImageListItemFailedDisplay";
import SensorImageListItemUploadProgressBar from "./DeviceImageListItemUploadProgressBar";
import {updateAppNavbarFlexibleWrapScrollToAction} from "../../slices/scrolling";

DeviceImageListItem.propTypes = {
  deviceImage: PropTypes.object.isRequired
}

function DeviceImageListItem({deviceImage}) {
  const [loading, setLoading] = useState(true)
  const [failed, setFailed] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const imageUrl = deviceImage.deviceImageBlobKey ? generateImgDownloadUrl(deviceImage.deviceImageBlobKey) : deviceImage.sensorTempUrl
  const theme = useTheme()
  const dispatch = useDispatch()
  const imageListItemRef = useRef()
  // useEffect(() => {
  //   if (deviceImage.sensorTempUrl) {
  //     const offsetTop = imageListItemRef.current.offsetTop
  //     dispatch(updateAppNavbarFlexibleWrapScrollToAction(offsetTop))
  //   }
  // }, [deviceImage.sensorTempUrl])


  return (
    <>
      <TouchListeningBox
        // onTap={
        //   failed
        //     ? null
        //     : () => {
        //       setModalOpen(true)
        //     }
        // }
        // onTapHold={() => {
        //   setDrawerOpen(true)
        // }}
      >
        <ImageListItem
          ref={imageListItemRef}
          style={{
            height: '200px',
            overflow: 'hidden',
            backgroundColor: failed ? theme.palette.warning.main : theme.palette.primary.main,
            borderRadius: '5px'
          }}
        >
          {loading && (
            <Box style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <CircularProgress style={{
                color: '#fff',
                marginTop: '70px'
              }}/>
            </Box>
          )}
          {failed
            ? (<SensorImageListItemFailedDisplay/>)
            : (
              <>
                <img
                  src={imageUrl} alt={''}
                  onLoad={() => setLoading(false)}
                  onError={() => {
                    setFailed(true)
                    setLoading(false)
                  }}
                  className={'touch-callout-disable'}
                  style={{
                    height: '200px',
                    visibility: loading ? 'hidden' : 'visible',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                />
                {deviceImage.uploadProgress != null && (<SensorImageListItemUploadProgressBar percentage={deviceImage.uploadProgress} />)}
              </>
            )}
        </ImageListItem>
      </TouchListeningBox>
      {/*{deviceImage.sensorImageBlobKey && (*/}
      {/*  <>*/}
      {/*    <SensorImageModal*/}
      {/*      setOpen={setModalOpen}*/}
      {/*      open={modalOpen}*/}
      {/*      imageUrl={imageUrl}*/}
      {/*    />*/}
      {/*    <SensorImageDrawer sensorImage={deviceImage} setOpen={setDrawerOpen} open={drawerOpen}/>*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  )
}

export default memo(DeviceImageListItem)
