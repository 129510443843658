import React, { memo } from 'react'
import {
  clearStoreSearchTextAction,
  toggleStoreSearchOpenAction,
  updateStoreSearchTextAction
} from '../../../slices/storeSearch'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import SearchOffIcon from '@mui/icons-material/SearchOff'

function StoreSearchToggleButton () {
  const dispatch = useDispatch()
  const searchOpen = useSelector(state => state.storeSearch.open)
  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{
        marginLeft: 0
      }}
      onClick={() => {
        dispatch(clearStoreSearchTextAction())
        dispatch(toggleStoreSearchOpenAction())
        dispatch(updateStoreSearchTextAction({
          focus: true
        }))
      }}
    >
      {searchOpen
        ? (
        <SearchOffIcon />
          )
        : (
        <SearchIcon/>
          )}
    </IconButton>
  )
}

export default memo(StoreSearchToggleButton)
