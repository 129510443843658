import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, IconButton, Modal, Paper, Typography } from '@mui/material'
import React, { memo, useState } from 'react'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import HelpSearch from './HelpSearch'
import CloseIcon from '@mui/icons-material/Close'
import HelpSections from './HelpSections'

function HelpModal () {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <Box>
      <IconButton onClick={handleOpen} color={'primary'}>
        <HelpOutlineIcon style={{
          fontSize: '18px'
        }}/>
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={3}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '90%',
            height: '90%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            overflowY: 'scroll'
          }}>
          <Box style={{
            display: 'flex',
            justifyContent: 'right',
            paddingTop: '5px',
            paddingRight: '5px'
          }}>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box style={{
            margin: '0 20px 20px 20px'
          }}>
            <Box style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <HelpCenterIcon
                color={'primary'}
                style={{
                  fontSize: '50px',
                  marginTop: '10px'
                }}
              />
            </Box>
            <Typography
              style={{
                textAlign: 'center',
                fontSize: '20px'
              }}
            >
              {"We're here to help"}
            </Typography>
            <HelpSearch />
            <HelpSections />
          </Box>
        </Paper>
      </Modal>
    </Box>
  )
}

export default memo(HelpModal)
