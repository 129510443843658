import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { getAllStoresThunk } from './getAllStoresThunk'

export const newStoreThunk = createAsyncThunk(
  'newStore/newStore',
  async (arg, { getState, dispatch }) => {
    const currentState = getState()
    const newStoreId = currentState.newStore.storeId.val
    await dispatch(fetchBackendAPIThunk({
      method: 'post',
      url: '/store',
      params: {
        storeId: newStoreId
      },
      blockUi: true
    })).unwrap()
    await dispatch(getAllStoresThunk({ refresh: true })).unwrap()
  }
)
