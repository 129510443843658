import { Box } from '@mui/material'
import React, { memo } from 'react'
import StoreButtonList from './StoreButtonList'
import { homeNavbarWrap } from '../../wraps/homeNavbarWrap'
import { loadStoreDataWrap } from '../../wraps/loadStoreDataWrap'
import StoreSearchBar from './StoreSearchBar'

function HomePage () {
  return (
    <>
      <Box>
        <StoreButtonList/>
      </Box>
      <StoreSearchBar />
    </>
  )
}
export default loadStoreDataWrap(homeNavbarWrap(memo(HomePage)))
