import { containerClient } from '../getAzureBlobContainerClient'

export const uploadImgToBlob = async (blobUrl, file, onProgress = (percentage) => {}) => {
  const blockBlobClient = containerClient.getBlockBlobClient(blobUrl)
  await blockBlobClient.upload(file, file.size, {
    onProgress: progress => {
      const uploaded = progress.loadedBytes
      onProgress(Math.ceil((uploaded / file.size) * 100))
    }
  })
  return blockBlobClient.url
}
