import ErrorIcon from "@mui/icons-material/Error";
import {Box} from "@mui/material";
import React from "react";

function SensorImageListItemFailedDisplay() {
  return (
    <Box style={{
      display: 'flex',
      justifyContent: 'center'
    }}>
      <ErrorIcon style={{
        color: '#fff',
        fontSize: '30px',
        marginTop: '80px'
      }}/>
    </Box>
  )
}

export default SensorImageListItemFailedDisplay