import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { updateSensorsAction, updateSensorsErrAction } from '../slices/sensors'
import { addLoadingItemAction, dropLoadingItemAction } from '../slices/loading'

export const getSensorsThunk = createAsyncThunk(
  'sensors/getSensors',
  async ({ storeId }, { getState, dispatch }) => {
    try {
      const currentState = getState()
      let storeIdForSensors = currentState.sensors.storeIdOfSensors
      const sensorErr = currentState.sensors.err
      if (storeId) {
        if (Number(storeIdForSensors) === Number(storeId)) {
          return
        }
        storeIdForSensors = storeId
      }
      if (sensorErr) {
        dispatch(updateSensorsErrAction(null))
      }
      dispatch(addLoadingItemAction('gettingSensors'))
      const result = await dispatch(fetchBackendAPIThunk({
        method: 'get',
        url: '/sensor',
        params: {
          storeId: Number(storeIdForSensors)
        },
        blockUi: false
      })).unwrap()
      const allSensor = {}
      for (const sensor of result) {
        allSensor[sensor.sensorId] = sensor
      }
      dispatch(updateSensorsAction({
        data: allSensor,
        storeId: Number(storeIdForSensors)
      }))
    } catch (e) {
      console.log(e)
      dispatch(updateSensorsErrAction('Network failure'))
    } finally {
      dispatch(dropLoadingItemAction('gettingSensors'))
    }
  }
)
