import { appNavbarWrap } from '../appNavbarWrap'
import React, { memo } from 'react'
import { Toolbar } from '@mui/material'
import NavBackButton from '../../components/NavBackButton'

export const addStoreNavbarWrap = (Component) => {
  return appNavbarWrap(Component, memo(
    function Wrapped () {
      return (
        <Toolbar style={{
          justifyContent: 'space-between'
        }}>
          <NavBackButton/>
        </Toolbar>
      )
    }
  ))
}
