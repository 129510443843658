import { createAsyncThunk } from '@reduxjs/toolkit'
import { cleanSensorInfoFormAction } from '../slices/sensorInfoForm'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { getSensorsThunk } from './getSensorsThunk'
import { newRelicAddPageActionThunk } from './newRelicAddPageActionThunk'
import {reboardConfirmThunk} from "./reboardConfirmThunk";
import {showSnackbarAction} from "../slices/snackbar";
import {deleteSensorThunk} from "./deleteSensorThunk";
import {getDevicesThunk} from "./getDevicesThunk";

export const newDeviceThunk = createAsyncThunk(
  'newSensor/newSensor',
  async ({ navigate, storeId }, { getState, dispatch }) => {
    const currentState = getState()
    const deviceType = currentState.addDeviceForm.deviceType.val

    const newDevice = await dispatch(fetchBackendAPIThunk({
      method: 'post',
      url: '/device',
      data: {
        deviceType
      },
      params: {
        storeId: storeId
      },
      blockUi: true
    })).unwrap()
    await dispatch(getDevicesThunk({refresh: true, storeId})).unwrap()
    navigate(-1)
    setTimeout(() => {
      navigate(`/snapshot/home/${storeId}/device/${newDevice.deviceId}`)
    }, 10)
  }
)
