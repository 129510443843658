import {createAsyncThunk} from "@reduxjs/toolkit";
import {updateConfirmAction} from "../slices/confirm";

export const confirmThunk = createAsyncThunk(
  'appConfirmEvent/confirmTHunk',
  ({ content}, {dispatch, getState}) => new Promise(resolve => {
    dispatch(updateConfirmAction({
      content,
      open: true,
    }))
    const appConfirmEventListener = evt => {
      document.removeEventListener('appConfirm', appConfirmEventListener)
      resolve(evt.detail.yesOrNo)
    }
    document.addEventListener('appConfirm', appConfirmEventListener)
  })
)