import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  text: {
    val: '',
    focus: false
  },
  open: false
}

const storeSearch = createSlice({
  name: 'storeSearch',
  initialState,
  reducers: {
    updateStoreSearchTextAction: (state, action) => {
      if (state.text.focus === false && action.payload.focus === true) {
        setTimeout(() => {
          window.scroll({
            top: 0,
            behavior: 'smooth'
          })
        }, 100)
      }
      return {
        ...state,
        text: {
          ...state.text,
          ...action.payload
        }
      }
    },
    clearStoreSearchTextAction: (state) => {
      state.text = initialState.text
    },
    toggleStoreSearchOpenAction: (state) => {
      state.open = !state.open
    }
  }
})

export const { updateStoreSearchTextAction, toggleStoreSearchOpenAction, clearStoreSearchTextAction } = storeSearch.actions
export const storeSearchReducer = storeSearch.reducer
