import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  username: {
    val: '',
    err: false,
    required: true
  },
  password: {
    val: '',
    err: false,
    required: true
  }
}

const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    updateSignInUserNameAction: (state, action) => {
      state.username = action.payload
    },
    updateSignInPasswordAction: (state, action) => {
      state.password = action.payload
    },
    clearSignInUsernamePasswordAction: () => {
      return initialState
    }
  }
})

export const signInReducer = signInSlice.reducer

export const { updateSignInUserNameAction, updateSignInPasswordAction, clearSignInUsernamePasswordAction } = signInSlice.actions
