import { createTheme, responsiveFontSizes } from '@mui/material'

let _theme = createTheme({
  palette: {
    primary: {
      dark: '#0c5e49',
      main: '#107f62',
      light: '#5da694',
      contrastText: '#fff'
    },
    secondary: {
      dark: '#b65f18',
      main: '#F4811F',
      light: '#f6a367'
    }
  },
  typography: {
    h5: {
      color: '#107f62'
    }
  }
})
_theme = responsiveFontSizes(_theme)

export const theme = _theme
