import { Box, Fab } from '@mui/material'
import CollectionsIcon from '@mui/icons-material/Collections'
import React from 'react'
import { useDispatch } from 'react-redux'
import { scanQrFileOnlineThunk } from '../../../thunks/scanQrFileOnlineThunk'
import PropTypes from 'prop-types'
import { showSnackbarAction } from '../../../slices/snackbar'
import { startHtml5QrCode } from '../startHtml5QrCode'

QrCodeScannerFromImage.propTypes = {
  handleScan: PropTypes.func.isRequired,
  html5QrCode: PropTypes.object.isRequired
}

function QrCodeScannerFromImage ({ handleScan, html5QrCode }) {
  const dispatch = useDispatch()
  const onFileScan = async (event) => {
    const imageFile = event.target.files[0]
    await html5QrCode.stop()
    const result = await dispatch(scanQrFileOnlineThunk({ imageFile })).unwrap()
    if (result) {
      await html5QrCode.clear()
      handleScan(result)
    } else {
      dispatch(showSnackbarAction({
        severity: 'warning',
        message: 'QR code not found'
      }))
      await startHtml5QrCode(html5QrCode, handleScan)
    }
  }
  return (
    <Box style={{
      position: 'absolute',
      bottom: '10%',
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    }}>
      <Fab component={'label'} color={'primary'}>
        <CollectionsIcon />
        <input hidden accept='image/*' type='file' onChange={onFileScan} />
      </Fab>
    </Box>
  )
}

export default QrCodeScannerFromImage
