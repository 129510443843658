import { Box, List, ListSubheader } from '@mui/material'
import React, { memo } from 'react'
import LocationListItem from './LocationListItem'
import SensorInStoreList from './SensorInStoreList'
import { useSelector } from 'react-redux'
import HelpModal from '../../../components/HelpModal'
import { useLoading } from '../../../hooks/useLoading'
import AppListSubheaderSkeleton from '../../../components/AppListSubheaderSkeleton'
import LocationSensorSkeleton from './LocationSensorSkeleton'

function LocationSensorInStoreList () {
  const sensors = useSelector(state => state.sensors.data)
  const numOfSensor = Object.keys(sensors).length
  let numOfSensorInactive = 0
  const sensorsLoading = useLoading('gettingSensors')
  const locationSensorMap = {}
  for (const sensorId in sensors) {
    const sensor = sensors[sensorId]
    if (!sensor.isActive) {
      numOfSensorInactive += 1
    }
    if (sensor.locationName in locationSensorMap) {
      locationSensorMap[sensor.locationName].push(sensor)
    } else {
      locationSensorMap[sensor.locationName] = [sensor]
    }
  }
  return (
    <List
      aria-labelledby="location-sensor-list-subheader"
      subheader={
        <ListSubheader
          id='store-list'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'static',
          }}
        >
          {sensorsLoading
            ? (
              <AppListSubheaderSkeleton/>
              )
            : (
              <Box>
                {numOfSensor === 0
                  ? 'You can add sensor by clicking the button'
                  : `${numOfSensor} sensors found • ${numOfSensorInactive} sensors inactive`}
              </Box>
              )}
          <HelpModal/>
        </ListSubheader>
      }
    >
      {sensorsLoading
        ? (<LocationSensorSkeleton/>)
        : Object.keys(locationSensorMap).map(locationName => (
          <>
            <LocationListItem locationName={locationName}
                              locationCategory={locationSensorMap[locationName][0].locationCategory}/>
            <SensorInStoreList sensors={locationSensorMap[locationName]}/>
          </>
        ))}
    </List>
  )
}

export default memo(LocationSensorInStoreList)
