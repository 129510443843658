import {refreshTokenCall} from "../../reducers/authSlice";

function refreshToken(dispatch, errMsg, isUserInfo) {
  return new Promise((resolve, reject) => {
    dispatch(refreshTokenCall({
      errorMessage: isUserInfo ? { message: "Token Expired" } : errMsg,
      isUserInfo,
      cb: (result) => {
        switch (result) {
          case 'logout': {
            reject('Login expired')
            break
          }
          case 'recall': {
            resolve()
            break
          }
          default:
            break
        }
      }
    }))
  })
}

export default refreshToken