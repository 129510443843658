import { appNavbarWrap } from '../appNavbarWrap'
import React, { memo } from 'react'
import { Toolbar, Typography } from '@mui/material'
import StoreIcon from '@mui/icons-material/Store'
import { useParams } from 'react-router-dom'
import NavBackButton from '../../components/NavBackButton'
import StoreNavbarAddSensorButton from './StoreNavbarAddSensorButton'
import UserDrawer from '../../components/UserDrawer'
import ConditionalRenderByPermission from '../../components/ConditionalRenderByPermission'

export const storeNavbarWrap = (Component) => {
  return appNavbarWrap(Component, memo(
    function Wrapped () {
      const { storeId } = useParams()
      return (
        <Toolbar style={{
          justifyContent: 'space-between'
        }}>
          <NavBackButton/>

          <StoreIcon />
          <Typography style={{
            marginTop: '2px',
            marginLeft: '10px'
          }}>
            # {storeId}
          </Typography>
          <UserDrawer />
        </Toolbar>
      )
    }
  ), memo(
    function BottomNav () {
      return (
        <ConditionalRenderByPermission permissionType={'createSensor'}>
          <StoreNavbarAddSensorButton />
        </ConditionalRenderByPermission>
      )
    }
  ))
}
