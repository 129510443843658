import {createAsyncThunk} from "@reduxjs/toolkit";
import {updateSensorsAction, updateSensorsErrAction} from "../slices/sensors";
import {addLoadingItemAction, dropLoadingItemAction} from "../slices/loading";
import {fetchBackendAPIThunk} from "./fetchBackendApiThunk";
import {updateDeviceAction} from "../slices/devices";

export const getDevicesThunk = createAsyncThunk(
  'device/getDevice',
  async ({ storeId, refresh }, { getState, dispatch }) => {
    try {
      const currentState = getState()
      let storeIdForDevices = currentState.devices.storeIdOfDevice
      const deviceErr = currentState.devices.err
      if (storeId) {
        if (Number(storeIdForDevices) === Number(storeId) && !refresh) {
          return
        }
        storeIdForDevices = storeId
      }
      if (deviceErr) {
        dispatch(updateSensorsErrAction(null))
      }
      dispatch(addLoadingItemAction('gettingDevices'))
      const result = await dispatch(fetchBackendAPIThunk({
        method: 'get',
        url: '/device',
        params: {
          storeId: Number(storeIdForDevices)
        },
        blockUi: false
      })).unwrap()
      const allDevices = {}
      for (const device of result) {
        allDevices[device.deviceId] = device
      }
      dispatch(updateDeviceAction({
        data: allDevices,
        storeId: Number(storeIdForDevices)
      }))
    } catch (e) {
      console.log(e)
      dispatch(updateSensorsErrAction('Network failure'))
    } finally {
      dispatch(dropLoadingItemAction('gettingDevices'))
    }
  }
)
