import { Skeleton } from '@mui/material'
import React from 'react'

function NavbarSkeleton () {
  return (
    <Skeleton variant={'rectangular'} width={'100%'} height={40} />
  )
}

export default NavbarSkeleton
