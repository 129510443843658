import { Box, ImageList, ImageListItem, ListSubheader } from '@mui/material'
import SensorImageListItem from '../../../components/SensorImageListItem'
import React, { memo } from 'react'
import { useSensor } from '../../../hooks/useSensor'
import HelpModal from "../../../components/HelpModal";

function ArchivedImageList () {
  const sensor = useSensor()
  let numOfImg = 0
  for (const sensorImage of sensor.sensorImages) {
    if (sensorImage.isArchived) {
      numOfImg += 1
    }
  }
  return (
    <ImageList
      cols={2}
      gap={8}
    >
      <ImageListItem
        key={'Subheader'}
        cols={2}
      >
        <ListSubheader
          id='store-list'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            {
              numOfImg === 0
                ? 'No images archived'
                : `${numOfImg} images archived`
            }
          </Box>
          <HelpModal />
        </ListSubheader>
      </ImageListItem>
      {sensor.sensorImages.map(sensorImage => {
        if (sensorImage.isArchived) {
          return (
            <SensorImageListItem key={sensorImage.sensorImageId} sensorImage={sensorImage}/>
          )
        } else {
          return <></>
        }
      })}
    </ImageList>
  )
}

export default memo(ArchivedImageList)
