import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  appNavbarFlexibleWrapScrollTo: null,
  appNavbarFlexibleWrapLastScrollTime: new Date().getTime()
}

const scrollingSlice = createSlice({
  name: 'scrolling',
  initialState,
  reducers: {
    updateAppNavbarFlexibleWrapScrollToAction: (state, action) => {
      state.appNavbarFlexibleWrapScrollTo = action.payload
    },
    updateAppNavbarFlexibleWrapLastScrollTimeAction: (state, action) => {
      state.appNavbarFlexibleWrapLastScrollTime = action.payload
    }
  }
})

export const { updateAppNavbarFlexibleWrapScrollToAction, updateAppNavbarFlexibleWrapLastScrollTimeAction } = scrollingSlice.actions

export const scrollingReducer = scrollingSlice.reducer
