import { useEffect } from 'react'

export function useReactNativeMessageListener (onMessageHandler) {
  useEffect(() => {
    const onMessage = (event) => {
      try {
        const action = JSON.parse(event.data)
        return onMessageHandler(action)
      } catch (e) {
        console.log(e)
      }
    }
    window.addEventListener('message', onMessage)
    document.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
      document.removeEventListener('message', onMessage)
    }
  }, [])
}
