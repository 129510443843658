import { Box, Typography } from '@mui/material'
import React from 'react'
import howToLogout1 from '../../../assets/imgs/help/howToLogout/1.png'
import howToLogout2 from '../../../assets/imgs/help/howToLogout/2.png'
import HelpImg from "../HelpImg";

export const generalHelpDetail = [
  {
    title: 'About this app',
    content: (
      <>
        <Typography>
          7Iot Snapshot is an app for onboard sensors for 7-Eleven stores.
        </Typography>
      </>
    )
  },
  {
    title: 'Procedure of onboard a sensor',
    content: (
      <>
        <ol>
          <li>
            Search a store, then go to that store page
          </li>
          <li>
            Add sensor to that store
          </li>
          <li>
            Upload images for that sensor
          </li>
        </ol>
      </>
    )
  },
  {
    title: 'How to logout',
    content: (
      <>
        <Typography>
          Click on the top right user icon.
        </Typography>
        <HelpImg src={howToLogout1} />
        <Typography>
          Then you can see the logout option.
        </Typography>
      </>
    )
  },
  {
    title: 'How to search for a store',
    content: (
      <>
        <Typography>
          In home page, click on top left search icon
        </Typography>
        <HelpImg src={howToLogout2} />
        <Typography>
          You can search by either store ID or address.
        </Typography>
      </>
    )
  }
]
