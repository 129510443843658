// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./Worker'

const workerInst = new Worker()
let uuid = 0
const getUuid = () => {
  uuid ++
  return uuid
}

const messagePool = new Map()

workerInst.onmessage = (ev) => {
  const {reqId, res} = ev.data
  const callback = messagePool.get(reqId)
  if (callback) {
    callback(res)
  }
  messagePool.delete(reqId)
}

workerInst.onerror = () => {
  window.location.href = '/error'
}

export const worker = (task, payload) => {
  return new Promise((resolve) => {
    const reqId = getUuid()
    workerInst.postMessage({
      task,
      payload,
      reqId
    })
    messagePool.set(reqId, (res) => {
      resolve(res)
    })
  })
}