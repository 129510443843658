import { useDispatch, useSelector } from 'react-redux'
import { updateSensorInfoFormFieldAction } from '../../slices/sensorInfoForm'
import { Box } from '@mui/material'
import AppSelect from '../AppSelect'
import { getSensorNamePrefixByCategory } from '../../utils/sensorDetail'
import React, { memo, useEffect } from 'react'
import { useAllSensorNameSetInStore } from '../../hooks/useAllSensorNameSetInStore'

function SensorNameSelect () {
  const dispatch = useDispatch()
  const allSensorNameSet = useAllSensorNameSetInStore()
  const currentSensorType = useSelector(state => state.sensorInfoForm.sensorType)
  const currentLocationCategory = useSelector(state => state.sensorInfoForm.locationCategory)
  const currentSensorName = useSelector(state => state.sensorInfoForm.sensorName)
  let availableSensorNames = getSensorNamePrefixByCategory(currentLocationCategory.val, currentSensorType.val)
  if (availableSensorNames) {
    availableSensorNames = [...availableSensorNames]
    for (let i = 0; i < availableSensorNames.length; i++) {
      const sensorNamePrefix = availableSensorNames[i]
      let num = 2
      while (allSensorNameSet.has(availableSensorNames[i])) {
        availableSensorNames[i] = `${sensorNamePrefix} ${num}`
        num++
      }
    }
  }

  const updateAction = (val) => updateSensorInfoFormFieldAction({
    sensorName: val
  })

  useEffect(() => {
    if (currentLocationCategory.val === '' && !currentSensorName.disabled) {
      dispatch(updateAction({
        ...currentSensorName,
        disabled: true
      }))
    } else if (currentLocationCategory.val !== '' && currentSensorName.disabled) {
      dispatch(updateAction({
        ...currentSensorName,
        disabled: false
      }))
    }
  }, [currentLocationCategory.val])

  return (
    <Box style={{
      margin: '10px 0'
    }}>
      <AppSelect
        updateAction={updateAction}
        fieldSelector={state => state.sensorInfoForm.sensorName}
        label={'Sensor Name'}
        availableOptions={availableSensorNames}
      />
    </Box>
  )
}

export default memo(SensorNameSelect)
