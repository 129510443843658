import {createTheme, responsiveFontSizes} from "@mui/material";

const insightTheme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "transparent !important"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            marginRight: '20px',
            minHeight: 'auto',
            fontWeight: 400,
            fontSize: '14px'
          },
        }
      }
    }
  },
  palette: {
    primary: {
      dark: '#107F62',
      main: '#107F62',
      light: '#41A44B',
      contrastText: '#fff'
    },
    secondary: {
      main: '#FDAE61',
    },
    error: {
      main: '#EC2526'
    }
  },
  typography: {
    h1: {
      fontSize: '20px',
      color: '#212121',
      lineHeight: '28px',
      letterSpacing: '0.15px',
      fontWeight: 700
    },
    h2: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '14px',
    }
  }
})


export default insightTheme