import { useDispatch } from 'react-redux'
import { signOutAction } from '../../../slices/global'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import React, { memo } from 'react'
import {signOutThunk} from "../../../thunks/signOutThunk";

function UserDrawerLogout () {
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(signOutThunk())
  }
  return (
    <ListItemButton onClick={logout}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText>
        Logout
      </ListItemText>
    </ListItemButton>
  )
}

export default memo(UserDrawerLogout)
