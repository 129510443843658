import { configureStore } from '@reduxjs/toolkit'
import { globalReducer } from './slices/global'
import { loadingReducer } from './slices/loading'
import { signInReducer } from './slices/signIn'
import { snackbarReducer } from './slices/snackbar'
import { sensorInfoFormReducer } from './slices/sensorInfoForm'
import { newStoreReducer } from './slices/newStore'
import { allStoresReducer } from './slices/allStores'
import { sensorsReducer } from './slices/sensors'
import { helpSearchReducer } from './slices/helpSearch'
import { storeSearchReducer } from './slices/storeSearch'
import { scrollingReducer } from './slices/scrolling'
import { uiSizeReducer } from './slices/uiSize'
import {confirmReducer} from "./slices/confirm";
import {devicesReducer} from "./slices/devices";
import {addDeviceFormReducer} from "./slices/addDeviceForm";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    loading: loadingReducer,
    signIn: signInReducer,
    snackbar: snackbarReducer,
    sensorInfoForm: sensorInfoFormReducer,
    newStore: newStoreReducer,
    allStores: allStoresReducer,
    sensors: sensorsReducer,
    helpSearch: helpSearchReducer,
    storeSearch: storeSearchReducer,
    scrolling: scrollingReducer,
    uiSize: uiSizeReducer,
    confirm: confirmReducer,
    devices: devicesReducer,
    addDeviceForm: addDeviceFormReducer
  }
})
