import { useSelector } from 'react-redux'
import { Box, CircularProgress } from '@mui/material'
import React from 'react'

function LoadingCircle () {
  const blockLoadingItems = useSelector(state => state.loading.blockLoadingItems)
  return blockLoadingItems.length > 0
    ? (
      <Box style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        top: 0,
        left: 0,
        zIndex: 20
      }}>
        <Box style={{
          position: 'absolute',
          width: '100%',
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <CircularProgress />
        </Box>
      </Box>
      )
    : (
      <></>
      )
}

export default LoadingCircle
