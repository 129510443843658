import {createSlice} from "@reduxjs/toolkit";

const initialState = {}

const storesInfoSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    updateStoresInfoAction: (state, action) => {
      const storesInfo = {}
      if (action.payload) {
        for (const storeInfo of action.payload) {
          storesInfo[storeInfo.store_id] = storeInfo
        }
      }
      return storesInfo
    }
  }
})

export const storesInfoReducer = storesInfoSlice.reducer

export const {updateStoresInfoAction} = storesInfoSlice.actions