import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { updateAppNavbarFlexibleWrapScrollToAction } from '../../../../slices/scrolling'

let prevStoreListStr = ''

StoreListScrollControl.propTypes = {
  storeListStr: PropTypes.string.isRequired
}
function StoreListScrollControl ({ storeListStr }) {
  const dispatch = useDispatch()
  useEffect(() => {
    if (prevStoreListStr !== storeListStr) {
      prevStoreListStr = storeListStr
      dispatch(updateAppNavbarFlexibleWrapScrollToAction(0))
    }
  }, [storeListStr])
  return <></>
}

export default StoreListScrollControl
