import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  content: '',
  open: false
}

const confirmSlice = createSlice({
  name: 'confirm',
  initialState,
  reducers: {
    updateConfirmAction: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})

export const confirmReducer = confirmSlice.reducer

export const {updateConfirmAction} = confirmSlice.actions