import {addSensorImageThunk} from "../../../thunks/addSensorImageThunk";
import {Button, ImageListItem} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import React, {useRef} from "react";
import {useSensor} from "../../../hooks/useSensor";
import {useDispatch} from "react-redux";
import {useDevice} from "../../../hooks/useDevice";
import {addDeviceImageThunk} from "../../../thunks/addDeviceImageThunk";

function NewDeviceImageButton() {
  const imageInputRef = useRef()
  const device = useDevice()
  const dispatch = useDispatch()
  const onImage = () => {
    const imgFile = imageInputRef.current.files[0]
    dispatch(addDeviceImageThunk({
      deviceId: device.deviceId,
      image: imgFile
    }))
  }

  return (
    <ImageListItem
      key={'AddImg'}
      style={{
        height: '200px'
      }}
    >
      <Button
        variant="outlined"
        component='label'
        fullWidth={true}
        style={{
          height: '100%'
        }}
      >
        <CameraAltIcon style={{
          fontSize: '40px'
        }} />
        <input hidden accept='image/*' type='file' ref={imageInputRef} onChange={onImage} />
      </Button>
    </ImageListItem>
  )
}

export default NewDeviceImageButton