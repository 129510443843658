import { createSlice } from '@reduxjs/toolkit';

const initState = {
    currentPage: 'connectivity',
    isLoading: false,
    filter: {
        region: [],
        market: [],
        al: [],
        store: [],
    },
    isEdit: false,
    openModal: false,
    modalTitle: "",
    activeModal: "",
    modalDetails: {}
}

const dashboardSettingSlice = createSlice({
    name:'setting',
    initialState: initState,
    reducers: {
        updateRunOpsDashboardData: (state, action) => { //UPDATE_RUNOPS_DASHBOARD_DATA
          state[action.payload?.key] = action.payload?.data
        },
        updatePageInRunOpsDashboard: (state, action) => { //UPDATE_PAGE_IN_RUNOPS_DASHBOARD
          state.currentPage = action.payload
        }
    },
    extraReducers: (builder) => {
    }
})

export const { updateRunOpsDashboardData, updatePageInRunOpsDashboard } = dashboardSettingSlice.actions;
export default dashboardSettingSlice.reducer;