import { Box } from '@mui/material'
import React, { memo } from 'react'
import { archivedImageNavbarWrap } from '../../wraps/archivedImageNavbarWrap'
import ArchivedImageList from './ArchivedImageList'
import RedirectIfForbidden from '../../components/RedirectIfForbidden'
import { loadStoreDataWrap } from '../../wraps/loadStoreDataWrap'
import { loadSensorDataWrap } from '../../wraps/loadSensorDataWrap'
import SensorPageSkeleton from '../../components/pageSkeletons/SensorPageSkeleton'

function ArchivedImagesPage () {
  return (
    <Box>
      <RedirectIfForbidden permissionType={'viewArchiveSensorImage'} />
      <ArchivedImageList />
    </Box>
  )
}

export default loadStoreDataWrap(
  loadSensorDataWrap(
    archivedImageNavbarWrap(memo(ArchivedImagesPage)),
    true, SensorPageSkeleton
  ),
  true,
  SensorPageSkeleton
)
