import { ListItemButton, ListItemIcon, ListItemText, Skeleton } from '@mui/material'
import React, { memo } from 'react'

function SensorSkeleton () {
  return (
    <ListItemButton
      style={{
        paddingLeft: '40px'
      }}
    >
      <ListItemIcon>
        <Skeleton variant="circular" height={35} width={35}/>
      </ListItemIcon>
      <ListItemText
        primary={(
          <Skeleton variant={'text'} />
        )}
        secondary={(
          <Skeleton variant={'text'} />
        )}
      />
    </ListItemButton>
  )
}

export default memo(SensorSkeleton)
