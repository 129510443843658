import { Button } from '@mui/material'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { canFormSubmit } from '../../utils/canFormSubmit'

AppSubmitButton.propTypes = {
  fieldsSelector: PropTypes.func.isRequired
}

function AppSubmitButton (props) {
  const fields = useSelector(props.fieldsSelector)
  const notDisable = canFormSubmit(fields)
  return (
    <Button
      disabled={!notDisable}
      {...props}
    />
  )
}

export default memo(AppSubmitButton)
