import React, { memo } from 'react'
import { Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch } from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import { cleanSensorInfoFormAction } from '../../../slices/sensorInfoForm'
import {useStore} from "../../../hooks/useStore";

function DeviceNavbarAddDeviceButton () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const store = useStore()
  return (
    <Fab
      color={'primary'}
      onClick={() => {
        dispatch(cleanSensorInfoFormAction())
        navigate(`/snapshot/home/${store.storeId}/add-device`)
      }}
    >
      <AddIcon />
    </Fab>
  )
}

export default memo(DeviceNavbarAddDeviceButton)
