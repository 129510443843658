import { Box, Button, Typography, useTheme } from '@mui/material'
import React, { memo } from 'react'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'

function AppError () {
  const theme = useTheme()
  return (
    <Box style={{
      position: 'absolute',
      width: '80%',
      left: '10%',
      top: '50%',
      transform: 'translateY(-50%)'
    }}>
      <Box style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <ReportProblemIcon style={{
          color: theme.palette.primary.main,
          fontSize: '70px'
        }} />
      </Box>
      <Typography style={{
        textAlign: 'center',
        marginTop: '5px',
        lineHeight: '20px'
      }}>
        There seems to have been a problem, please refresh page later.
      </Typography>
      <Box style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px'
      }}>
        <Button
          size={'large'}
          variant={'contained'}
          fullWidth
          onClick={() => {
            window.location.reload()
          }}
        >
          Refresh
        </Button>
      </Box>
    </Box>
  )
}

export default memo(AppError)
