import { Box } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAppNavbarFlexibleWrapLastScrollTimeAction,
  updateAppNavbarFlexibleWrapScrollToAction
} from '../../../slices/scrolling'

AppNavbarFlexibleWrap.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node)
}

let lastScrollTime = 0

function AppNavbarFlexibleWrap ({ children }) {
  const visualViewPortHeight = useSelector(state => state.uiSize.visualViewportHeight)
  const scrollTo = useSelector(state => state.scrolling.appNavbarFlexibleWrapScrollTo)
  const dispatch = useDispatch()
  const boxRef = useRef()
  const flexibleWrapOnScroll = () => {
    const nowTimeStamp = new Date().getTime()
    if (nowTimeStamp - lastScrollTime > 100) {
      dispatch(updateAppNavbarFlexibleWrapLastScrollTimeAction(nowTimeStamp))
      lastScrollTime = nowTimeStamp
    }
  }
  useEffect(() => {
    boxRef.current.addEventListener('scroll', flexibleWrapOnScroll)
  }, [])
  const scrollToPosition = (positionY) => {
    boxRef.current.scroll({
      top: positionY,
      behavior: 'smooth'
    })
  }
  useEffect(() => {
    if (scrollTo != null && boxRef.current != null) {
      scrollToPosition(scrollTo)
      dispatch(updateAppNavbarFlexibleWrapScrollToAction(null))
    }
  }, [scrollTo])
  return (
    <Box
      ref={boxRef}
      style={{
        marginTop: '60px',
        height: `${visualViewPortHeight - 60}px`,
        overflowY: 'scroll'
      }}
    >
      {children}
    </Box>
  )
}

export default AppNavbarFlexibleWrap
