import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {pathsWithoutAuth} from "../../constants";

function AppNavigation () {
  const username = useSelector(state => state.global.username)
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    for (const pathWithoutAuth of pathsWithoutAuth) {
      if (location.pathname.startsWith(`/snapshot${pathWithoutAuth}`)) {
        return
      }
    }
    const pathSupposeToBe = username ? '/snapshot/home' : '/snapshot/login'
    if (!location.pathname.startsWith(pathSupposeToBe)) {
      navigate(pathSupposeToBe)
    }
  }, [username])
  return <></>
}

export default memo(AppNavigation)
