export const canFormSubmit = (form) => {
  for (const fieldName in form) {
    const field = form[fieldName]
    if (field.required && field.val === '') {
      return false
    }
    if (field.err) {
      return false
    }
  }
  return true
}
