import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  err: null,
  data: {},
  storeIdOfSensors: null
}

const sensorsSlice = createSlice({
  name: 'slices',
  initialState,
  reducers: {
    updateSensorsAction: (state, action) => {
      const { data, storeId } = action.payload
      state.err = null
      state.data = data
      state.storeIdOfSensors = storeId
    },
    updateSensorsErrAction: (state, action) => {
      state.storeIdOfSensors = null
      state.data = {}
      state.err = action.payload
    },
    addLocalImageToSensorAction: (state, action) => {
      const {
        sensorId,
        sensorImageId,
        sensorImageUrl
      } = action.payload
      const sensor = state.data[sensorId]
      if (sensor) {
        const now = new Date()
        sensor.sensorImages.push({
          sensorImageId,
          sensorImageBlobKey: '',
          sensorTempUrl: sensorImageUrl,
          isArchived: false,
          createdAt: now.getTime(),
          lastUpdate: now.getTime(),
          uploadProgress: 0
        })
      }
    },
    updateLocalImageInSensorUploadProgressAction: (state, action) => {
      const {
        sensorId,
        sensorImageId,
        uploadProgress
      } = action.payload
      const sensor = state.data[sensorId]
      if (sensor) {
        for (const sensorImage of sensor.sensorImages) {
          if (sensorImage.sensorImageId === sensorImageId) {
            sensorImage.uploadProgress = uploadProgress
            break
          }
        }
      }
    },
    convertLocalImageInSensorToUploadedImageAction: (state, action) => {
      const {
        sensorId,
        oldSensorImageId,
        newSensorImage
      } = action.payload
      const sensor = state.data[sensorId]
      if (sensor) {
        for (let i = 0; i < sensor.sensorImages.length; i ++) {
          const sensorImage = sensor.sensorImages[i]
          if (sensorImage.sensorImageId === oldSensorImageId) {
            sensor.sensorImages[i] = newSensorImage
            break
          }
        }
      }
    },
    deleteSensorImageAction: (state, action) => {
      const {
        sensorId,
        sensorImageId,
      } = action.payload
      const sensor = state.data[sensorId]
      if (sensor) {
        for (let i = 0; i < sensor.sensorImages.length; i ++) {
          const sensorImage = sensor.sensorImages[i]
          if (sensorImage.sensorImageId === sensorImageId) {
            sensor.sensorImages.splice(i, 1)
            break
          }
        }
      }
    },
    updateArchiveSensorImageAction: (state, action) => {
      const {
        sensorId,
        sensorImageId,
        archive
      } = action.payload
      const sensor = state.data[sensorId]
      if (sensor) {
        for (let i = 0; i < sensor.sensorImages.length; i ++) {
          const sensorImage = sensor.sensorImages[i]
          if (sensorImage.sensorImageId === sensorImageId) {
            sensorImage.isArchived = archive
            break
          }
        }
      }
    }
  }
})

export const { updateSensorsAction, updateSensorsErrAction, addLocalImageToSensorAction, updateLocalImageInSensorUploadProgressAction, convertLocalImageInSensorToUploadedImageAction, deleteSensorImageAction, updateArchiveSensorImageAction } = sensorsSlice.actions

export const sensorsReducer = sensorsSlice.reducer
