import ArchiveIcon from '@mui/icons-material/Archive'
import { Box, IconButton } from '@mui/material'
import React, { memo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSensor } from '../../../hooks/useSensor'

function ArchiveFolderButton () {
  const navigate = useNavigate()
  const location = useLocation()
  const sensor = useSensor()
  let numOfArchivedImg = 0
  for (const sensorImage of sensor.sensorImages) {
    if (sensorImage.isArchived) {
      numOfArchivedImg += 1
    }
  }
  if (numOfArchivedImg > 99) {
    numOfArchivedImg = 99
  }
  return (
    <IconButton
      style={{
        color: '#fff',
        marginLeft: 'auto'
      }}
      onClick={() => navigate(`${location.pathname}/archived`)}
    >
      <ArchiveIcon />
      {numOfArchivedImg > 0 && (
        <Box style={{
          position: 'absolute',
          backgroundColor: '#EE2526',
          fontSize: '10px',
          width: '16px',
          height: '14px',
          borderRadius: '50%',
          top: '4px',
          right: '0',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '2px'
        }}>
          {numOfArchivedImg}
        </Box>
      )}
    </IconButton>
  )
}

export default memo(ArchiveFolderButton)
