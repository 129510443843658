import {combineReducers} from "redux";
import dashboardSettingSlice from "./dashboardSettingSlice";
import categorySlice from "./categorySlice";
import notificationSlice from "./notificationSlice";
import loadingSlice from "./loadingSlice";
import {dashboardFilterSearchReducer} from "./dashboardFilterSearchSlice";

const iotDashboardReducer = combineReducers({
    setting: dashboardSettingSlice,
    loadings: loadingSlice,
    notificationData: notificationSlice,
    categories: categorySlice,
    filterSearch: dashboardFilterSearchReducer
})

export default iotDashboardReducer