import {getTokenValues} from "../../reducers/globalSlice";

function getTokenDispatch(dispatch) {
  return new Promise((resolve, reject) => {
    dispatch(getTokenValues({
      cb: (result) => {
        resolve(result)
      }
    }))
  })
}

export default getTokenDispatch