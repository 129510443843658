import {createSlice} from "@reduxjs/toolkit";
import {SORT_BY, TIMEFRAME_TYPE, VIEW_PER} from "../../pages/InsightDashboard/constants";
const initialState = {
  timeframeTypeSelect: {
    val: TIMEFRAME_TYPE.LAST_24_HOURS,
    selections: Object.values(TIMEFRAME_TYPE)
  },
  viewPerSelect: {
    val: VIEW_PER.STORE,
    selections: Object.values(VIEW_PER)
  },
  sortBySelect: {
    val: SORT_BY.PRIORITY,
    selections: Object.values(SORT_BY)
  },
}

const insightFilterSlice = createSlice({
  name: 'insightFilter',
  initialState,
  reducers: {
    updateTimeframeTypeAction: (state, action) => {
      state.timeframeTypeSelect = action.payload
    },
    updateViewPerAction: (state, action) => {
      state.viewPerSelect = action.payload
    },
    updateSortByAction: (state, action) => {
      state.sortBySelect = action.payload
    }
  }
})

export const insightFilterReducer = insightFilterSlice.reducer

export const {updateTimeframeTypeAction, updateSortByAction, updateViewPerAction} = insightFilterSlice.actions