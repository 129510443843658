import React, { memo, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import sevenElevenFullNameLogo from '../../assets/imgs/7-eleven-horizontal.svg'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function IndexPage () {
  const username = useSelector(state => state.global.username)
  const navigate = useNavigate()
  useEffect(() => {
    if (!username) {
      navigate('/snapshot/login')
    } else {
      navigate('/snapshot/home')
    }
  }, [username])

  return (
    <Box style={{
      position: 'absolute',
      width: '98%',
      top: '50%',
      transform: 'translateY(-50%)'
    }}>
      <Box style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <img src={sevenElevenFullNameLogo} alt='' style={{
          width: '70%'
        }} />
      </Box>
      <Box style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Typography
          style={{
            marginTop: '8px'
          }}
          variant={'h5'}
        >
          Iot Snapshot
        </Typography>
      </Box>
    </Box>
  )
}

export default memo(IndexPage)
