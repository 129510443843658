import { createAsyncThunk } from '@reduxjs/toolkit'

export const newRelicAddPageActionThunk = createAsyncThunk(
  'newRelic/addPageAction',
  async ({ eventName, payload }, { getState, dispatch }) => {
    const currentState = getState()
    const username = currentState.global.username
    window.newrelic.addPageAction(eventName, {
      username,
      ...payload
    })
  })
