import { Box, Drawer, List, ListItem, ListItemText } from '@mui/material'
import React, { memo } from 'react'
import PropTypes from 'prop-types'

AppDrawerList.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  drawerTitle: PropTypes.string,
  drawerActionsList: PropTypes.arrayOf(PropTypes.node).isRequired,
  anchor: PropTypes.oneOf(['left', 'top', 'right', 'bottom'])
}

function AppDrawerList ({ open, setOpen, drawerTitle, drawerActionsList, anchor }) {
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={handleClose}
    >
      <Box
        role={'presentation'}
        onClick={handleClose}
        onKeyDown={handleClose}
      >
        <List>
          {drawerTitle && (
            <ListItem>
              <ListItemText style={{
                display: 'flex',
                justifyContent: 'center'
              }} secondary={drawerTitle} />
            </ListItem>
          )}
          {drawerActionsList}
        </List>
      </Box>
    </Drawer>
  )
}

export default memo(AppDrawerList)
