import { appNavbarWrap } from '../appNavbarWrap'
import React, { memo } from 'react'
import { Toolbar, Typography } from '@mui/material'
import NavBackButton from '../../components/NavBackButton'
import SensorTypeIcon from '../../components/SensorTypeIcon'
import ArchiveFolderButton from './ArchiveFolderButton'
import ConditionalRenderByPermission from '../../components/ConditionalRenderByPermission'
import { useSensor } from '../../hooks/useSensor'

export const sensorNavbarWrap = (Component) => {
  return appNavbarWrap(Component, memo(function Wrapped () {
    const sensor = useSensor()

    return (
      <Toolbar style={{
        justifyContent: 'left'
      }}>
        <NavBackButton/>
        <SensorTypeIcon sensorType={sensor.sensorType} />
        <Typography style={{
          marginTop: '2px',
          marginLeft: '10px'
        }}>
          # {sensor.sensorId}
        </Typography>
        <ConditionalRenderByPermission permissionType={'viewArchiveSensorImage'}>
          <ArchiveFolderButton />
        </ConditionalRenderByPermission>
      </Toolbar>
    )
  }))
}
