import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAPIThunk } from './fetchApiThunk'
import imageCompression from 'browser-image-compression'

export const scanQrFileOnlineThunk = createAsyncThunk(
  'scanQrFileOnlineThunk/scanQrFileOnlineThunk',
  async ({ imageFile }, { getState, dispatch }) => {
    const compressedImage = await imageCompression(imageFile, {
      maxSizeMB: 1
    })
    const formData = new FormData()
    formData.append('file', compressedImage)
    const response = await dispatch(fetchAPIThunk({
      blockUi: false,
      config: {
        method: 'post',
        url: 'https://api.qrserver.com/v1/read-qr-code/',
        data: formData
      }
    })).unwrap()
    return response[0].symbol[0].data
  }
)
