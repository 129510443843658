import { Box, ImageList } from '@mui/material'
import React from 'react'
import NavbarSkeleton from '../../NavbarSkeleton'
import AppImageListItemSkeleton from '../../AppImageListItemSkeleton'

function SensorPageSkeleton () {
  const imageItems = []
  for (let i = 0; i < 4; i++) {
    imageItems.push(<AppImageListItemSkeleton />)
  }
  return (
    <Box>
      <NavbarSkeleton />
      <ImageList
        cols={2}
        gap={8}
        style={{
          marginTop: '50px'
        }}
      >
        {imageItems}
      </ImageList>
    </Box>
  )
}

export default SensorPageSkeleton
