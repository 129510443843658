import { Box } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MemorizedChildren from '../MemorizedChildren'
import { useSelector } from 'react-redux'

TouchListeningBox.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  style: PropTypes.object,
  onTap: PropTypes.func,
  onTapHold: PropTypes.func
}

const holdTime = 500

const antiMistouchScale = 5

function TouchListeningBox ({ children, style, onTap = () => null, onTapHold = () => null }) {
  const [whenTouch, setWhenTouch] = useState(0)
  const [touchStartLocation, setTouchStartLocation] = useState(null)
  const [currentTouchLocation, setCurrentTouchLocation] = useState(null)
  const [executed, setExecuted] = useState(true)
  const [holdExecute, setHoldExecute] = useState(false)
  const [_timeout, _setTimeout] = useState(null)
  const lastScrollTime = useSelector(state => state.scrolling.appNavbarFlexibleWrapLastScrollTime)

  const shouldExe = () => {
    const nowTimeStamp = new Date().getTime()
    if (nowTimeStamp - lastScrollTime < 200) {
      return false
    }
    const { x, y } = currentTouchLocation
    return (
      (Math.abs(touchStartLocation.x - x) < antiMistouchScale) &&
      (Math.abs(touchStartLocation.y - y) < antiMistouchScale)
    )
  }
  useEffect(() => {
    if (holdExecute) {
      if (shouldExe()) {
        onTapHold()
      }
      setHoldExecute(false)
    }
  }, [holdExecute])
  useEffect(() => {
    if (!executed) {
      const now = new Date()
      setWhenTouch(now.getTime())
      _setTimeout(setTimeout(exeOnTapHold, holdTime))
    } else {
      if (_timeout) {
        clearTimeout(_timeout)
        _setTimeout(null)
      }
    }
  }, [executed])
  const exeOnTapHold = () => {
    setExecuted(true)
    setHoldExecute(true)
  }
  const exeOnTap = () => {
    if (!executed) {
      setTimeout(() => {
        setExecuted(true)
        if (shouldExe()) {
          onTap()
        }
      }, 1)
    }
  }
  return (
    <Box
      style={{
        userSelect: 'none',
        ...style
      }}
      onTouchStart={(event) => {
        setExecuted(false)
        const newTouchLocation = {
          x: event.touches[0].screenX,
          y: event.touches[0].screenY
        }
        setTouchStartLocation(newTouchLocation)
        setCurrentTouchLocation(newTouchLocation)
      }}

      onTouchMove={(event) => {
        const fingerLocation = {
          x: event.touches[0].screenX,
          y: event.touches[0].screenY
        }
        setCurrentTouchLocation(fingerLocation)
      }}

      onTouchEnd={() => {
        const now = new Date()
        const timeDelta = now.getTime() - whenTouch
        if (timeDelta < holdTime) {
          exeOnTap()
        }
      }}
    >
      <MemorizedChildren element={children} />
    </Box>
  )
}

export default memo(TouchListeningBox)
