import { Box } from '@mui/material'
import React, { memo } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import AppSubmitButton from '../AppSubmitButton'
import PropTypes from 'prop-types'

SensorFormSubmitButton.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

function SensorFormSubmitButton ({ onSubmit }) {
  return (
    <Box style={{
      marginTop: '15px'
    }}>
      <AppSubmitButton
        fieldsSelector={state => state.sensorInfoForm}
        variant={'contained'}
        size={'large'}
        fullWidth
        onClick={onSubmit}
      >
        <DoneIcon style={{
          fontSize: '32px'
        }} />
      </AppSubmitButton>
    </Box>
  )
}

export default memo(SensorFormSubmitButton)
