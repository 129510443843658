import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBackendAPIThunk } from './fetchBackendApiThunk'
import { newRelicAddPageActionThunk } from './newRelicAddPageActionThunk'
import {deleteSensorImageAction} from "../slices/sensors";

export const deleteSensorImageThunk = createAsyncThunk(
  'util/deleteSensorImage',
  async ({ sensorImageId, archive, sensorId }, { dispatch, getState }) => {
    const currentState = getState()
    const storeIdForSensors = currentState.sensors.storeIdOfSensors
    await dispatch(await fetchBackendAPIThunk({
      method: 'delete',
      url: 'sensor_image',
      data: {
        sensorImageId
      },
      params: {
        storeId: storeIdForSensors
      }
    })).unwrap()
    await dispatch(newRelicAddPageActionThunk({
      eventName: 'Sensor Image Deleted',
      payload: {
        sensorImageId,
        storeId: storeIdForSensors
      }
    })).unwrap()
    dispatch(deleteSensorImageAction({
      sensorId,
      sensorImageId,
    }))
  })
