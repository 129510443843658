const hashCode = (str) => {
    var hash = 0,
      i, chr;
    if (str?.length === 0) return hash;
    for (i = 0; i < str?.length; i++) {
      chr = str?.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
  
class Temp {
    tempDate = null
    static temp = null

    constructor() {
        this.tempDate = new Map()
    }

    static getInst() {
        if (!this.temp) {
            this.temp = new Temp()
        }
        return this.temp
    }

    get(key) {
        return this.tempDate.get(hashCode(JSON.stringify(key)))
    }

    set(key, value) {
        this.tempDate.set(hashCode(JSON.stringify(key)), value)
    }

    clear() {
        this.tempDate.clear()
    }

}

export default Temp