export const TIMEFRAME_TYPE = {
  LAST_24_HOURS: 'Last 24 Hours',
  LAST_7_DAYS: 'Last 7 Days',
  LAST_30_DAYS: 'Last 30 Days'
}

export const VIEW_PER = {
  STORE: 'Store',
  AREA_LEADER: 'Area Leader',
  MARKET: 'Market',
  REGION: 'Region'
}

export const SORT_BY = {
  PRIORITY: 'Priority'
}

export const COMPONENT_STATUS = {
  LOADING: 'LOADING',
  FAILED: 'FAILED',
  LOADED: 'LOADED'
}

export const API_TYPE = {
  BACKEND_API: 'BACKEND_API',
  PING_FEDERATE_API: 'PING_FEDERATE_API',
  EXTERNAL_API: 'EXTERNAL_API'
}

export const TICKET_DISPLAY_STATUS = {
  TICKET_NOT_CREATED: 'TICKET_NOT_CREATED',
  TICKET_CREATING: 'TICKET_CREATING',
  TICKET_CREATED: 'TICKET_CREATED',
  TICKET_CREATE_SUCCESS: 'TICKET_CREATE_SUCCESS',
  TICKET_CREATE_FAILED: 'TICKET_CREATE_FAILED'
}