import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { sensorSelector } from '../../appSelectors/sensorSelector'

export function useSensor () {
  const { sensorId } = useParams()
  const sensor = useSelector(sensorSelector(sensorId))
  if (!sensor) {
    window.location.href = '/'
  }
  return sensor
}
