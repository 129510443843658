import refreshToken from "./refreshToken";
import { showSnackBar } from "../../reducers/globalSlice";
import {newRelicNoticeError} from "../../utlities/newrelic/newRelicNoticeError";

const {
  REACT_APP_REDIRECT_URI,
} = process.env;

/**
 * Return true if exception is handled
 * Return false if exception is not handled.
 * @param e
 * @param dispatch
 * @returns {Promise<boolean>}
 */

async function handleRequestException(e, dispatch, showApiError, 
  navError, isUserInfo, sessionId, email) {
  switch (e.response.status) {
    case 401: {
      try {
        await refreshToken(dispatch, e.response.data, isUserInfo);
      } catch (e) {
        return false;
      }
      break;
    }
    default: {
      newRelicNoticeError(e, sessionId, email);
      if(navError) window.location.assign(`${REACT_APP_REDIRECT_URI}/error`);
      else if (showApiError)
        dispatch(showSnackBar({
          severity: "error", 
          message: `${e.response?.data?.message}`,
          vertical: "top",
          horizontal: "center"
        }))
      else if(e?.response?.data?.code)
        dispatch(showSnackBar({
          severity: "error", 
          message: `${e.response.data.code} ${e.response?.data?.message}`,
        }))
      else if(e?.message) 
        dispatch(showSnackBar({
          severity: "error", 
          message: e.message,
        }))
      else 
        dispatch(showSnackBar({
          severity: "error", 
          message: "Internal Error",
        }))
      return false;
    }
  }
  return true;
}

export default handleRequestException;
