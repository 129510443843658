import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  message: '',
  severity: 'info',
  open: false
}

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbarAction: (state, action) => {
      state.message = action.payload.message
      state.severity = action.payload.severity ? action.payload.severity : 'info'
      state.open = true
    },
    closeSnackbarAction: state => {
      state.open = false
    }
  }
})

export const { showSnackbarAction, closeSnackbarAction } = snackbarSlice.actions

export const snackbarReducer = snackbarSlice.reducer
